import * as React from 'react';
const SvgSolutions = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M17.677 10.547 12.76 17.05l-2.73.951.173-2.884 4.919-6.502a1.6 1.6 0 0 1 2.238-.31l.008.005a1.6 1.6 0 0 1 .31 2.238"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.234 18h-5.1a1.176 1.176 0 0 1-1.133-1.214V4.482a1.176 1.176 0 0 1 1.133-1.215h14.733a1.176 1.176 0 0 1 1.134 1.215v1.051M4.4 1v3.4M7.8 1v3.4M11.2 1v3.4M14.6 1v3.4"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M3.267 7.517a.85.85 0 0 1 .85-.85v0a.85.85 0 0 1 .85.85c0 .274-.093.54-.265.755l-1.435 1.793h1.7M10.067 6.667h.567a.567.567 0 0 1 .567.566v2.834M10.067 10.067h1.7M7.8 7.233V9.5M6.667 8.367h2.267"
    />
  </svg>
);
export default SvgSolutions;
