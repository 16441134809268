import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegionsRequest } from '../store/mainActions';

function useRegions() {
  const dispatch = useDispatch();
  const { regions } = useSelector((state) => state.sitemaps);
  useEffect(() => {
    if (regions === undefined) {
      dispatch(getRegionsRequest());
    }
  }, []);

  return { regions };
}

export default useRegions;
