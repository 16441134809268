import { message } from 'antd';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw, ContentState, EditorState } from 'draft-js';

export const GUIDE_TAG = process.env.REACT_APP_GUIDE_TAG;
export const PRINTABLE_TAG = process.env.REACT_APP_PRINTABLE;
export const PROJECTABLE_TAG = process.env.REACT_APP_PROJECTABLE;
export const DOWNLOADABLE_TAG = process.env.REACT_APP_DOWNLOADABLE;

export const capitalizeFirst = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const getTimeAgo = (date) => {
  const _date = new Date(date);
  const now = new Date();
  const diffTime = Math.abs(now - _date);
  const diffMinutes = Math.ceil(diffTime / (1000 * 60));
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  if (diffHours > 24) {
    return _date.toDateString().slice(3);
  }
  if (diffHours > 1) {
    return `${diffHours} hours ago`;
  }
  if (diffHours <= 1) {
    return `${diffMinutes} minutes ago`;
  }
};

export const getQueryParams = (pagination, filters, locale) => {
  const { offset, length } = pagination;
  let queryParams = '';
  if (offset || length) {
    queryParams = queryParams.concat(`?offset=${offset}&length=${length}`);
  }
  const appliedFilters = Object.keys(filters).filter(
    (filterLabel) => filters[filterLabel]
  );
  if (appliedFilters.length > 0) {
    queryParams =
      queryParams !== '' ? queryParams.concat('&') : queryParams.concat('?');
    appliedFilters.forEach((filterLabel, index) => {
      queryParams = queryParams.concat(
        `${index > 0 ? '&' : ''}${filterLabel}=${filters[filterLabel]}`
      );
    });
  }
  if (locale) {
    queryParams =
      queryParams !== ''
        ? queryParams.concat(`&locale=${locale}`)
        : queryParams.concat(`?locale=${locale}`);
  }
  return queryParams;
};

export const handleCopyClipboard = (id) => {
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('value', id);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  message.success(' ID Copied!');
};

export async function throttleRequests(
  requestsToMake = [],
  maxParallelRequests = 6
) {
  const queue = [];
  const results = [];

  for (const requestToMake of requestsToMake) {
    // fire the async function, add its promise to the queue,
    // and remove it from queue when complete
    const promise = requestToMake().then((res) => {
      queue.splice(queue.indexOf(promise), 1);
      results.push(res);
      return res;
    });
    queue.push(promise);

    // if the number of queued requests matches our limit then
    // wait for one to finish before enqueueing more
    if (queue.length >= maxParallelRequests) {
      await Promise.race(queue);
    }
  }
  // wait for the rest of the calls to finish
  await Promise.all(queue);
  return results;
}

export const parseHtmlStringToDraft = (string) => {
  const blocksFromHtml = htmlToDraft(string);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHtml.contentBlocks,
    blocksFromHtml.entityMap
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

export const getCoursesFromTags = (tag) =>
  tag.match(/^\d/) || tag.match(/^[Pp][3-5]/) || tag.match(/^[ESO]/);

export const filterTags = (tag) =>
  ![GUIDE_TAG, PRINTABLE_TAG, DOWNLOADABLE_TAG, PROJECTABLE_TAG].includes(tag);

export const objectIsEmpty = (obj) => Object.keys(obj).length === 0;
