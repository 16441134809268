import React, { useEffect, useState } from 'react';
import { Input, Table, Modal, Tag, message, Button } from 'antd';
import {
  FilterOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import {
  capitalizeFirst,
  getTimeAgo,
  handleCopyClipboard,
} from '../../util/helpers';
import {
  actionDeleteResource,
  actionGetResources,
  actionGetResource,
  actionSetCurrentResource,
  actionDeleteResources,
  actionSetPagination,
} from '../../store/mainActions';

import ContainerHeader from '../../components/ContainerHeader';
import ResourcePreview from '../../components/ResourcePreview';

const columns = (handleOpenPreview, handleEditResource, onDelete) => [
  {
    title: 'Title',
    dataIndex: 'name',
    width: '40%',
    // sorter: (a, b) => b.title.localeCompare(a.title),
    render: (title) =>
      title && <div dangerouslySetInnerHTML={{ __html: title }} />,
  },
  {
    title: 'Identifier',
    dataIndex: 'uuid',
    width: '30%',
    render: (id) => (
      <span
        onClick={() => handleCopyClipboard(id)}
        style={{ cursor: 'pointer' }}
      >
        {id}
      </span>
    ),
  },
  {
    title: 'Languages',
    dataIndex: 'available_locales',
    render: (locales) => locales.map((lang) => <Tag key={lang}>{lang}</Tag>),
    // sorter: (a, b) => b.type.localeCompare(a.type),
    width: '20%',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (type) => capitalizeFirst(type),
    // sorter: (a, b) => b.type.localeCompare(a.type),
    width: '20%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (actions, all) => (
      <div style={{ display: 'flex' }}>
        <div style={{ textAlign: 'center', padding: '0 5px' }}>
          <EyeOutlined onClick={() => handleOpenPreview(all)} />
        </div>
        <div style={{ textAlign: 'center', padding: '0 5px' }}>
          <EditOutlined onClick={() => handleEditResource(all)} />
        </div>
        {all.type !== 'Session' && (
          <div style={{ textAlign: 'center', padding: '0 5px' }}>
            <DeleteOutlined onClick={() => onDelete([all.uuid])} />
          </div>
        )}
      </div>
    ),
  },
];

const Resources = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const resourcesList = useSelector(({ resources }) => resources.resources);
  const resourcesCount = useSelector(({ resources }) => resources.count);
  const loading = useSelector(({ common }) => common.loading);
  const success = useSelector(({ common }) => common.success);
  const pagination = useSelector(
    ({ paginationFilters }) => paginationFilters.resources.pagination
  );
  const filters = useSelector(
    ({ paginationFilters }) => paginationFilters.resources.filters
  );

  const [previewVisible, setPreviewVisible] = useState(false);
  const [waitEdit, setWaitEdit] = useState(false);
  const [resourcesToDelete, setResourcesToDelete] = useState([]);

  useEffect(() => {
    dispatch(actionGetResources());
  }, [filters, pagination]);

  useEffect(() => {
    if (waitEdit && !loading && success) {
      setWaitEdit(false);
      history.push('/newContent');
    } else if (waitEdit && !loading && !success) {
      setWaitEdit(false);
    }
  }, [loading, success]);

  const onDelete = (resources) => {
    openDeleteModal(resources);
  };

  const handleDeleteResource = (resource) => {
    dispatch(actionDeleteResource(resource));
  };

  const handleDeleteResources = (resources) => {
    dispatch(actionDeleteResources(resources));
  };

  const handleEditResource = (resource) => {
    setWaitEdit(true);
    dispatch(actionGetResource(resource));
  };

  const handlePagination = (newPage, newPageSize) => {
    const _pagination = {
      offset: (newPage - 1) * newPageSize,
      length: newPageSize,
    };
    dispatch(actionSetPagination('resources', _pagination));
  };

  const paginationSettings =
    resourcesCount > 50
      ? {
          total: resourcesCount,
          pageSize: pagination.length,
          current: pagination.offset / pagination.length + 1,
          onChange: (newPage, newPageSize) =>
            handlePagination(newPage, newPageSize),
          position: ['bottomCenter'],
          showTotal: () => `Total ${resourcesCount} items`,
          size: 'small',
          showSizeChanger: false,
        }
      : false;

  const handleOpenPreview = (resource) => {
    setPreviewVisible(true);
    dispatch(actionGetResource(resource));
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
    dispatch(actionSetCurrentResource({}));
  };

  const openDeleteModal = (resources) => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Confirm',
      content:
        resources.length > 1
          ? 'Are you sure you want to delete these resources?'
          : 'Are you sure you want to delete this resource?',
      cancelText: 'Cancel',
      okText: 'Delete',
      onOk: () => {
        if (resources.length > 1) {
          handleDeleteResources(resources);
        } else {
          handleDeleteResource(resources[0]);
        }
        setResourcesToDelete([]);
      },
      width: '50%',
      maskClosable: true,
    });
  };

  const rowSelection = {
    selectedRowKeys: resourcesToDelete,
    onChange: (selectedRowKeys, selectedRows) => {
      setResourcesToDelete(selectedRowKeys);
    },
    getCheckboxProps: (resource) => ({
      disabled: resource.type === 'Session',
      name: resource.name,
    }),
  };

  return (
    <>
      <ContainerHeader title="Resources" />
      {/* <div className={styles.filterContainer}>
        <Input
          placeholder="Filter"
          suffix={<FilterOutlined />}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div> */}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className="inm-buttons-deleteButton"
          onClick={() => onDelete(resourcesToDelete)}
          disabled={isEmpty(resourcesToDelete)}
        >
          Delete Selected
        </Button>
      </div>
      <Table
        className="gx-table-no-bordered"
        columns={columns(handleOpenPreview, handleEditResource, onDelete)}
        dataSource={resourcesList}
        pagination={paginationSettings}
        bordered={false}
        rowKey={(record) => record.uuid}
        size="medium"
        loading={loading}
        rowSelection={{
          ...rowSelection,
        }}
      />
      <Modal
        visible={previewVisible}
        closable
        onCancel={handleClosePreview}
        footer={null}
        width="90%"
        style={{ height: '80%' }}
        bodyStyle={{ height: '100%' }}
        wrapClassName="inm-preview-wrapModal"
      >
        <ResourcePreview />
      </Modal>
    </>
  );
};

export default Resources;
