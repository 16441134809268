import * as React from 'react';
const SvgHome = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M3.128 11.62v6.875h5v-5a1.25 1.25 0 0 1 1.25-1.25h1.25a1.25 1.25 0 0 1 1.25 1.25v5h5V11.62M.628 10.37l8.49-8.49a1.25 1.25 0 0 1 1.769 0l8.49 8.49"
      />
    </g>
  </svg>
);
export default SvgHome;
