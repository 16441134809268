import React from 'react';
import { Input } from 'antd';

const WebGLComposer = (props) => {
  const { appletScene, appletPack, handleChangeInput, duration } = props;

  return (
    <div className="inm-newContent-section">
      <div className="inm-newContent-subSection inm-newContent-columns">
        Pack or variation
        <Input
          onChange={(e) =>
            handleChangeInput('appletPack', e.target.value, false)
          }
          value={appletPack}
          className="inm-newContent-input"
        />
      </div>

      <div className="inm-newContent-subSection inm-newContent-columns">
        Scene name
        <Input
          onChange={(e) =>
            handleChangeInput('appletScene', e.target.value, false)
          }
          value={appletScene}
          className="inm-newContent-input"
        />
      </div>
      {/* <div className={`${styles.subSection} ${styles.columns}`}>
        Duration
        <Input
          placeholder="Duration in secs"
          value={duration}
          type="number"
          onChange={(e) => handleChangeInput('duration', e.target.value, true)}
          className={styles.input}
        />
      </div> */}
    </div>
  );
};

export default WebGLComposer;
