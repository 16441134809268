import * as React from 'react';
const SvgGoogle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#google_svg__a)">
      <path
        fill="#4285F4"
        d="M14.864 8.135c0-.59-.048-1.02-.152-1.467H7.976V9.33h3.954c-.08.661-.51 1.658-1.467 2.328l-.013.089 2.13 1.65.147.015c1.355-1.252 2.137-3.094 2.137-5.278"
      />
      <path
        fill="#34A853"
        d="M7.976 15.15c1.937 0 3.564-.637 4.751-1.737l-2.264-1.754c-.606.422-1.419.717-2.487.717-1.898 0-3.508-1.252-4.082-2.982l-.084.008-2.215 1.714-.03.08a7.17 7.17 0 0 0 6.41 3.955"
      />
      <path
        fill="#FBBC05"
        d="M3.894 9.394a4.4 4.4 0 0 1-.24-1.419c0-.494.088-.972.232-1.419l-.004-.095L1.639 4.72l-.073.035A7.2 7.2 0 0 0 .8 7.975c0 1.156.28 2.249.766 3.221z"
      />
      <path
        fill="#EB4335"
        d="M7.976 3.574c1.347 0 2.256.582 2.774 1.069l2.025-1.977C11.532 1.51 9.913.8 7.975.8a7.17 7.17 0 0 0-6.41 3.954l2.32 1.802c.583-1.73 2.193-2.982 4.09-2.982"
      />
    </g>
    <defs>
      <clipPath id="google_svg__a">
        <path fill="#fff" d="M.8.8h14.072v14.4H.8z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGoogle;
