import * as React from 'react';
const SvgMapsPin = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M19.375 7.5V3.875a1.25 1.25 0 0 0-.786-1.16l-5-2a1.25 1.25 0 0 0-.928 0L7.339 2.842a1.25 1.25 0 0 1-.928 0L1.483.872a.625.625 0 0 0-.858.583v11.978a1.25 1.25 0 0 0 .786 1.16l5 2a1.25 1.25 0 0 0 .928 0l2.401-.96M6.875 2.933v13.75M13.125.625v6.25M15.625 12.874a.313.313 0 0 1 .313.313M15.313 13.187a.313.313 0 0 1 .312-.313M15.625 13.5a.313.313 0 0 1-.312-.312"
      />
      <path strokeWidth={1.249995} d="M15.938 13.187a.313.313 0 0 1-.313.312" />
      <path
        strokeWidth={1.249995}
        d="M15.625 9.437a3.75 3.75 0 0 1 3.75 3.75c0 1.6-2.24 4.646-3.257 5.948a.625.625 0 0 1-.985 0c-1.018-1.3-3.258-4.347-3.258-5.948a3.75 3.75 0 0 1 3.75-3.75"
      />
    </g>
  </svg>
);
export default SvgMapsPin;
