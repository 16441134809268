import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Input } from 'antd';
import ReactPlayer from 'react-player';
import isEmpty from 'lodash/isEmpty';
import UploadThumbnail from '../../../../components/UploadThumbnail';
import { API_HOST } from '../../../../store/resources/dataSource';
import { axiosInstance } from '../../../../util/axiosInstance';

const VideoComposer = (props) => {
  const {
    handleChangeInput,
    videoUrl,
    duration,
    title,
    isEdit,
  } = props;

  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const tableKeys = ['order', 'from', 'to', 'caption', 'thumbnail', 'delete'];

  const handleUploadVideo = async () => {
    setIsVideoUploading(true);

    const url = `${API_HOST}/file/vimeo`;
    try {
      const data = {
        file_name: title.replace(/[^a-zA-Z0-9]/g, ''),
        url: videoUrl,
      };
      const response = await axiosInstance({ url, method: 'POST', data });
      if (response.error) {
        handleChangeInput('videoUrl', 'Error', true);
      } else {
        handleChangeInput('fileId', response.data.uuid, true);
      }
    } catch (e) {
      handleChangeInput('videoUrl', 'Error', true);
    }

    setIsVideoUploading(false);
  };

  const getVideoDuration = (e) => {
    setIsVideoUploading(false);
    handleChangeInput('duration', e, true);
  };

  return (
    <div className="inm-newContent-browseContainer">
      URL
      <div className="inm-newContent-uploadVideo">
        <Input
          placeholder="www.vimeo.com/"
          onChange={(e) => handleChangeInput('videoUrl', e.target.value, true)}
          value={videoUrl}
        />
        {/*
          <Button
          className={styles.uploadButton}
          onClick={handleUploadVideo}
          loading={isVideoUploading}
        >
          Upload
        </Button>
           */}
      </div>
      <div className="inm-newContent-durationContainer">
        Duration
        <Input
          placeholder="Duration in secs"
          value={duration}
          type="number"
          disabled
        />
      </div>
      <ReactPlayer
        url={videoUrl}
        onDuration={getVideoDuration}
        className="inm-newContent-hiddenVideo"
        playing={false}
      />
    </div>
  );
};

export default VideoComposer;
