import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Spin, Tag } from 'antd';

const ListPreview = (props) => {
  const { CheckableTag } = Tag;
  const list = useSelector(({ lists }) => lists.currentList);
  const { translations = {} } = list;

  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (translations) {
      const languagesAvailable =
        Object.keys(translations).map((lang) => lang) || [];
      setLanguage(languagesAvailable[0]);
    }
  }, [list]);

  if (!translations || !language) {
    return (
      <div className="inm-preview-spinnerWrapper">
        <Spin size="large" />
      </div>
    );
  }

  const languagesAvailable =
    Object.keys(translations).map((lang) => lang) || [];

  const title = translations[language]?.title || '';
  const resources = translations[language]?.resources || [];

  return (
    <div className="inm-preview-modal">
      <div className="inm-preview-description">
        <div className="inm-preview-maindata">
          <span>
            <strong>Title: </strong>
            <div
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </span>
        </div>
      </div>
      <div className="inm-preview-languagesAvailable">
        {languagesAvailable?.map((lang) => (
          <CheckableTag
            key={lang}
            checked={lang === language}
            onChange={() => setLanguage(lang)}
          >
            {lang}
          </CheckableTag>
        ))}
      </div>
      <Divider />
      <div className="inm-preview-resourcesList">
        {resources?.map((resource) => (
          <div className="inm-newContent-resultBox" key={resource.uuid}>
            <div className="inm-newContent-resultType">{resource.type}</div>
            <div className="inm-newContent-resultInfoContainer">
              <div dangerouslySetInnerHTML={{ __html: resource.name }} />
              <div>{resource.uuid}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListPreview;
