import {
  CLEAR_SITEMAP,
  CLONE_SITEMAP,
  CLONE_SITEMAP_ERROR,
  CLONE_SITEMAP_SUCCESS,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_SITEMAP_SUCCESS,
  GET_ALL_COURSES_SUCCESS,
  GET_ALL_MENU_ITEMS,
  GET_ALL_MENU_ITEMS_SUCCESS,
  GET_LANGUAGES_SUCCESS,
  GET_MENU_ITEM,
  GET_MENU_ITEM_SUCCESS,
  GET_REGIONS_ERROR,
  GET_REGIONS_SUCCESS,
  GET_ROOT_ITEM_SUCCESS,
  GET_SITEMAPS_ERROR,
  GET_SITEMAPS_REQUEST,
  GET_SITEMAPS_SUCCESS,
  ON_REORDER_LIST,
  SAVE_AND_CONTINUE,
  SAVE_AND_CONTINUE_ERROR,
  SAVE_AND_CONTINUE_SUCCESS,
  SAVE_GROUP_ITEM,
  SAVE_GROUP_ITEM_ERROR,
  SAVE_GROUP_ITEM_SUCCESS,
  SAVE_LINK_ITEM_ERROR,
  SAVE_LINK_ITEM_REQUEST,
  SAVE_LINK_ITEM_SUCCESS,
  GET_SITEMAP_SUCCESS,
  TOGGLE_SITEMAP_SUCCESS,
  SET_REGION_FILTER,
  SET_LANG_FILTER,
  SET_ROLE_FILTER,
} from '../../constants/ActionTypes';

const SPAIN_DEFAULT_REGION = 'ES';

const initialSettings = {
  regions: undefined,
  saving: false,
  sitemap: undefined,
  sitemaps: undefined,
  loading: false,
  languages: undefined,
  menuItems: undefined,
  menuItem: undefined,
  courses: undefined,
  rootItem: undefined,
  cloning: false,
  regionFilter: SPAIN_DEFAULT_REGION,
  langFilter: undefined,
  roleFilter: 'ROLE_TEACHER',
};

const sitemaps = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_REGIONS_SUCCESS: {
      return {
        ...state,
        regions: action.payload,
      };
    }
    case GET_REGIONS_ERROR: {
      return {
        ...state,
        regions: null,
      };
    }
    case SAVE_AND_CONTINUE: {
      return {
        ...state,
        saving: true,
      };
    }
    case GET_SITEMAP_SUCCESS: {
      return {
        ...state,
        sitemap: action.payload,
      };
    }
    case SAVE_AND_CONTINUE_SUCCESS: {
      return {
        ...state,
        saving: false,
        sitemap: {
          ...state.sitemap,
          ...action.payload,
        },
      };
    }
    case SAVE_AND_CONTINUE_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case GET_SITEMAPS_REQUEST: {
      return {
        ...state,
        loading: true,
        sitemap: undefined,
        menuItems: undefined,
        menuItem: undefined,
        rootItem: undefined,
      };
    }
    case GET_SITEMAPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        sitemaps: [...action.payload],
      };
    }
    case GET_SITEMAPS_ERROR: {
      return {
        ...state,
        loading: false,
        sitemaps: null,
      };
    }
    case TOGGLE_SITEMAP_SUCCESS: {
      return {
        ...state,
        sitemaps: state.sitemaps.map((sitemap) =>
          sitemap.id === action.payload.id
            ? { ...sitemap, active: action.payload.checked }
            : sitemap
        ),
      };
    }
    case DELETE_SITEMAP_SUCCESS: {
      return {
        ...state,
        sitemaps: state.sitemaps.filter(
          (sitemap) => sitemap.id !== action.payload
        ),
      };
    }
    case GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        languages: { ...action.payload },
      };
    }
    case SAVE_LINK_ITEM_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }
    case SAVE_GROUP_ITEM: {
      return {
        ...state,
        saving: true,
      };
    }
    case SAVE_GROUP_ITEM_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }
    case SAVE_LINK_ITEM_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }
    case SAVE_LINK_ITEM_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }
    case SAVE_GROUP_ITEM_ERROR: {
      return {
        ...state,
        saving: false,
      };
    }

    case ON_REORDER_LIST: {
      const { newData, groupId } = action.payload;
      return {
        ...state,
        menuItems: state.menuItems.map((_item) =>
          _item.id === groupId
            ? {
                ..._item,
                items: newData,
              }
            : _item
        ),
      };
    }
    case GET_ALL_MENU_ITEMS: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_ALL_MENU_ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        menuItems: action.payload,
      };
    }
    case GET_ROOT_ITEM_SUCCESS: {
      return {
        ...state,
        rootItem: action.payload,
      };
    }
    case DELETE_MENU_ITEM_SUCCESS: {
      const isSubitem =
        action.payload.parentMenuItemId !== action.payload.rootMenuItemId;
      return isSubitem
        ? removeMenuSubItem(state, action.payload)
        : removeMenuItem(state, action.payload);
    }
    case GET_MENU_ITEM: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        menuItem: action.payload,
      };
    }
    case GET_ALL_COURSES_SUCCESS: {
      return {
        ...state,
        courses: action.payload,
      };
    }
    case CLONE_SITEMAP: {
      return {
        ...state,
        cloning: true,
        regionFilter: action.payload.to.region,
      };
    }
    case CLONE_SITEMAP_ERROR: {
      return {
        ...state,
        cloning: false,
      };
    }
    case CLONE_SITEMAP_SUCCESS: {
      return {
        ...state,
        cloning: false,
        sitemaps: [...state.sitemaps, action.payload].sort(
          (a, b) => a.courseOrder - b.courseOrder
        ),
      };
    }
    case SET_REGION_FILTER: {
      return {
        ...state,
        regionFilter: action.payload,
        langFilter: undefined,
      };
    }
    case SET_LANG_FILTER: {
      return {
        ...state,
        langFilter: action.payload,
      };
    }
    case SET_ROLE_FILTER: {
      return {
        ...state,
        roleFilter: action.payload,
      };
    }
    default:
      return state;
  }
};

const removeMenuItem = (state, payload) => ({
  ...state,
  menuItems: state.menuItems.map((item) =>
    item.sitemapId === payload.sitemapId
      ? {
          ...item,
          items: item.items.filter((_item) => _item.id !== payload.id),
        }
      : item
  ),
});

const removeMenuSubItem = (state, payload) => ({
  ...state,
  menuItems: state.menuItems.map((item) =>
    item.sitemapId === payload.sitemapId
      ? {
          ...item,
          items: item.items.map((item) =>
            item.id === payload.parentMenuItemId
              ? {
                  ...item,
                  items: item.items.filter((item) => item.id !== payload.id),
                }
              : item
          ),
        }
      : item
  ),
});

export default sitemaps;
