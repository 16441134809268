import * as React from 'react';
const SvgStudyDesk = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M18.438 13.158H1.563a.624.624 0 0 1-.606-.474l-.313-1.25a.624.624 0 0 1 .606-.776h17.5a.624.624 0 0 1 .606.776l-.313 1.25a.624.624 0 0 1-.605.474M2.5 13.158l-1.25 6.25M17.5 13.158l1.25 6.25"
      />
      <path strokeWidth={1.249995} d="M11.875 10.658a2.5 2.5 0 0 1 5 0" />
      <path
        strokeWidth={1.249995}
        d="M12.5 1.908a1.875 1.875 0 0 1 1.875 1.875v4.375"
      />
      <path
        strokeWidth={1.249995}
        d="M9.02 1.283a2.5 2.5 0 0 1 2.855 4.105ZM8.728 10.658H3.125a1.25 1.25 0 0 1 0-2.5h5.603M7.478 8.158v2.5M8.728 8.158H3.125a1.25 1.25 0 0 1 0-2.5h5.603M7.478 5.658v2.5"
      />
    </g>
  </svg>
);
export default SvgStudyDesk;
