import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Input,
  message,
  Select,
  TreeSelect,
  Radio,
  Popover,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import UploadThumbnail from '../../../components/UploadThumbnail';
import { showVisibilityType } from '../../../util/showVisibilityType';
import { defaultLanguage } from '../../../util/languageList';

const { TextArea } = Input;
const { Option } = Select;

const caption = (
  <div>
    <p>&lt;sup&gt;&lt;/sup&gt;</p>
    <p>&lt;sub&gt;&lt;/sub&gt;</p>
    <p>&lt;i&gt;&lt;/i&gt;</p>
    <p>&lt;a href=&quot;...&quot; target=&quot;_blank&quot;&gt;&lt;/a&gt;</p>
  </div>
);
class FirstStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: !!props.type,
    };
  }

  formatAlphanumericInput = (text) => text.replace(/[^a-z0-9]/gi, '');

  formatNumericInput = (text) => text.replace(/\D/g, '');

  selectContentType = (value) => {
    const { handleChangeInput } = this.props;
    this.setState({
      selectedType: true,
    });
    handleChangeInput('type', value);
  };

  handleCopyClipboard = (id) => {
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.setAttribute('value', id);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    message.success(' ID Copied!');
  };

  render() {
    const {
      type,
      contentTypes,
      handleChangeInput,
      isGuide,
      isPrintable,
      isDownloadable,
      isProjectable,
      isPublic,
      language,
      languageOptions,
      translations,
      handleUploadedImage,
      thumbnailUrl,
      handleNextStep,
      validateData,
      uuid,
      dynamics,
      tagOptions,
      courses,
      contentBlock,
      others,
      coursesOptions,
      tabs,
      tabsOptions,
      fakeCoursesOptions,
      tags,
      openCancelModal,
      notAutomaticallyRelatable,
      printableIds,
      visibilityType,
      sessionCode,
      validateSessionCode,
      isCompletable,
      list_type,
    } = this.props;
    const { selectedType } = this.state;
    const { title, description, icon, status, enumeration } =
      translations[language];
    const isSession = type === 'session';
    const isPage = type === 'page';
    const isList = type === 'resourceList';
    const isGeneric = type === 'generic';
    const canBePrintable = type === 'pdf' || isGeneric;
    const canBeGuide = type === 'pdf' || type === 'genially';
    const canHavePrintables = type === 'pdf' || type === 'vimeo';
    const statusOptions = ['available', 'new', 'not_available', 'jumpable'];
    const iconOptions = [null, 'download', 'evaluation'];
    const listTypeOptions = [null, 'assessable', 'adventures', 'laboratory'];
    const showVisibilityTypeSelector = selectedType && showVisibilityType(type);

    return (
      <>
        <div className="inm-newContent-section inm-newContent-borderBottom">
          <div className="inm-newContent-subSection inm-newContent-borderRight inm-newContent-typeSelector">
            Type
            <TreeSelect
              value={type === '' ? null : type}
              treeData={contentTypes}
              placeholder="Choose content type"
              onChange={(value) => this.selectContentType(value)}
              treeDefaultExpandAll
              className="inm-newContent-dropDown"
              disabled={uuid}
            />
          </div>
          <div className="inm-newContent-subSection inm-newContent-alignRight">
            Language
            <Select
              className="inm-newContent-dropDown"
              onChange={(value) => handleChangeInput('language', value)}
              value={language}
            >
              {languageOptions}
            </Select>
          </div>
        </div>
        <div
          className={`inm-newContent-section ${
            isSession || isPage || isList ? '' : 'inm-newContent-borderBottom'
          } ${selectedType ? '' : 'inm-newContent-sectionDisabled'}`}
        >
          {isList && (
            <div className="inm-newContent-subSectionColumn">
              <div className="inm-newContent-checkboxContainer">
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={notAutomaticallyRelatable}
                  onChange={(e) =>
                    handleChangeInput(
                      'notAutomaticallyRelatable',
                      e.target.checked
                    )
                  }
                />
                Not Automatically Relatable
              </div>
            </div>
          )}
          {!(isSession || isPage || isList) && (
            <div className="inm-newContent-subSection">
              <div className="inm-newContent-checkboxContainer">
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={isProjectable}
                  onChange={(e) =>
                    handleChangeInput('isProjectable', e.target.checked)
                  }
                />
                Projectable
              </div>
              <div className="inm-newContent-checkboxContainer">
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={isDownloadable}
                  onChange={(e) =>
                    handleChangeInput('isDownloadable', e.target.checked)
                  }
                />
                Downloadable
              </div>
              <div
                className={`inm-newContent-checkboxContainer ${
                  canBeGuide ? '' : 'inm-newContent-hide'
                }`}
              >
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={isGuide}
                  onChange={(e) =>
                    handleChangeInput('isGuide', e.target.checked)
                  }
                />
                Guide
              </div>
              <div
                className={`inm-newContent-checkboxContainer ${
                  canBePrintable ? '' : 'inm-newContent-hide'
                }`}
              >
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={isPrintable || isGeneric}
                  onChange={(e) =>
                    handleChangeInput('isPrintable', e.target.checked)
                  }
                />
                Printable
              </div>
              <div className="inm-newContent-checkboxContainer">
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={isPublic}
                  onChange={(e) =>
                    handleChangeInput('isPublic', e.target.checked)
                  }
                />
                Public
              </div>
            </div>
          )}
          {isSession && (
            <div className="inm-newContent-subSection inm-newContent-borderBottom">
              <span style={{ marginRight: '8px' }}>Session Code</span>
              <div className="inm-newContent-checkboxContainer">
                <Input
                  placeholder="L000000006"
                  value={sessionCode}
                  onChange={(e) =>
                    handleChangeInput(
                      'sessionCode',
                      this.formatAlphanumericInput(e.target.value)
                    )
                  }
                  className={`${
                    validateSessionCode() ? '' : 'ant-input-status-error'
                  }`}
                />
                <div
                  className={`inm-newContent-error ${
                    validateSessionCode() ? 'inm-newContent-hide' : ''
                  }`}
                  style={{
                    paddingRight: '6px',
                    paddingLeft: '6px',
                    fontSize: '12px',
                  }}
                >
                  Max length is 10
                </div>
              </div>

              <div className="inm-newContent-checkboxContainer">
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={isCompletable}
                  onChange={(e) =>
                    handleChangeInput('isCompletable', e.target.checked)
                  }
                />
                Completable
              </div>
              <div className="inm-newContent-checkboxContainer">
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={isPublic}
                  onChange={(e) =>
                    handleChangeInput('isPublic', e.target.checked)
                  }
                />
                Public
              </div>
            </div>
          )}
        </div>
        {showVisibilityTypeSelector && (
          <div className="inm-newContent-section inm-newContent-borderBottom inm-newContent-subSection">
            <div className="inm-newContent-checkboxContainer">
              <Radio.Group
                onChange={(e) =>
                  handleChangeInput('visibilityType', e.target.value)
                }
                value={visibilityType}
              >
                <Radio value="BLANK">Study content</Radio>
                <Radio value="ACTIVITY">Activity</Radio>
                <Radio value="SOLUTION">Solution</Radio>
              </Radio.Group>
            </div>
          </div>
        )}
        <div
          className={`inm-newContent-section inm-newContent-borderBottom ${
            selectedType ? '' : 'inm-newContent-sectionDisabled'
          }`}
        >
          <div className="inm-newContent-subSection inm-newContent-columns inm-newContent-columnsHalfWidth">
            <div className="inm-newContent-section inm-section-gap">
              <div>
                <Popover content={caption}>
                  <span className="inm-required-field">
                    Title
                    <sup>*</sup>
                  </span>
                </Popover>
                <Input
                  value={title}
                  onChange={(e) =>
                    handleChangeInput('title', e.target.value, true)
                  }
                />
              </div>
              {!(isPage || isList) && (
                <div className="inm-newContent-status">
                  Status
                  <Select
                    className="inm-newContent-status"
                    onChange={(value) =>
                      handleChangeInput('status', value, true)
                    }
                    value={status}
                  >
                    {statusOptions.map((value) => (
                      <Option value={value} key={value}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </div>
            {isList && (
              <div className="gx-mt-4 ant-btn-block">
                List Type
                <Select
                  className="inm-newContent-status"
                  onChange={(value) => handleChangeInput('list_type', value)}
                  value={list_type}
                >
                  {listTypeOptions.map((value) => (
                    <Option value={value} key={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {!(isPage || isList) && (
              <div className="inm-newContent-section-thumbnail gx-mt-4">
                <div>
                  <span className="inm-required-field">
                    Thumbnail
                    {language === defaultLanguage && <sup>*</sup>}
                  </span>
                  <UploadThumbnail
                    handleUploadedImage={handleUploadedImage}
                    thumbnailUrl={thumbnailUrl}
                    handleRemoveImage={() => handleUploadedImage({})}
                  />
                  <div className="inm-newContent-uploadArea">
                    {uuid && (
                      <div>
                        ID
                        <div className="inm-newContent-copyId">
                          <Input value={uuid} disabled />
                          <CopyOutlined
                            onClick={() => this.handleCopyClipboard(uuid)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  Number
                  <Input
                    maxLength={3}
                    value={enumeration}
                    onChange={(e) =>
                      handleChangeInput('enumeration', e.target.value, true)
                    }
                  />
                  <div>
                    Icon
                    <Select
                      className="inm-newContent-status"
                      onChange={(value) =>
                        handleChangeInput('icon', value, true)
                      }
                      value={icon}
                    >
                      {iconOptions.map((value) => (
                        <Option value={value} key={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="inm-newContent-subSection inm-newContent-columns inm-newContent-columnsHalfWidth">
            Short Description
            <TextArea
              rows={6}
              value={description}
              onChange={(e) =>
                handleChangeInput('description', e.target.value, true)
              }
            />
          </div>
        </div>
        {canHavePrintables && (
          <div className="inm-newContent-section">
            <div className="inm-newContent-subSection inm-newContent-columns">
              Printable ID&apos;s
              <Input
                placeholder='e.g. "printable-1", "printable-2"'
                value={printableIds}
                onChange={(e) =>
                  handleChangeInput('printableIds', e.target.value)
                }
              />
            </div>
          </div>
        )}
        <div
          className={`inm-newContent-section ${
            selectedType ? '' : 'inm-newContent-sectionDisabled'
          }`}
        >
          {!isPage && !isList && (
            <div className="inm-newContent-subSection inm-newContent-columns inm-newContent-marginBottomEnd">
              All tags
              <Select
                mode="multiple"
                value={tags}
                placeholder="Select one or multiple options, write to find"
                onChange={(e) => handleChangeInput('tags', e)}
                className="inm-newContent-multiSelect"
                optionFilterProp="label"
              >
                {tagOptions}
              </Select>
            </div>
          )}

          {isPage && (
            <div className="inm-newContent-marginBottomEnd">
              <div className="inm-newContent-subSection inm-newContent-columns">
                Course
                <Select
                  value={courses}
                  placeholder="Select an option"
                  onChange={(e) => handleChangeInput('courses', [e])}
                  className="inm-newContent-multiSelect"
                  optionFilterProp="label"
                >
                  {coursesOptions}
                </Select>
              </div>
              <div className="inm-newContent-subSection inm-newContent-columns">
                Tab
                <Select
                  value={tabs}
                  placeholder="Select an option"
                  onChange={(e) => handleChangeInput('tabs', [e])}
                  className="inm-newContent-multiSelect"
                  optionFilterProp="label"
                >
                  {tabsOptions}
                </Select>
              </div>
            </div>
          )}
        </div>

        <div className="inm-newContent-buttonsContainer inm-newContent-onlyRight">
          <Button
            className="inm-newContent-cancelButton"
            onClick={openCancelModal}
          >
            Cancel
          </Button>
          <Button
            className="inm-newContent-saveButton"
            onClick={handleNextStep}
            disabled={!validateData()}
          >
            Save and Continue
          </Button>
        </div>
      </>
    );
  }
}

export default FirstStep;
