import * as React from 'react';
const SvgBell = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M8.5 17.313a1.565 1.565 0 0 0 3.004 0M10 3.25V1.563M10 3.25a5.625 5.625 0 0 1 5.625 5.625c0 5.284 1.125 6.188 1.125 6.188H3.25s1.125-1.437 1.125-6.188A5.625 5.625 0 0 1 10 3.25"
    />
  </svg>
);
export default SvgBell;
