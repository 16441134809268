import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Spin, Tag } from 'antd';

const PagePreview = (props) => {
  const { CheckableTag } = Tag;
  const page = useSelector(({ pages }) => pages.currentPage);
  const { translations = {} } = page;

  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (translations) {
      const languagesAvailable = Object.keys(translations).map((lang) => lang);
      setLanguage(languagesAvailable[0]);
    }
  }, [page]);

  if (!translations || !language) {
    return (
      <div className="inm-preview-spinnerWrapper">
        <Spin size="large" />
      </div>
    );
  }

  const languagesAvailable = Object.keys(translations).map((lang) => lang);

  const title = translations[language]?.title || '';
  const lists = translations[language]?.lists || [];

  return (
    <div className="inm-preview-modal">
      <div className="inm-preview-description">
        <div className="inm-preview-maindata">
          <span>
            <strong>Title: </strong>
            <div
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </span>
        </div>
      </div>
      <div className="inm-preview-languagesAvailable">
        {languagesAvailable.map((lang) => (
          <CheckableTag
            key={lang}
            checked={lang === language}
            onChange={() => setLanguage(lang)}
          >
            {lang}
          </CheckableTag>
        ))}
      </div>
      <Divider />
      <div className="inm-preview-resourcesList">
        {lists?.map((list) => (
          <div className="inm-newContent-resultBox" key={list.uuid}>
            <div className="inm-newContent-resultType">LIST</div>
            <div className="inm-newContent-resultInfoContainer">
              <div dangerouslySetInnerHTML={{ __html: list.name }} />
              <div>{list.uuid}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PagePreview;
