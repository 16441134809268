const selectIconsValues = [
  { key: 'Abacus', value: 1 },
  { key: 'AddCircle', value: 2 },
  { key: 'AnalyticsGraph', value: 3 },
  { key: 'ArrowDown', value: 4 },
  { key: 'ArrowLeft', value: 5 },
  { key: 'ArrowLeft2', value: 6 },
  { key: 'ArrowRight', value: 7 },
  { key: 'ArrowUp', value: 8 },
  { key: 'BoardGameJenga', value: 9 },
  { key: 'BubbleInfo', value: 10 },
  { key: 'CalendarDate', value: 11 },
  { key: 'Camera', value: 12 },
  { key: 'Chair', value: 13 },
  { key: 'ChatUser', value: 14 },
  { key: 'CheckCircle', value: 15 },
  { key: 'Check', value: 16 },
  { key: 'Clipboard', value: 17 },
  { key: 'Close', value: 18 },
  { key: 'CloseBig', value: 19 },
  { key: 'ContentWrite', value: 20 },
  { key: 'ContentApplet', value: 21 },
  { key: 'ContentBase', value: 22 },
  { key: 'ContentFlag', value: 23 },
  { key: 'Crics', value: 24 },
  { key: 'Cube', value: 25 },
  { key: 'DefaultAvatar', value: 26 },
  { key: 'DeleteTrash', value: 27 },
  { key: 'DrawerSend', value: 28 },
  { key: 'ELearningMonitor', value: 29 },
  { key: 'EditList', value: 30 },
  { key: 'EducativeToysMath', value: 31 },
  { key: 'EducativeToys', value: 32 },
  { key: 'Enter', value: 33 },
  { key: 'Error', value: 34 },
  { key: 'Expand', value: 35 },
  { key: 'FileSearch', value: 36 },
  { key: 'Geogebra', value: 37 },
  { key: 'Geometry', value: 38 },
  { key: 'Home', value: 39 },
  { key: 'Info', value: 40 },
  { key: 'Keyboard', value: 41 },
  { key: 'KingBadge', value: 42 },
  { key: 'King', value: 43 },
  { key: 'LabFlask', value: 44 },
  { key: 'LegalScaleUnequal', value: 45 },
  { key: 'Logout', value: 46 },
  { key: 'MailSend', value: 47 },
  { key: 'MapsMark', value: 48 },
  { key: 'MapsPin', value: 49 },
  { key: 'Measure', value: 50 },
  { key: 'Menu', value: 51 },
  { key: 'MonitorDownload', value: 52 },
  { key: 'MonitorTouch', value: 53 },
  { key: 'MultipleUsers', value: 54 },
  { key: 'NotesTask', value: 55 },
  { key: 'OpenBook', value: 56 },
  { key: 'OutdoorsLandscape', value: 57 },
  { key: 'PaginateFilter1', value: 58 },
  { key: 'PaginateFilter2', value: 59 },
  { key: 'PaginateFilter3', value: 60 },
  { key: 'PaginateFilter4', value: 61 },
  { key: 'PaginateFilter5', value: 62 },
  { key: 'PaginateFilter6', value: 63 },
  { key: 'PasswordLock', value: 64 },
  { key: 'Pill', value: 65 },
  { key: 'Play', value: 66 },
  { key: 'QuestionCircle', value: 67 },
  { key: 'RatingStar', value: 68 },
  { key: 'SchoolBoardMaths', value: 69 },
  { key: 'SchoolTeacher', value: 70 },
  { key: 'Search', value: 71 },
  { key: 'Settings', value: 72 },
  { key: 'ShipmentUpload', value: 73 },
  { key: 'ShowMore', value: 74 },
  { key: 'StudyDesk', value: 75 },
  { key: 'Tab', value: 76 },
  { key: 'TaskListClock', value: 77 },
  { key: 'Tetris', value: 78 },
  { key: 'TimeStopwatch', value: 79 },
  { key: 'Transfer', value: 80 },
  { key: 'Trust', value: 81 },
  { key: 'Unlink', value: 82 },
  { key: 'VideoGameController', value: 83 },
  { key: 'Video', value: 84 },
  { key: 'View', value: 85 },
  { key: 'ViewDisabled', value: 86 },
  { key: 'Warning', value: 87 },
  { key: 'Whatsapp', value: 88 },
  { key: 'StudentPermission', value: 89 },
  { key: 'Preparem', value: 90 },
  { key: 'VidaAula', value: 91 },
  { key: 'Espais', value: 92 },
  { key: 'Statistics', value: 93 },
  { key: 'PlayClass', value: 94 },
  { key: 'Download', value: 95 },
  { key: 'Evaluation', value: 96 },
  { key: 'IcWorldProblems', value: 97 },
  { key: 'Tramo7', value: 98 },
  { key: 'Tramo8', value: 99 },
  { key: 'Tramo9', value: 100 },
];

export default selectIconsValues;
