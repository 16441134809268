import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './settings/reducer';
import Auth from './auth/reducer';
import Common from './common/reducer';
import Resources from './resources/reducer';
import PaginationAndFilters from './paginationAndFilters/reducer';
import Tags from './tags/reducer';
import Tabs from './tabs/reducer';
import Courses from './courses/reducer';
import Lists from './lists/reducer';
import Pages from './pages/reducer';
import Sitemaps from './sitemaps/reducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    resources: Resources,
    paginationFilters: PaginationAndFilters,
    tags: Tags,
    tabs: Tabs,
    courses: Courses,
    lists: Lists,
    pages: Pages,
    sitemaps: Sitemaps,
  });

export default createRootReducer;
