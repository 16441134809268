import * as React from 'react';
const SvgEditList = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M15.622 1.875H1.872a1.25 1.25 0 0 0-1.25 1.25v13.75a1.25 1.25 0 0 0 1.25 1.25h15a1.25 1.25 0 0 0 1.25-1.25v-7.5m-17.5-3.75h10.625"
      />
      <path
        strokeWidth={1.249995}
        d="m18.985 5.388-4.77 4.769-2.343.468.469-2.343 4.769-4.77a1.32 1.32 0 0 1 1.87 0l.005.005a1.323 1.323 0 0 1 0 1.87M4.372 10.625h3.75m-3.75 3.75h8.125"
      />
    </g>
  </svg>
);
export default SvgEditList;
