import React, { useState, useEffect } from 'react';
import { Upload, message, Button, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_HOST } from '../../../../store/resources/dataSource';

import UploadThumbnail from '../../../../components/UploadThumbnail';

const GeniallyComposer = (props) => {
  const {
    fileName,
    editFragment,
    fragments,
    addFragment,
    handleChangeInput,
    duration,
  } = props;

  const [isGeniallyUploaded, setIsGeniallyUploaded] = useState(!!fileName);
  const [fileList, updateFileList] = useState([]);

  useEffect(() => {
    if (fileName) {
      const currentFile = [
        {
          uid: '1',
          name: fileName,
          status: 'done',
        },
      ];
      updateFileList(currentFile);
      setIsGeniallyUploaded(true);
    } else {
      updateFileList([]);
      setIsGeniallyUploaded(false);
    }
  }, [fileName]);

  const tableKeys = ['page', 'caption', 'thumbnail'];

  const renderTableHeader = () =>
    tableKeys.map((key, index) => (
      <th key={index} className="inm-newContent-tableHeader">
        {key.toUpperCase()}
      </th>
    ));

  const handleUploadedImage = ({ url, uuid }, fragment) => {
    editFragment('thumbnail_id', fragment.order, uuid);
    editFragment('thumbnail_url', fragment.order, url);
  };

  const renderTableData = () =>
    fragments.map((fragment) => (
      <tr key={fragment.order}>
        <td className="inm-newContent-tableCell">
          <Input disabled value={fragment.order} />
        </td>
        <td className="inm-newContent-tableCell">
          <Input
            value={fragment.caption}
            onChange={(e) =>
              editFragment('caption', fragment.order, e.target.value)
            }
          />
        </td>
        <td
          id="thumbContainer"
          className="inm-newContent-browseThumbnailContainer inm-newContent-tableCell"
        >
          <UploadThumbnail
            handleUploadedImage={(image) =>
              handleUploadedImage(image, fragment)
            }
            thumbnailUrl={fragment.thumbnail_url}
            isSmall
            handleRemoveImage={() => handleUploadedImage({}, fragment)}
          />
        </td>
      </tr>
    ));

  const handleUploadGenially = (numPages) => {
    for (let i = 1; i <= numPages; i += 1) {
      const fragment = {
        order: i,
        caption: '',
        thumbnail_id: null,
      };
      addFragment(fragment);
    }
  };

  const handleAddFragment = () => {
    const fragment = {
      order: fragments.length + 1,
      caption: '',
      thumbnail_id: null,
    };
    addFragment(fragment);
  };

  const successFileUpload = (file) => {
    handleChangeInput('fileName', file.name, true);
    handleChangeInput('fileId', file.response.uuid, true);
    // TODO: GET NUM PAGES handleUploadGenially(file.response.length);
  };

  const clearFileData = () => {
    handleChangeInput('fileName', '', true);
    handleChangeInput('fileId', '', true);
  };

  const handleOnChange = (info) => {
    // file.status is empty when beforeUpload return false
    updateFileList(info.fileList.filter((file) => !!file.status));
    setIsGeniallyUploaded(!!info.file.status);

    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'removed') {
      setIsGeniallyUploaded(false);
      clearFileData();
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      successFileUpload(info.file);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <div className="inm-newContent-browseContainer">
      Upload the resource
      <div className="inm-newContent-browsePdf">
        <Upload
          fileList={fileList}
          name="file"
          action={`${API_HOST}/file/genially`}
          onChange={handleOnChange}
        >
          <Button disabled={isGeniallyUploaded}>
            <UploadOutlined />
            Click to Upload
          </Button>
        </Upload>
      </div>
      {/* <div className={styles.durationContainer}>
        Duration
        <Input
          placeholder="Duration in secs"
          value={duration}
          type="number"
          onChange={(e) => handleChangeInput('duration', e.target.value, true)}
        />
      </div> */}
      <div className="inm-newContent-browseContainer">
        Pages details
        <div className="inm-newContent-subTitle">
          Each of the accessible pages of the resource
        </div>
        <div className="inm-newContent-tableContainer">
          <table>
            <tbody>
              <tr>{renderTableHeader()}</tr>
              {renderTableData()}
              <tr>
                <td className="inm-newContent-tableCell">
                  {fragments.length + 1}.
                </td>
                <td className="inm-newContent-tableCell">
                  <Button onClick={handleAddFragment} disabled={!fileName}>
                    Add
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GeniallyComposer;
