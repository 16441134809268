import * as React from 'react';
const SvgStatistics = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M.625 19.375h18.75M5 14.375H2.5a.625.625 0 0 0-.625.625v4.375h3.75V15A.625.625 0 0 0 5 14.375M11.25 8.125h-2.5a.625.625 0 0 0-.625.625v10.625h3.75V8.75a.625.625 0 0 0-.625-.625M17.5 11.875H15a.624.624 0 0 0-.625.625v6.875h3.75V12.5a.624.624 0 0 0-.625-.625M.625 8.75a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0M6.25 1.875a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0M16.875 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0M2.667 7.782l4.041-4.94M16.926 4.647l-8.227-2.42"
    />
  </svg>
);
export default SvgStatistics;
