import * as React from 'react';
const SvgArrowRight3 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path d="M9 3 7.943 4.058l4.185 4.192H3v1.5h9.128l-4.185 4.193L9 15l6-6z" />
  </svg>
);
export default SvgArrowRight3;
