import * as React from 'react';
const SvgTramo7 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#tramo-7_svg__a)">
      <path
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M16.445 18.786H2.383a1.17 1.17 0 0 1-1.172-1.172V3.552m8.565 2.343h2.568a.587.587 0 0 1 .524.849l-2.506 5.01M4.727 1.209h12.89s1.172 0 1.172 1.172v12.89s0 1.172-1.172 1.172H4.727s-1.172 0-1.172-1.172V2.38s0-1.172 1.172-1.172"
      />
    </g>
    <defs>
      <clipPath id="tramo-7_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTramo7;
