import * as React from 'react';
const SvgMeasure = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M1.458.625h17.917v6.25h0H1.458a.833.833 0 0 1-.833-.833V1.458a.833.833 0 0 1 .833-.833M8.125 13.125h11.25v6.25H8.125zM8.125 13.125l4.375-6.25M15 13.125l4.375-6.25M3.75.625v6.25M8.125.625v2.5M11.875.625v2.5M15.625.625v2.5M16.25 13.125v2.5M12.5 13.125v2.5"
      />
    </g>
  </svg>
);
export default SvgMeasure;
