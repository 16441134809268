import axios from 'axios';
import { axiosInstance } from '../../util/axiosInstance';

export const API_HOST = process.env.REACT_APP_API_RESOURCES;

export const createTag = async (data) => {
  const url = `${API_HOST}/tag`;
  const response = await axiosInstance({ url, method: 'POST', data });

  return response;
};

export const getTags = async (locale) => {
  const url = `${API_HOST}/tags/locale/${locale}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};

export const getTagById = async (id) => {
  const url = `${API_HOST}/tag/${id}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};
