import {
  SET_CURRENT_RESOURCE,
  SET_RESOURCES,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  currentResource: {},
  resources: [],
  count: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_RESOURCE: {
      return {
        ...state,
        currentResource: action.resource,
      };
    }
    case SET_RESOURCES: {
      return {
        ...state,
        resources: action.resources.resources,
        count: action.resources.count,
      };
    }
    default:
      return state;
  }
};
