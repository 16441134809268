import * as React from 'react';
const SvgMaterial = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#material_svg__a)">
      <g stroke="#757575" strokeWidth={1.25} clipPath="url(#material_svg__b)">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M17.688 10.857h-6.375a.75.75 0 0 0-.75.75v6.375c0 .414.335.75.75.75h6.374a.75.75 0 0 0 .75-.75v-6.375a.75.75 0 0 0-.75-.75M8.313 15.627l-2.16.786a1.127 1.127 0 0 1-1.442-.675l-3.08-8.453a1.125 1.125 0 0 1 .675-1.442l4.882-1.78"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.063 10.857V2.982a1.125 1.125 0 0 0-1.126-1.125H8.314a1.125 1.125 0 0 0-1.126 1.125v9a1.125 1.125 0 0 0 1.125 1.125h2.25"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.125 9.17a1.688 1.688 0 1 0 0-3.376 1.688 1.688 0 0 0 0 3.375"
        />
        <path d="M13.094 13.67a.281.281 0 1 1 0-.563M13.094 13.67a.281.281 0 0 0 0-.563M13.094 16.481a.281.281 0 1 1 0-.563M13.094 16.481a.281.281 0 0 0 0-.563M15.906 13.67a.281.281 0 0 1 0-.563M15.906 13.67a.281.281 0 1 0 0-.563M15.906 16.481a.281.281 0 0 1 0-.563M15.906 16.481a.281.281 0 1 0 0-.563" />
      </g>
    </g>
    <defs>
      <clipPath id="material_svg__a">
        <path fill="#fff" d="M0 20V0h20v20z" />
      </clipPath>
      <clipPath id="material_svg__b">
        <path fill="#fff" d="M1 1.294h18v18H1z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMaterial;
