import * as React from 'react';
const SvgPlay = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625 9.998a9.375 9.375 0 1 0 18.75 0 9.375 9.375 0 1 0-18.75 0"
      />
      <path
        strokeWidth={1.249995}
        d="M7.5 13.01a1.363 1.363 0 0 0 2.26 1.027L14.375 10 9.76 5.96A1.363 1.363 0 0 0 7.5 6.988Z"
      />
    </g>
  </svg>
);
export default SvgPlay;
