import * as React from 'react';
const SvgDownloadZip = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M5.203 10.002h1.511a.6.6 0 0 1 .56.823L5.53 15.178a.6.6 0 0 0 .553.824h1.52M10 16v-6M12.4 16v-6M12.403 10.002h.6a1.8 1.8 0 1 1 0 3.6h-.6"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.649 4.849a1.2 1.2 0 0 1 .351.848v11.505A1.8 1.8 0 0 1 17.203 19h-14.4A1.8 1.8 0 0 1 1 17.202v-14.4A1.8 1.8 0 0 1 2.803 1h11.5a1.2 1.2 0 0 1 .849.351z"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M14.2 1v3.6a1.2 1.2 0 0 0 1.2 1.2H19"
    />
  </svg>
);
export default SvgDownloadZip;
