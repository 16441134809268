import React, { Component } from 'react';
import { Upload, Modal, message, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import './styles.scss';

import { API_HOST } from '../../store/resources/dataSource';

class UploadThumbnail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: props.thumbnailUrl
        ? [
            {
              uid: '-1',
              status: 'done',
              url: props.thumbnailUrl,
            },
          ]
        : [],
    };
  }

  handleChange = ({ fileList, file }) => {
    const { handleRemoveImage = () => {} } = this.props;
    this.setState({ fileList });
    if (file.status === 'done') {
      this.successFileUpload(file);
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    } else if (file.status === 'removed') {
      handleRemoveImage();
    }
  };

  successFileUpload = (file) => {
    const { handleUploadedImage } = this.props;
    this.setState({ fileName: file.name });
    handleUploadedImage({ uuid: file.response.uuid, url: file.response.href });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { thumbnailUrl } = this.props;
    const { fileName } = this.state;
    if (prevProps.thumbnailUrl !== thumbnailUrl) {
      this.setState({
        fileList: thumbnailUrl
          ? [
              {
                uid: '-1',
                status: 'done',
                url: thumbnailUrl,
                name: fileName,
              },
            ]
          : [],
      });
    }
  }

  render() {
    const { fileList } = this.state;
    const { isSmall } = this.props;

    const uploadButton = (
      <div>
        {isSmall ? (
          <Button>
            <PlusOutlined />
          </Button>
        ) : (
          <>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
          </>
        )}
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          action={`${API_HOST}/file/thumbnail`}
          listType={isSmall ? 'picture' : 'picture-card'}
          accept="image/*"
          fileList={fileList}
          onChange={this.handleChange}
          className={isSmall ? 'smallSize' : ''}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </div>
    );
  }
}

export default UploadThumbnail;
