import * as React from 'react';
const SvgFly = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#fly_svg__a)"
    >
      <path d="M13.919 4.392 4.036 6.459l-1.078-1.38-1.618.249A.785.785 0 0 0 .85 6.54l2.125 3.081a1.56 1.56 0 0 0 1.599.65l2.827-.572-1.031 3.85a.774.774 0 0 0 .899.97l1.468-.296a.79.79 0 0 0 .535-.395l2.736-5.057 2.687-.54a1.957 1.957 0 1 0-.776-3.84" />
      <path d="M11.356 4.93 8.7 2.55a.79.79 0 0 0-.677-.181l-1.443.288a.785.785 0 0 0-.462 1.249L7.53 5.728M13.567 16.86a1.606 1.606 0 1 1 1.151-2.724 2.141 2.141 0 1 1 2.06 2.724z" />
    </g>
    <defs>
      <clipPath id="fly_svg__a">
        <path fill="#fff" d="M20 0H0v20h20z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgFly;
