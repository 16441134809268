import * as React from 'react';
const SvgContentApplet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#757575"
      d="M17.5 0h-15A2.503 2.503 0 0 0 0 2.5v15A2.503 2.503 0 0 0 2.5 20h15a2.503 2.503 0 0 0 2.5-2.5v-15A2.503 2.503 0 0 0 17.5 0m-3.76 10.934L8.026 14.15a1.073 1.073 0 0 1-1.597-.935V6.786a1.073 1.073 0 0 1 1.597-.929l5.714 3.214a1.073 1.073 0 0 1 0 1.869z"
    />
  </svg>
);
export default SvgContentApplet;
