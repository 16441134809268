import * as React from 'react';
const SvgEducativeToys = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625 19.375h18.75M11.25 15h6.25v4.375h-6.25ZM2.5 15h6.25v4.375H2.5ZM2.5 10.625h6.25V15H2.5ZM2.5 6.25h6.25v4.375H2.5ZM5.625.625V6.25M14.375.625V15"
      />
    </g>
  </svg>
);
export default SvgEducativeToys;
