import * as React from 'react';
const SvgLargeRightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 10.765h16M14.235 14.53 18 10.764 14.235 7"
    />
  </svg>
);
export default SvgLargeRightArrow;
