import * as React from 'react';
const SvgLittlegroup = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M14.297 7.956a1.813 1.813 0 1 0 3.626 0 1.813 1.813 0 0 0-3.626 0M13.088 15a3.022 3.022 0 0 1 6.044 0M8.253 4.813a1.813 1.813 0 1 0 3.626 0 1.813 1.813 0 0 0-3.626 0M7.044 11.857a3.022 3.022 0 1 1 6.044 0M2.209 7.956a1.813 1.813 0 1 0 3.626 0 1.813 1.813 0 0 0-3.626 0M1 15a3.022 3.022 0 0 1 6.044 0"
    />
  </svg>
);
export default SvgLittlegroup;
