import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { SAVE_TAB, REQUEST_TABS } from 'constants/ActionTypes';
import {
  actionSetTabs,
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../mainActions';
import * as translationApi from '../translations/dataSource';
import * as tabApi from './dataSource';
import { defaultLanguage } from '../../util/languageList';

function* runRequestTabs({ locale }) {
  yield put(fetchStart());
  try {
    const res = yield tabApi.getTabs(locale);
    const tabs = res.data.translated_tabs;
    yield put(actionSetTabs(tabs));
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

function* runSaveTab({ tabs }) {
  yield put(fetchStart());
  try {
    const translations = [];
    Object.keys(tabs).forEach((locale) => {
      translations.push({ locale, translation: tabs[locale] });
    });
    const translationResponse = yield translationApi.requestSingleTranslation({
      translations,
    });
    const translationId = translationResponse.data.uuid;
    yield tabApi.createTab({ translation_id: translationId });
    yield runRequestTabs({ locale: defaultLanguage });
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

const watcher = () =>
  function* watch() {
    yield takeLatest(SAVE_TAB, runSaveTab);
    yield takeLatest(REQUEST_TABS, runRequestTabs);
  };

export default function* tabsSagas() {
  yield all([fork(watcher())]);
}
