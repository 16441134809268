import * as React from 'react';
const SvgWarmUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g stroke="#757575" clipPath="url(#Warm-up_svg__a)">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M9.381 13.093h2.475v3.711l3.711-3.711h2.474a1.237 1.237 0 0 0 1.237-1.237V1.959A1.237 1.237 0 0 0 18.041.722H8.144a1.237 1.237 0 0 0-1.237 1.237"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.443}
        d="M3.814 8.144a2.474 2.474 0 1 0 0-4.948 2.474 2.474 0 0 0 0 4.948"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M6.907 14.33v-1.856a3.093 3.093 0 1 0-6.185 0v1.856h1.237l.618 4.948h2.475l.618-4.948zM11.237 5.98a1.547 1.547 0 1 1 1.547 1.546"
      />
      <path
        strokeWidth={1.25}
        d="M12.784 10a.31.31 0 1 1 0-.619M12.784 10a.31.31 0 1 0 0-.619"
      />
    </g>
    <defs>
      <clipPath id="Warm-up_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWarmUp;
