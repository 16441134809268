import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { getAllCourses } from '../store/sitemaps/actions';

export default function useCourses() {
  const dispatch = useDispatch();
  const courses = useSelector(({ sitemaps }) =>
    sitemaps?.courses?.sort((a, b) => a.order - b.order)
  );

  useEffect(() => {
    if (courses === undefined) {
      dispatch(getAllCourses());
    }
  }, [courses]);

  return courses;
}
