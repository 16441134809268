import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
} from 'constants/ActionTypes';
import { SET_VALIDATION } from '../../constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  message: '',
  success: false,
  isValid: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: '',
        message: '',
        loading: true,
        success: false,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: '',
        loading: false,
        success: true,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.payload,
        loading: false,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        message: '',
        success: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
      };
    }
    case SET_VALIDATION: {
      return {
        ...state,
        isValid: action.isValid,
      };
    }
    default:
      return state;
  }
};
