import { axiosInstance } from '../../util/axiosInstance';

export const API_USERS = process.env.REACT_APP_API_USERS;
const MAKE_AUTH_WEBHOOK = process.env.REACT_APP_MAKE_AUTH_WEBHOOK;

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json;charset=utf-8',
};

export const sendLogin = async (data) => {
  const url = `${API_USERS}/auth/openid-connect/token`;

  const response = await axiosInstance({
    method: 'POST',
    url,
    headers: {},
    data: {
      grant_type: 'password',
      client_id: process.env.REACT_APP_USERS_CLIENT_ID,
      username: data.email,
      password: data.password,
      scope: 'openid profile email',
    },
  });

  return response;
};

export const sendLoginGoogle = async (token) => {
  const url = `${API_USERS}/auth/openid-connect/token`;
  const data = JSON.stringify({
    grant_type: 'google_token',
    client_id: process.env.REACT_APP_USERS_CLIENT_ID,
    token,
  });

  const response = await axiosInstance({
    url,
    method: 'POST',
    data,
    headers: { ...BASE_HEADERS },
  });

  return response;
};

export const getSettings = async () => {
  const url = `${API_USERS}/user/me`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};

export const validateEmailAuth = async (email) => {
  const url = `${MAKE_AUTH_WEBHOOK}?e=${email}`;
  try {
    const response = await fetch(url);

    // Check if the response status is 401
    if (response.status === 401) {
      return false;
    }

    // Check if the response status is 200
    if (response.status === 200) {
      const responseBody = await response.text();

      // Check if the response body contains the email
      if (responseBody === email) {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};
