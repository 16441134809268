import * as React from 'react';
const SvgUser = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M6.4 5.967a3.967 3.967 0 1 0 7.933 0 3.967 3.967 0 0 0-7.933 0M3 19a7.367 7.367 0 0 1 14.733 0"
    />
  </svg>
);
export default SvgUser;
