import * as React from 'react';
const SvgGoogleDrive = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M3.567 18.25H16.55l2.825-4.95H6.392"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M13.533 13.333h5.834L12.917 1.75H7.083c.267.467 6.45 11.583 6.45 11.583"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.075 1.75.625 12.942l2.942 5.308S9.725 7.425 10 6.967"
    />
  </svg>
);
export default SvgGoogleDrive;
