import React from 'react';
import { Select } from 'antd';
import * as IconComponents from './Icons';
import icons from './selectIcons';

const IconSelector = ({
  noBgColor,
  placeholder,
  className,
  value,
  onChange,
}) => {
  const handleChange = (value) => {
    const selectedIcon = icons.find((icon) => icon.value.toString() === value);
    onChange({ value: selectedIcon ? selectedIcon.value : '' });
  };

  const currentValue = icons
    .find((icon) => icon.value === value)
    ?.value.toString();

  const options = icons.map((icon) => ({
    value: icon.value.toString(),
    searchValue: icon.key,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {React.createElement(IconComponents[icon.key], {
          style: {
            width: '20px',
            marginRight: '10px',
          },
        })}
        {icon.key}
      </div>
    ),
  }));

  return (
    <div className="inm-select-container">
      <span>Icon*</span>
      <Select
        showSearch
        className={className}
        value={currentValue || undefined}
        onChange={handleChange}
        placeholder={placeholder}
        style={{ backgroundColor: noBgColor ? 'transparent' : '' }}
        filterOption={(input, option) =>
          option.searchValue.toLowerCase().includes(input.toLowerCase())
        }
        options={options}
        optionFilterProp="searchValue"
      />
    </div>
  );
};

export default IconSelector;
