import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, Checkbox, Input, TreeSelect } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { MenuOutlined } from '@ant-design/icons';
import React from 'react';
import { getCoursesFromTags } from '../../util/helpers';
import { defaultLanguage } from '../../util/languageList';

const SearchDragCRUD = (props) => {
  const {
    onDragEnd,
    searchID,
    setSearchID,
    guideOrPrintableStep,
    guideStep,
    searchType,
    getContentTypes,
    setSearchType,
    error,
    handleSearchElement,
    loading,
    searchResult,
    allResults,
    getAllElementsList,
    tagsList,
    addElement,
    elementsData,
    isPreview,
    handleChangeInput,
    addedElements,
    removeElement,
    type,
    pageId,
    suggestedListStep,
  } = props;

  const isSession = type === 'session';
  const isList = type === 'resourceList';
  const isPage = type === 'page';

  const getTimeFromSecs = (totalSecs, format) => {
    const hours = Math.floor(totalSecs / 3600);
    const minutes = Math.floor((totalSecs - hours * 3600) / 60);
    const seconds = totalSecs - hours * 3600 - minutes * 60;
    if (format === 'string') {
      return `${hours ? `${hours}h` : ''} ${minutes ? `${minutes}min` : ''} ${
        seconds ? `${seconds}s` : ''
      }`;
    }
    const _hours = hours >= 10 ? hours : hours > 0 ? `0${hours}:` : '';
    const _minutes = minutes >= 10 ? `${minutes}:` : `0${minutes}:`;
    const _seconds = seconds >= 10 ? `${seconds}:` : `0${seconds}`;
    return `${_hours}${_minutes}${_seconds}`;
  };

  const getTitleStep = () => {
    if (guideOrPrintableStep && guideStep) return 'Select guide';
    if (guideOrPrintableStep && suggestedListStep) {
      return 'Select suggested lists';
    }
    if (guideOrPrintableStep) return 'Select printables';
    return 'Select the resources';
  };

  const getTitleType = (type) => {
    if (isPage || searchType === 'resourceList') return 'List ';
    if (searchType === 'digitalGuide') return 'Digital Guide ';
    return type;
  };

  const isSearchDisabled = !(searchID && searchType);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="inm-newContent-twoColumns">
        <div className="inm-newContent-columnLeft">
          <div className="inm-newContent-topRow">
            <div className="inm-newContent-selectResourcesSubtitle">
              {getTitleStep()}
            </div>
            <div className="inm-newContent-searchArea">
              <Input
                placeholder="ID"
                value={searchID}
                onChange={(e) => setSearchID(e.target.value)}
              />
              {/* <Input
                placeholder="Resource name"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              /> */}
              <TreeSelect
                value={searchType === '' ? null : searchType}
                treeData={getContentTypes()}
                placeholder="Type"
                onChange={(value) => setSearchType(value)}
                treeDefaultExpandAll
                className="inm-newContent-dropDown"
              />
            </div>
            <div className="inm-newContent-idArea">
              <div
                className={`inm-newContent-error ${
                  !error ? 'inm-newContent-hide' : ''
                }`}
              >
                Results not found.
              </div>
              <Button
                disabled={isSearchDisabled}
                onClick={handleSearchElement}
                loading={loading}
              >
                Search
              </Button>
            </div>
            <div className="inm-newContent-marginBottom10">Search results</div>
          </div>
          <div className="inm-newContent-bottomRow">
            <Droppable droppableId="search">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {(!isEmpty(searchResult) ? [searchResult] : allResults).map(
                    (result, index) => {
                      const isSpecialItem =
                        searchType === 'resourceList' ||
                        searchType === 'digitalGuide';
                      let locales = [];
                      if (result.translations) {
                        locales = Object.keys(result.translations);
                      }
                      if (result.available_locales) {
                        locales = result.available_locales;
                      }
                      return (
                        <Draggable
                          draggableId={result.uuid}
                          index={index}
                          key={result.uuid}
                        >
                          {(_provided) => (
                            <div
                              {..._provided.draggableProps}
                              {..._provided.dragHandleProps}
                              ref={_provided.innerRef}
                              className="inm-newContent-resultBox"
                            >
                              <div className="inm-newContent-resultType">
                                {getTitleType(result.type)}
                              </div>
                              <div className="inm-newContent-resultInfoContainer">
                                {!isPage && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        (result.translations && result.translations[defaultLanguage]?.title) ||
                                        result.name,
                                    }}
                                  />
                                )}
                                {isPage && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: result.title || result.name,
                                    }}
                                  />
                                )}
                                <div className="inm-newContent-resultDuration">
                                  {!isPage &&
                                    !isSpecialItem &&
                                    locales?.map((language) => (
                                      <div
                                        key={language}
                                        className="inm-newContent-box inm-newContent-searchResultLang"
                                      >
                                        {language}
                                      </div>
                                    ))}
                                  {!isPage &&
                                    !isSpecialItem &&
                                    result.tags.length > 0 &&
                                    tagsList?.map((tag) => {
                                      if (
                                        result.tags.some(
                                          (_tag) =>
                                            tag.uuid === _tag.uuid &&
                                            getCoursesFromTags(tag.translation)
                                        )
                                      ) {
                                        return (
                                          <div
                                            key={tag.uuid}
                                            className="inm-newContent-courseBox"
                                          >
                                            {tag.translation}
                                          </div>
                                        );
                                      }
                                    })}
                                  {!isPage &&
                                    !isSpecialItem &&
                                    result.tags?.map((tag) => {
                                      if (tag.uuid) return;
                                      if (getCoursesFromTags(tag)) {
                                        return (
                                          <div
                                            key={tag}
                                            className="inm-newContent-courseBox"
                                          >
                                            {tag}
                                          </div>
                                        );
                                      }
                                    })}
                                  {result.duration
                                    ? getTimeFromSecs(result.duration)
                                    : '-'}
                                  <div
                                    className="inm-newContent-addResult"
                                    onClick={() => addElement(result, 1)}
                                  >
                                    +
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                </div>
              )}
            </Droppable>
          </div>
        </div>
        <div className="inm-newContent-columnRight">
          {
            <div
              className={`inm-newContent-addedElementsInfo ${
                elementsData.totalResources ? '' : 'inm-newContent-hide'
              }`}
            >
              {isSession && !guideOrPrintableStep
                ? `${
                    elementsData.totalResources
                  } resources added. Total duration ${getTimeFromSecs(
                    elementsData.totalDuration,
                    'string'
                  )}`
                : `${elementsData.totalResources} resources added.`}
            </div>
          }
          {isList && (
            <div className="inm-newContent-linkIdPage">
              <Checkbox
                className="inm-newContent-checkbox"
                checked={isPreview}
                onChange={(e) => {
                  if (!e.target.checked) handleChangeInput('pageId', null);
                  handleChangeInput('isPreview', e.target.checked);
                }}
              >
                Link to page
              </Checkbox>
              {isList && (
                <Input
                  placeholder="Page ID"
                  value={pageId}
                  onChange={(e) => handleChangeInput('pageId', e.target.value)}
                  disabled={!isPreview}
                />
              )}
            </div>
          )}
          {Object.keys(addedElements).map((block) => (
            <div
              key={block}
              className={`inm-newContent-blockContainer ${
                isSession ? '' : 'inm-newContent-fullHeight'
              }`}
            >
              {isSession && !guideOrPrintableStep && (
                <div className="inm-newContent-blockNumber">
                  {`Block ${block}`}
                </div>
              )}
              <Droppable droppableId={block}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`inm-newContent-dragAndDropZone ${
                      isSession ? '' : 'inm-newContent-fullHeight'
                    }`}
                  >
                    {addedElements[block].map((resource, index) => (
                      <Draggable
                        draggableId={resource.uuid + block + index}
                        index={index}
                        key={resource.uuid + index}
                      >
                        {(_provided) => (
                          <div
                            {..._provided.draggableProps}
                            {..._provided.dragHandleProps}
                            ref={_provided.innerRef}
                            className="inm-newContent-resultBox"
                          >
                            <div className="inm-newContent-menuIcon">
                              <MenuOutlined />
                            </div>
                            <div className="inm-newContent-resultInfoContainer">
                              <div className="inm-newContent-resultTitle">
                                {!isPage && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        (resource.translations && resource.translations[defaultLanguage]?.title) ||
                                        resource.name,
                                    }}
                                  />
                                )}
                                {isPage && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: resource.title || resource.name,
                                    }}
                                  />
                                )}
                              </div>
                              <div className="inm-newContent-resultDuration">
                                {resource.duration
                                  ? getTimeFromSecs(resource.duration)
                                  : '-'}
                                <div
                                  className="inm-newContent-removeResource"
                                  onClick={() => removeElement(index, block)}
                                >
                                  X
                                </div>
                              </div>
                              {_provided.placeholder}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <div className="inm-newContent-dragAndDropPlaceholder">
                      Drag content to add
                    </div>
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </div>
    </DragDropContext>
  );
};

export default SearchDragCRUD;
