import * as React from 'react';
const SvgRatingStar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#rating-star_svg__a)"
    >
      <path d="M9.375 14.375a5 5 0 1 0 10 0 5 5 0 0 0-10 0M14.375 11.875v5M11.875 14.375h5" />
      <path d="m18.943 8.427.232-.23a.671.671 0 0 0-.417-1.145l-5.366-.531L10.608 1a.683.683 0 0 0-1.216 0L6.605 6.52l-5.367.532a.67.67 0 0 0-.416 1.145l4.416 4.378-1.63 5.948a.677.677 0 0 0 .96.78L7.68 17.76" />
    </g>
    <defs>
      <clipPath id="rating-star_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRatingStar;
