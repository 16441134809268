import * as React from 'react';
const SvgBoardGameJenga = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625 15.625h5v3.75h0-4.167a.833.833 0 0 1-.833-.833zM14.303 11.875H1.875a1.25 1.25 0 0 0-1.25 1.25v2.5h14.472M5.625 15.625h5v2.917a.833.833 0 0 1-.833.833H5.625h0zM1.458.625h3.334a.833.833 0 0 1 .833.833v2.917h0-5 0V1.458a.833.833 0 0 1 .833-.833M11.458.625h3.334a.833.833 0 0 1 .833.833v2.917h0-5 0V1.458a.833.833 0 0 1 .833-.833M5.625 8.125h5v3.75h-5z"
      />
      <path
        strokeWidth={1.249995}
        d="m12.837 4.919 3.67-.774 3.099 14.687-3.67.774Z"
      />
      <path
        strokeWidth={1.249995}
        d="M15.625 4.375h-15v2.5a1.25 1.25 0 0 0 1.25 1.25h11.638"
      />
    </g>
  </svg>
);
export default SvgBoardGameJenga;
