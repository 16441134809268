import * as React from 'react';
const SvgContentFlag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#content_flag_svg__a)">
      <path
        fill="#757575"
        d="M13.727 5.586a.36.36 0 0 1 0-.457l2.81-3.372A1.071 1.071 0 0 0 15.714 0H4.286a1.07 1.07 0 0 0-1.072 1.071V18.93a1.071 1.071 0 0 0 2.143 0V11.07a.357.357 0 0 1 .357-.357h10a1.072 1.072 0 0 0 .823-1.757z"
      />
    </g>
    <defs>
      <clipPath id="content_flag_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgContentFlag;
