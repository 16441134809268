import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PDFReader } from 'reactjs-pdf-reader';
import ReactPlayer from 'react-player';
import { Divider, Checkbox, Tag, Spin, Table } from 'antd';
import CircularProgress from '../CircularProgress';

const ResourcePreview = (props) => {
  const { CheckableTag } = Tag;
  const resource = useSelector(({ resources }) => resources.currentResource);
  const {
    type,
    translations,
    thumbnailUrl,
    isPrintable,
    isGuide,
    pack,
    scene,
    tags,
    isDownloadable,
    isProjectable,
    isPublic,
    isCompletable,
    sessionCode,
  } = resource;

  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (translations) {
      const languagesAvailable = Object.keys(translations).map((lang) => lang);
      setLanguage(languagesAvailable[0]);
    }
  }, [resource]);

  if (!translations || !language) {
    return (
      <div className="inm-preview-spinnerWrapper">
        <Spin size="large" />
      </div>
    );
  }

  const languagesAvailable = Object.keys(translations).map((lang) => lang);

  const {
    fileUrl,
    fileName,
    title,
    description,
    fragments,
    resources,
    guide,
    printables,
  } = translations[language];

  const getResourcePreview = () => {
    switch (type) {
      case 'pdf':
        return (
          <PDFReader
            url={fileUrl}
            key={fileUrl}
            showAllPage
            // page={page}
            scale={1}
          />
        );
      case 'genially':
        return (
          <iframe
            title="Genially Viewer"
            frameBorder="0"
            src={fileUrl}
            allowFullScreen
            scrolling="yes"
          />
        );
      case 'applet':
        return <div>APPLET Preview Pending</div>;
      case 'vimeo':
        return (
          <ReactPlayer url={fileUrl} width="100%" height="100%" controls />
        );
      case 'manipulativeEnvironment':
        return <div>MANIPULATIVE Preview Pending</div>;
      case 'geogebra':
        return <div>GEOGEBRA Preview Pending</div>;
      case 'generic':
        return <div>GENERIC File Preview Pending</div>;
      case 'session':
        return (
          <div>
            {resources &&
              Object.keys(resources).map((block) => (
                <div key={block}>
                  {`Block ${block}`}
                  {resources[block].map((r) => (
                    <div className="inm-newContent-resultBox" key={r.uuid}>
                      <div className="inm-newContent-resultType">{r.type}</div>
                      <div className="inm-newContent-resultInfoContainer">
                        <div>{r.name}</div>
                        <div>{r.uuid}</div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            {guide && (
              <div>
                Guide
                <div className="inm-newContent-resultBox" key={guide.uuid}>
                  <div className="inm-newContent-resultType">{guide.type}</div>
                  <div className="inm-newContent-resultInfoContainer">
                    <div>{guide.name}</div>
                    <div>{guide.uuid}</div>
                  </div>
                </div>
              </div>
            )}
            {printables && (
              <div>
                Printables
                {printables.map((printable) => (
                  <div
                    className="inm-newContent-resultBox"
                    key={printable.uuid}
                  >
                    <div className="inm-newContent-resultType">
                      {printable.type}
                    </div>
                    <div className="inm-newContent-resultInfoContainer">
                      <div>{printable.name}</div>
                      <div>{printable.uuid}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      default:
        return '';
    }
  };

  const getFragments = () => {
    const columns = [
      {
        title: 'Thumb',
        dataIndex: 'thumbnail_url',
        key: 'img',
        render: (thumbnail_url) =>
          thumbnail_url ? (
            <img
              src={thumbnail_url}
              className="inm-preview-fragmentThumb"
              alt="Fragment thumb"
            />
          ) : (
            'No image'
          ),
        width: '0%',
      },
      {
        title: 'From',
        dataIndex: 'from',
        key: 'from',
      },
      {
        title: 'To',
        dataIndex: 'to',
        key: 'to',
      },
      {
        title: 'Caption',
        dataIndex: 'caption',
        key: 'caption',
      },
      {
        title: 'Visibility Type',
        dataIndex: 'visibility_type',
        key: 'visibility_type',
      },
    ];
    return (
      <Table
        dataSource={fragments}
        columns={columns}
        pagination={false}
        className="inm-preview-fragments "
      />
    );
  };

  const getResourceFields = () => {
    switch (type) {
      case 'applet':
        return (
          <div>
            {`Pack: ${pack}`}
            <br />
            {`Scene: ${scene}`}
          </div>
        );
      case 'genially':
      case 'pdf':
        return (
          <div>
            <p>{`File Name: ${fileName}`}</p>
            <p>{`Url: ${fileUrl}`}</p>
            <p>Fragments:</p>
            {getFragments()}
          </div>
        );
      case 'vimeo':
        return <p>{`Url: ${fileUrl}`}</p>;
      default:
        break;
    }
  };

  const getTagsList = () => {
    const tagList = tags.translations.map((tagArray) => {
      const translatedTag = tagArray.find(
        (tagTrans) => tagTrans.locale === language
      );
      const output = translatedTag ? (
        <Tag key={translatedTag.translation}>{translatedTag.translation}</Tag>
      ) : (
        <Tag key={language} color="red">
          Missing tag: {language}
        </Tag>
      );
      return output;
    });
    return tagList;
  };

  return (
    <div className="inm-preview-modal">
      <div className="inm-preview-description">
        {thumbnailUrl && (
          <img
            src={thumbnailUrl}
            alt="Thumbnail"
            className="inm-preview-thumbnail"
          />
        )}
        <div className="inm-preview-maindata">
          <span>
            <strong>Resource type: </strong>
            {type}
          </span>
          <span>
            <strong>Title: </strong>
            <div
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </span>
          <span>
            <strong>Description: </strong>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </span>
          <span>
            <strong>Session Code: </strong>
            <div>{sessionCode}</div>
          </span>
        </div>
      </div>
      <Divider />
      <div className="inm-preview-otherInfo">
        <div className="inm-preview-checkboxes">
          <span>
            <Checkbox checked={isPrintable} />
            Printable
          </span>
          <span>
            <Checkbox checked={isGuide} />
            Guide
          </span>
          <span>
            <Checkbox checked={isDownloadable} />
            Downloadable
          </span>
          <span>
            <Checkbox checked={isProjectable} />
            Projectable
          </span>
          <span>
            <Checkbox checked={isCompletable} />
            Completable
          </span>
          <span>
            <Checkbox checked={isPublic} />
            Public
          </span>
        </div>
        <div className="inm-preview-languagesAvailable">
          {languagesAvailable.map((lang) => (
            <CheckableTag
              key={lang}
              checked={lang === language}
              onChange={() => setLanguage(lang)}
            >
              {lang}
            </CheckableTag>
          ))}
        </div>
      </div>
      <Divider />
      <div className="inm-preview-wrapper">
        <div className="inm-preview-area">{getResourcePreview()}</div>
        <div className="inm-preview-sidearea">
          <p>Tags:</p>
          <p>{getTagsList()}</p>
          <Divider />
          {getResourceFields()}
        </div>
      </div>
    </div>
  );
};

export default ResourcePreview;
