import * as React from 'react';
const SvgExpandSquare = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M13.077 2H18v4.923M6.923 2H2v4.923M13.077 18H18v-4.923M6.923 18H2v-4.923"
    />
  </svg>
);
export default SvgExpandSquare;
