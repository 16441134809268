import * as React from 'react';
const SvgTouch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#touch_svg__clip0_3764_7343)"
    >
      <path d="m7.898 19.373-3.209-4.277a1.28 1.28 0 0 1-.04-1.475v0a1.28 1.28 0 0 1 1.829-.314l1.416 1.066V6.25a1.25 1.25 0 0 1 2.5 0v5l3.479.773a2.5 2.5 0 0 1 1.947 2.667l-.426 4.685zM9.148 2.498V.622M11.648 3.123l1.25-1.25M6.648 3.123l-1.25-1.25M12.273 4.998h1.875M6.023 4.998H4.148" />
    </g>
  </svg>
);
export default SvgTouch;
