import * as React from 'react';
const SvgTaskListClock = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M9.375 14.384a5 5 0 1 0 10 0 5 5 0 1 0-10 0"
      />
      <path
        strokeWidth={1.249995}
        d="M16.585 14.384h-2.21v-2.21M4.375 8.76H8.75M4.375 11.884h2.5M4.375 15.01h2.5M8.125 19.384h-6.25a1.25 1.25 0 0 1-1.25-1.25V5.01a1.25 1.25 0 0 1 1.25-1.25H5a3.125 3.125 0 0 1 6.25 0h3.125a1.25 1.25 0 0 1 1.25 1.25v1.875"
      />
      <path
        strokeWidth={1.249995}
        d="M8.125 3.134a.313.313 0 1 1-.312.313.313.313 0 0 1 .312-.313"
      />
    </g>
  </svg>
);
export default SvgTaskListClock;
