import {
  CLEAN_PAGINATION_FILTER,
  SET_FILTERS,
  SET_PAGINATION,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  resources: {
    pagination: {
      offset: 0,
      length: 50,
    },
    filters: {},
  },
  lists: {
    pagination: {
      offset: 0,
      length: 50,
    },
    filters: {},
  },
  pages: {
    pagination: {
      offset: 0,
      length: 50,
    },
    filters: {},
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PAGINATION: {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          pagination: {
            offset: action.pagination.offset,
            length: action.pagination.length,
          },
        },
      };
    }
    case SET_FILTERS: {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          filters: {
            ...action.filters,
          },
        },
      };
    }
    case CLEAN_PAGINATION_FILTER: {
      return {
        ...INIT_STATE,
      };
    }
    default:
      return state;
  }
};
