import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import useRegions from '../../hooks/useRegions';
import useCourses from '../../hooks/useCourses';
import { cloneSitemap } from '../../store/mainActions';

const { Option } = Select;

export default function CloneSitemapModal({ sitemap, onClose }) {
  const dispatch = useDispatch();
  const [region, setRegion] = useState();
  const { regions } = useRegions();
  const [courseOrder, setCourseOrder] = useState();
  const [level, setLevel] = useState('DEFAULT');
  const courses = useCourses();
  const filterByRegion = (course) => course.region === region;
  const cloning = useSelector(({ sitemaps }) => sitemaps.cloning);
  const levelsList = ['DEFAULT', 'BASIC', 'MANAGER'];

  useEffect(() => {
    if (sitemap) {
      setRegion(sitemap.region);
      setCourseOrder(sitemap.courseOrder);
      setLevel(sitemap.level);
    }
  }, [sitemap]);

  useEffect(() => {
    if (!cloning) {
      onClose();
    }
  }, [cloning]);

  const handleClone = () => {
    dispatch(
      cloneSitemap({
        from: sitemap,
        to: {
          region,
          courseOrder,
          level,
        },
      })
    );
  };

  const handleChangeRegion = (value) => {
    setRegion(value);
    setCourseOrder();
    setLevel();
  };

  const handleChangeCourse = (value) => {
    setCourseOrder(+value);
    setLevel();
  };

  return (
    <Modal
      title="Clone Sitemap"
      visible={Boolean(sitemap)}
      onOk={handleClone}
      okText="Save and continue"
      onCancel={onClose}
      okButtonProps={{ disabled: !region || !courseOrder }}
      confirmLoading={cloning}
    >
      <div className="inm-craete-sitemap-container full-width">
        <div className="inm-select-container">
          Select region
          <Select
            required
            className="inm-select"
            onChange={handleChangeRegion}
            value={region}
          >
            {regions?.map((_region) => (
              <Option key={_region} value={_region}>
                {_region}
              </Option>
            ))}
          </Select>
        </div>
        <div className="inm-select-container">
          Select course
          <Select
            disabled={!region}
            required
            className="inm-select"
            onChange={handleChangeCourse}
            value={courseOrder}
          >
            {courses?.filter(filterByRegion).map((course) => (
              <Option key={course.order} value={course.order}>
                {course.order}
              </Option>
            ))}
          </Select>
        </div>
        <div className="inm-select-container">
          Select level
          <Select
            disabled={!region || !courseOrder}
            required
            className="inm-select"
            onChange={(value) => setLevel(value)}
            value={level}
          >
            {levelsList?.map((level) => (
              <Option key={level} value={level}>
                {level}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </Modal>
  );
}
