import * as React from 'react';
const SvgShrink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m18 2-4.267 4.267M6.267 18v-4.267H2M13.733 2v4.267H18M6.267 13.733 2 18M2 2l4.267 4.267M13.733 18v-4.267H18M6.267 2v4.267H2M13.733 13.733 18 18M8 8h4v4H8z"
    />
  </svg>
);
export default SvgShrink;
