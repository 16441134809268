import * as React from 'react';
const SvgDessarollo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#Dessarollo_svg__a)"
    >
      <path d="m18.75 1.306-1.798.2a6.25 6.25 0 0 0-3.73 1.792L5.05 11.471l3.533 3.535 8.175-8.173a6.25 6.25 0 0 0 1.792-3.729zM5.05 11.47l-3.018-1.006a.624.624 0 0 1-.245-1.034l.194-.193a5 5 0 0 1 5.116-1.209l1.046.349z" />
      <path d="m5.05 11.47-3.018-1.006a.624.624 0 0 1-.245-1.034l.194-.193a5 5 0 0 1 5.116-1.209l1.046.349zM8.583 15.006l1.007 3.018a.624.624 0 0 0 1.034.244l.194-.193a5 5 0 0 0 1.208-5.117l-.347-1.041z" />
      <path d="m8.583 15.006 1.007 3.018a.624.624 0 0 0 1.034.244l.194-.193a5 5 0 0 0 1.208-5.117l-.347-1.041zM6.483 15.448a1.87 1.87 0 0 1-.55 1.326c-.731.732-4.416 1.768-4.416 1.768s1.036-3.688 1.767-4.417c.322-.322.75-.516 1.204-.546" />
    </g>
    <defs>
      <clipPath id="Dessarollo_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDessarollo;
