import * as React from 'react';
const SvgCierre = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#Cierre_svg__a)"
    >
      <path d="M12.494 18.068c2.331.166 4.638.58 6.881 1.236M.625 19.375a32.8 32.8 0 0 1 7.427-1.327M10 19.375V10h7.5v5H10M6.838 10.477A5 5 0 1 1 10.433 7" />
      <path d="M1.293 3.126H2.5a1.25 1.25 0 0 1 1.25 1.25v.583A1.25 1.25 0 0 0 4.307 6l.798.532a1.167 1.167 0 0 1 0 1.944L3 9.878M9.957 3.126h-.584a1.25 1.25 0 0 0-1.116 1.809c.355.712.437.75.657.878l1.584.949" />
    </g>
    <defs>
      <clipPath id="Cierre_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCierre;
