import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Popconfirm, Table, Select } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  deleteSitemap,
  getSitemaps,
  toggleSitemap,
  setRegionFilter,
} from '../../store/mainActions';
import { getAllCourses } from '../../store/sitemaps/actions';
import ContainerHeader from '../../components/ContainerHeader';
import CloneSitemapModal from '../CloneSitemapModal';
import useLanguages from '../../hooks/useLanguages';
import useRegions from '../../hooks/useRegions';

const { Option } = Select;

const Sitemaps = () => {
  useLanguages();
  const { regions } = useRegions();
  const region = useSelector(({ sitemaps }) => sitemaps.regionFilter);

  const sitemapsList = useSelector(({ sitemaps }) => sitemaps?.sitemaps);
  const loading = useSelector(({ sitemaps }) => sitemaps.loading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [cloneSitemapModal, setCloneSitemapModal] = useState();

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  useEffect(() => {
    dispatch(getSitemaps(region));
  }, [region]);

  const columns = (onCopy, onEdit, onDelete, onActivate) => [
    {
      title: 'Active',
      dataIndex: 'active',
      width: '30%',
      render: (active, { id, numberOfNullPageId }) => (
        <Checkbox
          disabled={numberOfNullPageId > 0}
          checked={active}
          onChange={(e) => onActivate(id, e.target.checked)}
        />
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      render: (region) => region,
      width: '30%',
    },
    {
      title: 'Course',
      dataIndex: 'courseOrder',
      render: (courseOrder) => courseOrder,
      width: '15%',
    },
    {
      title: 'Level',
      dataIndex: 'level',
      render: (level) => level,
      width: '15%',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, all) => (
        <div style={{ display: 'flex' }}>
          {all.regions?.length > 0 ? (
            <Popconfirm
              title={`You are going to clone a sitemap that affects the following regions: ${all.regions.join(
                ', '
              )}. Are you sure?`}
              onConfirm={() => onCopy(all)}
              okText="Yes"
              placement="left"
              cancelText="Cancel"
              overlayStyle={{ maxWidth: '400px' }}
            >
              <div style={{ textAlign: 'center', padding: '0 5px' }}>
                <CopyOutlined />
              </div>
            </Popconfirm>
          ) : (
            <div style={{ textAlign: 'center', padding: '0 5px' }}>
              <CopyOutlined onClick={() => onCopy(all)} />
            </div>
          )}
          {all.regions?.length > 0 ? (
            <Popconfirm
              title={`You are going to edit a sitemap that affects the following regions: ${all.regions.join(
                ', '
              )}. Are you sure?`}
              onConfirm={() => onEdit(all)}
              okText="Yes"
              placement="left"
              cancelText="Cancel"
              overlayStyle={{ maxWidth: '400px' }}
            >
              <div style={{ textAlign: 'center', padding: '0 5px' }}>
                <EditOutlined />
              </div>
            </Popconfirm>
          ) : (
            <div style={{ textAlign: 'center', padding: '0 5px' }}>
              <EditOutlined onClick={() => onEdit(all)} />
            </div>
          )}
          <Popconfirm
            title={`Are you sure to delete this sitemap?${
              all.regions?.length > 0
                ? ` This sitemap affects the following regions: ${all.regions.join(
                    ', '
                  )}`
                : ''
            }`}
            onConfirm={() => onDelete(all.id)}
            okText="Yes"
            placement="left"
            cancelText="Cancel"
            overlayStyle={{ maxWidth: '400px' }}
          >
            <div
              style={{
                textAlign: 'center',
                padding: '0 5px',
                cursor: 'pointer',
              }}
            >
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const handleActivate = (id, checked) => {
    dispatch(toggleSitemap({ id, checked }));
  };
  const handleCopy = (sitemap) => {
    setCloneSitemapModal(sitemap);
  };
  const handleEdit = (sitemap) => {
    history.push(`/newSitemap/${sitemap.id}`);
  };
  const handleDelete = (id) => {
    dispatch(deleteSitemap(id));
  };

  return (
    <>
      <div className="inm-sitemaps-filter">
        <ContainerHeader title="Sitemaps" />

        <Select
          className="inm-sitemaps-filter-select"
          suffixIcon={<FilterOutlined />}
          value={region}
          style={{ width: 100 }}
          onChange={(value) => dispatch(setRegionFilter(value))}
        >
          {regions?.map((_region) => (
            <Option key={_region} value={_region}>
              {_region}
            </Option>
          ))}
        </Select>
      </div>
      <Table
        pagination={false}
        className="gx-table-no-bordered"
        columns={columns(handleCopy, handleEdit, handleDelete, handleActivate)}
        dataSource={sitemapsList}
        bordered={false}
        rowKey={(record) => record.id}
        size="medium"
        loading={loading}
      />
      <CloneSitemapModal
        sitemap={cloneSitemapModal}
        onClose={() => setCloneSitemapModal()}
      />
    </>
  );
};

export default Sitemaps;
