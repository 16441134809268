import * as React from 'react';
const SvgWarning = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="#F90" transform="rotate(-180 10 10)" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 11.25c.69 0 1.25-.56 1.25-1.25V6.25a1.25 1.25 0 1 0-2.5 0V10c0 .69.56 1.25 1.25 1.25M10 15a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWarning;
