import * as React from 'react';
const SvgStudentPermission = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M5.542 3.7a2.7 2.7 0 1 0 5.4 0 2.7 2.7 0 0 0-5.4 0M11.485 8.8A4.82 4.82 0 0 0 5 8.8"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M10.942 3.789c-.488.14-.993.211-1.5.211a5.38 5.38 0 0 1-3.654-1.425M2.1 13.3a2.7 2.7 0 1 0 5.401 0 2.7 2.7 0 0 0-5.4 0M8.6 19A4.82 4.82 0 0 0 1 19"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.5 13.389a5.41 5.41 0 0 1-5.154-1.214M18.25 13.125h-5c-.69 0-1.25.56-1.25 1.25v3.75c0 .69.56 1.25 1.25 1.25h5c.69 0 1.25-.56 1.25-1.25v-3.75c0-.69-.56-1.25-1.25-1.25M18.25 13.125h-5v-1.25a2.5 2.5 0 0 1 4.268-1.768"
    />
    <path
      fill="#000"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M15.75 15.962a.313.313 0 1 0 0 .625.313.313 0 0 0 0-.625"
    />
  </svg>
);
export default SvgStudentPermission;
