import * as React from 'react';
const SvgUserRegister = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#user-register_svg__a)"
    >
      <path
        strokeWidth={1.25}
        d="M9.6 6.033a3.033 3.033 0 1 0 6.067 0 3.033 3.033 0 0 0-6.067 0M7 16.633a5.633 5.633 0 1 1 11.267 0"
      />
      <path
        strokeWidth={0.8}
        d="M3.073 2.787a1.614 1.614 0 0 0-1.61 1.632l-.001 9.093 1.61 2.754 1.61-2.754V4.419a1.613 1.613 0 0 0-1.61-1.632M1.463 4.68l3.22-.001M1.462 5.992h3.22"
      />
      <path
        strokeWidth={0.8}
        d="m1.462 13.512 1.61-.647 1.61.644M3.072 5.992v6.873"
      />
    </g>
    <defs>
      <clipPath id="user-register_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUserRegister;
