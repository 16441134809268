import * as Api from './dataSource';

function setFormatData(data, fields) {
  const languages = Object.keys(data);
  const result = { translations: {} };

  fields.forEach((field) => {
    result.translations[field] = { translations: [] };
    result.translations[field].translations = languages
      .filter((language) => data[language][field])
      .map((language) => {
        const translation = {};
        translation.locale = language;
        if (field === 'title' || field === 'description') {
          translation.translation = data[language][field]
            ? data[language][field]
            : '';
        } else {
          translation.translation = data[language][field];
        }
        return translation;
      });
  });
  return result;
}

export function prepareDataToSend(data, type) {
  let result;

  switch (type) {
    case 'manipulativeEnvironment':
    case 'applet':
      // WIP Add all fields for translations
      result = setFormatData(data, ['title', 'description']);
      break;
    case 'session':
      result = setFormatData(data, ['title', 'description']);
      break;
    case 'vimeo':
      result = setFormatData(data, ['title', 'description']);
      break;
    default:
      result = setFormatData(data, ['title', 'description']);
      break;
  }
  return result;
}

export async function requestTranslations(data, type) {
  const dataReady = prepareDataToSend(data, type);
  try {
    const response = await Api.requestTranslations(dataReady);
    return response.data;
  } catch (e) {
    return e.response;
  }
}
