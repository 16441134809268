import * as React from 'react';
const SvgClipboard = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="m13.594 9.383-4.66 4.66a.68.68 0 0 1-.965 0L6.407 12.48"
      />
      <path
        strokeWidth={1.249995}
        d="M13.125 3.758h3.125a1.25 1.25 0 0 1 1.25 1.25v13.125a1.25 1.25 0 0 1-1.25 1.25H3.75a1.25 1.25 0 0 1-1.25-1.25V5.008a1.25 1.25 0 0 1 1.25-1.25h3.125a3.125 3.125 0 0 1 6.25 0"
      />
      <path
        strokeWidth={1.249995}
        d="M10.048 3.138a.313.313 0 1 1-.357.26.313.313 0 0 1 .356-.26"
      />
    </g>
  </svg>
);
export default SvgClipboard;
