import * as React from 'react';
const SvgRule = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M5.133 18A2.134 2.134 0 0 1 3 15.867V6.11c0-.33.079-.657.228-.953L4.65 2.313a.533.533 0 0 1 .953 0l1.422 2.844c.149.296.227.622.227.953v9.757A2.133 2.133 0 0 1 5.133 18M3 6.089h4.267M3 14.622h4.267M15.8 2h-4.267c-.589 0-1.066.478-1.066 1.067v13.866c0 .59.477 1.067 1.066 1.067H15.8c.59 0 1.067-.478 1.067-1.067V3.067c0-.59-.478-1.067-1.067-1.067M10.467 5.2h2.489M10.467 8.4h2.489M10.467 11.6h2.489M10.467 14.8h2.489"
    />
  </svg>
);
export default SvgRule;
