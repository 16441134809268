import * as React from 'react';
const SvgZoomout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#zoomout_svg__a)"
    >
      <path d="M15.73 8.176a7.554 7.554 0 1 1-15.108 0 7.554 7.554 0 0 1 15.107 0M4.375 8.124h7.5M13.518 13.516l5.857 5.858" />
    </g>
    <defs>
      <clipPath id="zoomout_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgZoomout;
