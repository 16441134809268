import * as React from 'react';
const SvgEducativeToysMath = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path strokeWidth={1.249995} d="M.625.625h18.75v18.75H.625Z" />
      <path
        strokeWidth={1.249995}
        d="M11.865 14.375a2.5 2.5 0 1 0 5 0 2.5 2.5 0 1 0-5 0M3.125 16.875l2.5-5 2.5 5zM3.125 3.75h5v5h-5zM14.39 3.327l.722 1.907h1.405a.345.345 0 0 1 .244.6l-1.221.853.677 1.553a.368.368 0 0 1-.525.46l-1.634-.917-1.635.917a.368.368 0 0 1-.525-.46l.677-1.553-1.22-.854a.344.344 0 0 1 .24-.601H13l.723-1.908a.373.373 0 0 1 .666.003"
      />
    </g>
  </svg>
);
export default SvgEducativeToysMath;
