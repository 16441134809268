import * as React from 'react';
const SvgEspais = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#espais_svg__a)"
    >
      <path d="M.625 4.38a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 0 0-7.5 0M.625 15.63a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 0 0-7.5 0M11.875 4.38a3.75 3.75 0 1 0 7.498 0 3.75 3.75 0 0 0-7.498 0M11.875 15.63a3.749 3.749 0 1 0 7.498 0 3.749 3.749 0 0 0-7.498 0" />
    </g>
    <defs>
      <clipPath id="espais_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEspais;
