import * as React from 'react';
const SvgArrowLeft2 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path d="M23.25 12H.75M11.25 1.5.75 12l10.5 10.5" />
    </g>
  </svg>
);
export default SvgArrowLeft2;
