import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BottomActions from '../../components/BottomActions';
import ContainerHeader from '../../components/ContainerHeader';
import useSitemaps from '../../hooks/useSitemaps';
import { saveGroupItem } from '../../store/mainActions';
import TranslationByLanguage from '../../components/TranslationByLanguage';
import SelectRoles from '../../components/SelectRoles';
import useLanguages from '../../hooks/useLanguages';

export default function NewSitemapGroup() {
  const { sitemap, rootItem, saving, menuItem, getTranslation } = useSitemaps();
  const dispatch = useDispatch();
  const { region, courseOrder } = sitemap || {};
  const history = useHistory();

  const [checkedRoles, setCheckedRoles] = useState([]);
  const [translations, setTranslations] = useState();

  const { getLanguagesWithTranslations: getLanguagesByRegion } = useLanguages();

  useEffect(() => {
    if (menuItem) {
      setCheckedRoles(menuItem.roles);
      setTranslations(getLanguagesByRegion(menuItem.translations));
    } else {
      setTranslations(getLanguagesByRegion([]));
    }
  }, [menuItem]);

  const validateData = () => {
    if (!checkedRoles.length) return false;
    if (translations.some((lang) => lang.name === '' || !lang)) return false;
    return true;
  };

  const handleSaveAndContinue = () => {
    // we sett the order to null beacuse we added the correct order in sagas
    const body = {
      parentMenuItemId: rootItem.id,
      sitemapId: sitemap.id,
      menuItemOrder: null,
      translations,
      roles: checkedRoles,
      rootMenuItemId: rootItem.parentMenuItemId || rootItem.id,
    };
    dispatch(saveGroupItem(body));
  };

  const getTitle = () => {
    if (menuItem) {
      return `Edit sitemap: ${region} - ${courseOrder} - ${getTranslation(
        menuItem
      )} - ${getTranslation(menuItem)}`;
    }

    return `Create sitemap: ${region} - ${courseOrder} - ${getTranslation(
      rootItem
    )} - New group`;
  };

  if (!sitemap) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <ContainerHeader title={getTitle()} />
      <div className="inm-craete-sitemap-group-container">
        <TranslationByLanguage
          displayName="Insert group name"
          translations={translations}
          setTranslations={setTranslations}
        />
        <div className="inm-roles-container">
          <SelectRoles
            checkedRoles={checkedRoles}
            setCheckedRoles={setCheckedRoles}
          />
        </div>
      </div>
      <div className="inm-footer-offset" />
      <BottomActions
        onSave={handleSaveAndContinue}
        onCancel={() => history.push(`/newSitemap/${sitemap.id}`)}
        validateData={validateData}
        saving={saving}
      />
    </>
  );
}
