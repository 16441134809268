import * as React from 'react';
const SvgPlayerArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m6 2 7.623 7.623a.533.533 0 0 1 0 .754L6 18"
    />
  </svg>
);
export default SvgPlayerArrowRight;
