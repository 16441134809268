import React, { useState, useEffect } from 'react';
import { Upload, message, Button, Input, Radio } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_HOST } from '../../../../store/resources/dataSource';

import UploadThumbnail from '../../../../components/UploadThumbnail';

const PdfComposer = (props) => {
  const {
    fileName,
    editFragment,
    fragments,
    addFragment,
    handleChangeInput,
    duration,
    translations,
  } = props;

  const [isPdfUploaded, setIsPdfUploaded] = useState(!!fileName);
  const [fileList, updateFileList] = useState([]);

  useEffect(() => {
    if (fileName) {
      const currentFile = [
        {
          uid: '1',
          name: fileName,
          status: 'done',
        },
      ];
      updateFileList(currentFile);
      setIsPdfUploaded(true);
    } else {
      updateFileList([]);
      setIsPdfUploaded(false);
    }
  }, [fileName]);

  const tableKeys = ['page', 'caption', 'thumbnail', 'visibility'];

  const renderTableHeader = () =>
    tableKeys.map((key, index) => (
      <th key={index} className="inm-newContent-tableHeader">
        {key.toUpperCase()}
      </th>
    ));

  const handleUploadedImage = ({ url, uuid }, fragment) => {
    editFragment('thumbnail_id', fragment.order, uuid);
    editFragment('thumbnail_url', fragment.order, url);
  };

  const renderTableData = () =>
    fragments.map((fragment) => (
      <tr key={fragment.order}>
        <td className="inm-newContent-tableCell">
          <Input disabled value={fragment.order} />
        </td>
        <td className="inm-newContent-tableCell">
          <Input
            value={fragment.caption}
            onChange={(e) =>
              editFragment('caption', fragment.order, e.target.value)
            }
          />
        </td>
        <td
          id="thumbContainer"
          className="inm-newContent-browseThumbnailContainer inm-newContent-tableCell"
        >
          <UploadThumbnail
            handleUploadedImage={(image) =>
              handleUploadedImage(image, fragment)
            }
            thumbnailUrl={fragment.thumbnail_url}
            isSmall
            handleRemoveImage={() => handleUploadedImage({}, fragment)}
          />
        </td>
        <td id="visibility">
          <Radio.Group
            onChange={(e) =>
              editFragment('visibility_type', fragment.order, e.target.value)
            }
            value={fragment.visibility_type}
          >
            <Radio value="BLANK">Study content</Radio>
            <Radio value="ACTIVITY">Activity</Radio>
            <Radio value="SOLUTION">Solution</Radio>
          </Radio.Group>
        </td>
      </tr>
    ));

  const handleUploadPdf = (numPages) => {
    for (let i = 1; i <= numPages; i += 1) {
      const fragment = {
        order: i,
        caption: '',
        thumbnail_id: null,
        visibility_type: 'BLANK',
      };
      addFragment(fragment);
    }
  };

  const successFileUpload = (file) => {
    handleChangeInput('fileName', file.name, true);
    handleChangeInput('fileId', file.response.uuid, true);
    handleUploadPdf(file.response.length);
  };

  const clearFileData = () => {
    handleChangeInput('fileName', '', true);
    handleChangeInput('fileId', '', true);
    handleChangeInput('fragments', [], true);
  };

  const handleOnChange = (info) => {
    // file.status is empty when beforeUpload return false
    updateFileList(info.fileList.filter((file) => !!file.status));
    setIsPdfUploaded(!!info.file.status);

    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'removed') {
      setIsPdfUploaded(false);
      clearFileData();
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      successFileUpload(info.file);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const checkFileType = (file) => {
    if (file.type !== 'application/pdf') {
      message.error(`${file.name} is not a pdf file`);
    }
    return file.type === 'application/pdf';
  };

  return (
    <div className="inm-newContent-browseContainer">
      Upload the resource
      <div className="inm-newContent-browsePdf">
        <Upload
          fileList={fileList}
          name="file"
          action={`${API_HOST}/file/pdf`}
          onChange={handleOnChange}
          beforeUpload={checkFileType}
        >
          <Button disabled={isPdfUploaded}>
            <UploadOutlined />
            Click to Upload
          </Button>
        </Upload>
      </div>
      {/* <div className={styles.durationContainer}>
        Duration
        <Input
          placeholder="Duration in secs"
          value={duration}
          type="number"
          onChange={(e) => handleChangeInput('duration', e.target.value, true)}
        />
      </div> */}
      <div className="inm-newContent-browseContainer">
        Pages details
        <div className="inm-newContent-subTitle">
          Each of the accessible pages of the resource
        </div>
        <div className="inm-newContent-tableContainer">
          <table>
            <tbody>
              <tr>{renderTableHeader()}</tr>
              {renderTableData()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PdfComposer;
