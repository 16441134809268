import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Checkbox, Modal, Select } from 'antd';
import { updateItemRequest } from '../../store/mainActions';
import useSitemaps from '../../hooks/useSitemaps';

export function RightClickModal({ popupData, setPopupData }) {
  const dispatch = useDispatch();
  const { menuItems, getTranslation } = useSitemaps();

  useEffect(() => {
    setPopupData({
      isVisible: false,
      isGroup: false,
      item: {},
    });
  }, [menuItems]);

  const handleCancel = () => {
    setPopupData({
      isVisible: false,
      isGroup: false,
      item: {},
    });
  };

  const parents = menuItems?.map((root) => ({
    id: root.id,
    name: getTranslation(root),
  }));

  const childrensByParent = menuItems?.find(
    (parent) => parent.id === popupData?.item?.rootMenuItemId
  );

  const translatedChildrens = childrensByParent?.items
    ?.filter((child) => child.menuItemType === 'group')
    .map((child) => ({
      id: child.id,
      name: getTranslation(child),
    }));

  const handleSend = () => {
    dispatch(updateItemRequest(popupData.item));
  };

  const onChangeParent = (rootId) => {
    const rootElement = menuItems.find((parent) => parent.id === rootId);
    const lastOrder =
      rootElement.items.length > 0
        ? rootElement.items[rootElement.items.length - 1].menuItemOrder
        : 0;

    setPopupData({
      ...popupData,
      item: {
        ...popupData.item,
        rootMenuItemId: rootId,
        parentMenuItemId: rootId,
        menuItemOrder: lastOrder + 1,
      },
    });
  };

  const onChangeChildren = (parentId) => {
    if (parentId === null) {
      setPopupData({
        ...popupData,
        item: {
          ...popupData.item,
          parentMenuItemId: popupData.item.rootMenuItemId,
        },
      });
      return;
    }

    const parentElement = childrensByParent.items.find(
      (child) => child.id === parentId
    );

    const lasChildOrder =
      parentElement.items.length > 0
        ? parentElement.items[parentElement.items.length - 1].menuItemOrder
        : 0;

    setPopupData({
      ...popupData,
      item: {
        ...popupData.item,
        parentMenuItemId: parentId,
        menuItemOrder: lasChildOrder + 1,
      },
    });
  };

  return (
    <Modal
      visible={popupData.isVisible}
      onCancel={handleCancel}
      onOk={handleSend}
    >
      <div className="right-click-modal-container">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          Select Main Root
          <Select
            onChange={onChangeParent}
            value={popupData.item.rootMenuItemId}
          >
            {parents?.map((parent) => (
              <Select.Option value={parent.id}>{parent.name}</Select.Option>
            ))}
          </Select>
        </div>

        {!popupData.isGroup && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            Select Parent Group
            <Select
              onChange={onChangeChildren}
              value={
                popupData.item.parentMenuItemId ===
                popupData.item.rootMenuItemId
                  ? null
                  : popupData.item.parentMenuItemId
              }
            >
              <Select.Option value={null}>-</Select.Option>
              {translatedChildrens?.map((child) => (
                <Select.Option value={child.id}>{child.name}</Select.Option>
              ))}
            </Select>
          </div>
        )}
      </div>
    </Modal>
  );
}
