import * as React from 'react';
const SvgImport = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M5.625 5.625h-2.5a1.25 1.25 0 0 0-1.25 1.25V15a1.25 1.25 0 0 0 1.25 1.25h13.75a1.25 1.25 0 0 0 1.25-1.25V6.875a1.25 1.25 0 0 0-1.25-1.25h-2.5M10 16.25v3.125M6.875 19.375h6.25M1.875 13.333h16.25"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m7.5 8.125 2.5 2.5 2.5-2.5M10 10.625v-10"
    />
  </svg>
);
export default SvgImport;
