import { authAxiosInstance, axiosInstance } from '../../util/axiosInstance';

export const API_HOST = process.env.REACT_APP_API_RESOURCES;
export const PREPR_API_HOST = process.env.REACT_APP_API_PREPR;
export const PREPR_BEARER = process.env.REACT_APP_PREPR_BEARER;

export const createResource = async (data, type) => {
  const url = `${API_HOST}/${type}`;
  const response = await authAxiosInstance({ url, method: 'POST', data });

  return response;
};

export const updateResource = async (data, type, uuid) => {
  const url = `${API_HOST}/${type}/${uuid}`;
  const response = await authAxiosInstance({ url, method: 'PUT', data });

  return response;
};

export const createFile = async (data, type) => {
  const url = `${API_HOST}/file/${type}`;
  const response = await authAxiosInstance({ url, method: 'POST', data });

  return response;
};

export const updateFile = async (uuid, data, type) => {
  const url = `${API_HOST}/file/${type}/${uuid}`;
  const response = await authAxiosInstance({ url, method: 'PUT', data });

  return response;
};

export const getResources = async (queryParams) => {
  const url = `${API_HOST}/resources${queryParams}`;
  const response = await authAxiosInstance({ url, method: 'GET' });

  return response;
};

export const getTranslatedResources = async (queryParams, locale) => {
  const url = `${API_HOST}/resources/locale/${locale}${queryParams}`;
  const response = await authAxiosInstance({ url, method: 'GET' });

  return response;
};

export const getThumbnails = async (thumbnails) =>
  Promise.all(
    thumbnails.map(async ([language, uuid]) => {
      const { data } = await getThumbnail(uuid);
      return { ...data, language };
    })
  );

export const getThumbnail = async (uuid) => {
  const url = `${API_HOST}/file/thumbnail/${uuid}`;
  const response = await authAxiosInstance({ url, method: 'GET' });

  return response;
};

export const getFile = async (type, uuid) => {
  const url = `${API_HOST}/file/${type}/${uuid}`;
  const response = await authAxiosInstance({ url, method: 'GET' });

  return response;
};

export const getTranslatedResource = async (uuid, locale) => {
  const url = `${API_HOST}/resource/${uuid}/locale/${locale}`;
  const response = await authAxiosInstance({ url, method: 'GET' });

  return response;
};

export const getTranslatedResourceByType = async (type, uuid, locale) => {
  const url = `${API_HOST}/${type}/${uuid}/locale/${locale}`;
  const response = await authAxiosInstance({ url, method: 'GET' });

  return response;
};

export const getPrepareGuide = async (uuid) => {
  const url = `${PREPR_API_HOST}/${uuid}?fields=title`;
  const response = await axiosInstance(url, {
    method: 'get',
    headers: {
      Authorization: `Bearer ${PREPR_BEARER}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.data;
};

export const getResourceByType = async (type, uuid) => {
  const url = `${API_HOST}/${type}/${uuid}`;
  const response = await authAxiosInstance({ url, method: 'GET' });

  return response;
};

export const deleteResource = async (uuid) => {
  const url = `${API_HOST}/resource/${uuid}`;
  const response = await authAxiosInstance({ url, method: 'DELETE' });

  return response;
};

export const updateSuggestedLists = async (uuid, data) => {
  const url = `${API_HOST}/resource/${uuid}/suggestedLists`;
  const response = await authAxiosInstance({ url, method: 'PUT', data });

  return response;
};

export const getTranslatedResourceList = async (uuid, locale) => {
  const url = `${API_HOST}/translatedResourceList/${uuid}`;
  const response = await authAxiosInstance({
    url,
    method: 'GET',
    headers: { 'Accept-Language': locale },
  });

  return response;
};
