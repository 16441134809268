import * as React from 'react';
const SvgTransfer = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M8.75 12.5h10.625M16.875 10l2.5 2.5-2.5 2.5M11.25 7.5H.625M3.125 10l-2.5-2.5 2.5-2.5"
      />
    </g>
  </svg>
);
export default SvgTransfer;
