import * as React from 'react';
const SvgIcWorldProblems = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#000"
      strokeWidth={1.25}
      clipPath="url(#ic_world_problems_svg__a)"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.375 19.375a5 5 0 1 0 0-10 5 5 0 0 0 0 10"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.813 13.438A1.563 1.563 0 1 1 14.375 15"
      />
      <path d="M14.375 17.5a.313.313 0 1 1 0-.625M14.375 17.5a.313.313 0 1 0 0-.625" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.738 19.29A9.376 9.376 0 1 1 19.295 8.769"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.74 19.1c-1.267-1.86-2.115-5.24-2.115-9.1S6.473 2.76 7.741.9M.646 9.375H8.75M2.5 4.375h15M1.707 14.375h4.305M12.26.9a14.2 14.2 0 0 1 1.922 5.967"
      />
    </g>
    <defs>
      <clipPath id="ic_world_problems_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcWorldProblems;
