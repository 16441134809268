import React from 'react';
import styles from './SearchBox.module.scss';

const SearchBox = ({ styleName, placeholder, onChange, value }) => (
  <div className={`gx-search-bar ${styleName} ${styles.searchBar}`}>
    <div className="gx-form-group">
      <input
        className="ant-input"
        type="search"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      <span className="gx-search-icon gx-pointer">
        <i className="icon icon-search" />
      </span>
    </div>
  </div>
);
export default SearchBox;

SearchBox.defaultProps = {
  styleName: '',
  value: '',
};
