import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import NewContent from './NewContent';
import NewTag from './NewTag';
import NewTab from './NewTab';
import NewCourse from './NewCourse';
import Resources from './Resources';
import Lists from './Lists';
import Pages from './Pages/Pages';
import Sitemaps from './Sitemaps/Sitemaps';
import NewSitemap from './NewSitemap';
import NewSitemapGroup from './NewSitemapGroup/NewSitemapGroup';
import NewSitemapLink from './NewSitemapLink/NewSitemapLink';
import NewSitemapMenu from './NewSitemapMenu';

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route exact path="/">
        <Redirect to="/resources" />
      </Route>
      <Route path={`${match.url}resources`} component={Resources} />
      <Route path={`${match.url}lists`} component={Lists} />
      <Route path={`${match.url}pages`} component={Pages} />
      <Route path={`${match.url}newTag`} component={NewTag} />
      <Route path={`${match.url}newTab`} component={NewTab} />
      <Route path={`${match.url}newCourse`} component={NewCourse} />
      <Route path={`${match.url}newContent`} component={NewContent} />
      <Route path={`${match.url}sitemaps`} component={Sitemaps} />
      <Route exact path={`${match.url}newSitemap`} component={NewSitemap} />
      <Route
        exact
        path={`${match.url}newSitemap/:id`}
        component={NewSitemapMenu}
      />
      <Route
        exact
        path={`${match.url}newSitemap/:id/newGroup/:menuId?`}
        component={NewSitemapGroup}
      />
      <Route
        exact
        path={`${match.url}newSitemap/:id/newLink/:menuId?`}
        component={NewSitemapLink}
      />
    </Switch>
  </div>
);

export default App;
