import * as React from 'react';
const SvgSettings = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M8.788 2.032a1.63 1.63 0 0 0 2.424 0L12 1.167a1.633 1.633 0 0 1 2.842 1.177l-.06 1.167a1.63 1.63 0 0 0 1.71 1.712l1.166-.06a1.633 1.633 0 0 1 1.175 2.842l-.868.783a1.633 1.633 0 0 0 0 2.425l.868.783a1.633 1.633 0 0 1-1.177 2.842l-1.167-.06a1.63 1.63 0 0 0-1.713 1.714l.059 1.166A1.633 1.633 0 0 1 12 18.833l-.784-.867a1.633 1.633 0 0 0-2.423 0l-.788.867a1.633 1.633 0 0 1-2.838-1.173l.06-1.167a1.63 1.63 0 0 0-1.714-1.713l-1.166.06A1.632 1.632 0 0 1 1.167 12l.867-.783a1.633 1.633 0 0 0 0-2.425l-.867-.787A1.632 1.632 0 0 1 2.34 5.167l1.167.059a1.63 1.63 0 0 0 1.715-1.717l-.056-1.167a1.633 1.633 0 0 1 2.838-1.175Z"
      />
      <path
        strokeWidth={1.249995}
        d="M6.25 10a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 1 0-7.5 0"
      />
    </g>
  </svg>
);
export default SvgSettings;
