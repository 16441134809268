import * as React from 'react';
const SvgLabFlask = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M5.605.625h8.75M13.105 6.875V.625h-6.25v6.25l-5.614 8.638a2.5 2.5 0 0 0 2.092 3.862h13.29a2.5 2.5 0 0 0 2.096-3.862ZM4.418 10.625h11.125M11.855 14.375h2.5M13.105 13.125v2.5"
      />
      <path
        strokeWidth={1.249995}
        d="M5.605 15.938a.313.313 0 0 1 .312.312M5.293 16.25a.313.313 0 0 1 .312-.312M5.605 16.563a.313.313 0 0 1-.312-.313"
      />
      <path
        strokeWidth={1.249995}
        d="M5.917 16.25a.313.313 0 0 1-.313.313M8.105 13.438a.313.313 0 0 1 .313.312M7.793 13.75a.313.313 0 0 1 .312-.312M8.105 14.063a.313.313 0 0 1-.312-.313"
      />
      <path
        strokeWidth={1.249995}
        d="M8.417 13.75a.313.313 0 0 1-.313.313M13.105 3.125h-2.5M13.105 5.625h-2.5"
      />
    </g>
  </svg>
);
export default SvgLabFlask;
