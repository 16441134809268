import React from 'react';
import ReactDOM from 'react-dom';

import { AppContainer } from 'react-hot-loader';
import { authService } from '@innovamat/core-utilites';
import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';

authService.init(
  process.env.REACT_APP_API_USERS,
  process.env.REACT_APP_USERS_CLIENT_ID
);
// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
