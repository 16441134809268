import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import { useSelector } from "react-redux";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const { SubMenu } = Menu;

const SidebarContent = () => {
  const { themeType, pathname } = useSelector(({ settings }) => settings);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <SubMenu title="Contents">
              <Menu.Item key="resources">
                <Link to="/resources">
                  <i className="icon icon-widgets" />
                  <span>Resources</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="lists">
                <Link to="/lists">
                  <i className="icon icon-all-contacts" />
                  <span>Lists</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="pages">
                <Link to="/pages">
                  <i className="icon icon-card" />
                  <span>Pages</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="newTag">
              <Link to="/newTag">
                <i className="icon icon-tag" />
                <span>Tags</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="newTab">
              <Link to="/newTab">
                <i className="icon icon-tab" />
                <span>Tabs</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="newCourse">
              <Link to="/newCourse">
                <i className="icon icon-extensions" />
                <span>Courses</span>
              </Link>
            </Menu.Item>
            <div className="divider" />
            <Menu.Item key="sitemaps">
              <Link to="/sitemaps">
                <i className="icon" />
                <span>Sitemaps</span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;
