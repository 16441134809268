import * as React from 'react';
const SvgClip = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m19 8.358-8.782 9.018A5.33 5.33 0 0 1 6.4 19a5.33 5.33 0 0 1-3.818-1.624A5.62 5.62 0 0 1 1 13.455c0-1.47.569-2.881 1.582-3.92l7.212-7.406c.332-.354.73-.635 1.169-.829a3.52 3.52 0 0 1 2.784-.025c.443.186.844.46 1.182.808.338.347.606.76.787 1.214a3.78 3.78 0 0 1-.024 2.859 3.7 3.7 0 0 1-.807 1.2l-7.212 7.41c-.34.336-.794.522-1.266.518a1.78 1.78 0 0 1-1.257-.54 1.87 1.87 0 0 1-.528-1.292 1.88 1.88 0 0 1 .505-1.3l7.213-7.409"
    />
  </svg>
);
export default SvgClip;
