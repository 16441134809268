import {
  GET_LISTS,
  SET_LISTS,
  GET_LIST,
  SET_CURRENT_LIST,
} from '../../constants/ActionTypes';

export const actionGetLists = () => ({
  type: GET_LISTS,
});

export const actionSetLists = (lists) => ({
  type: SET_LISTS,
  lists,
});

export const actionGetList = (list) => ({
  type: GET_LIST,
  list,
});

export const actionSetCurrentList = (list) => ({
  type: SET_CURRENT_LIST,
  list,
});
