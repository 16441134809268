import * as React from 'react';
const SvgVideoGameController = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M5 .625h1.25C8.32.625 10 2.929 10 5v.625M4.375 9.375v2.5M3.125 10.625h2.5M14.375 10.625a.313.313 0 0 1 .313.313M14.063 10.938a.313.313 0 0 1 .312-.313M14.375 11.25a.313.313 0 0 1-.312-.312M14.688 10.938a.313.313 0 0 1-.313.312M16.25 8.75a.313.313 0 0 1 .313.313M15.938 9.063a.313.313 0 0 1 .312-.313M16.25 9.375a.313.313 0 0 1-.312-.312M16.563 9.063a.313.313 0 0 1-.313.312"
      />
      <path
        strokeWidth={1.249995}
        d="M19.375 12.5V9.375a3.75 3.75 0 0 0-3.75-3.75H4.375a3.75 3.75 0 0 0-3.75 3.75v7.185a2.78 2.78 0 0 0 1.978 2.728 2.67 2.67 0 0 0 3.106-1.473l1.848-3.44h4.886l1.796 3.326a2.78 2.78 0 0 0 2.011 1.632 2.667 2.667 0 0 0 3.127-2.622Z"
      />
    </g>
  </svg>
);
export default SvgVideoGameController;
