import * as ApiTabs from './dataSource';
import * as ApiTranslations from '../translations/dataSource';

import { REQUEST_TABS, SAVE_TAB, SET_TABS } from '../../constants/ActionTypes';

export const actionSaveTab = (tabs) => ({
  type: SAVE_TAB,
  tabs,
});

export const actionSetTabs = (tabs) => ({
  type: SET_TABS,
  tabs,
});

export const actionRequestTabs = (locale) => ({
  type: REQUEST_TABS,
  locale,
});

export async function getTabTranslations(uuid) {
  try {
    const responseId = await ApiTabs.getTabById(uuid);
    const responseTranslations = await ApiTranslations.getSingleTranslation(
      responseId.data.translation_id
    );
    return responseTranslations.data.translations;
  } catch (e) {
    console.error('Translation ERROR', e.response);
    return e.response;
  }
}
