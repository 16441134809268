import { Checkbox } from 'antd';
import React from 'react';

const roleLabels = {
  ROLE_TEACHER: 'Teacher (owner/admin/teacher) & Internal Roles',
  ROLE_LEAD: 'Leads',
  ROLE_FREEUSER: 'Freeuser',
  ROLE_STUDENT: 'Student',
  ROLE_PARENT: 'Parent',
  ROLE_INTERNAL: 'Internal',
};

export default function SelectRoles({
  checkedRoles,
  setCheckedRoles,
  allowedRoles,
}) {
  const allRoles = Object.keys(roleLabels).map((role) => role);

  const handleRoleCheck = (event, role) => {
    const { checked } = event.target;
    setCheckedRoles((prevState) =>
      checked
        ? [...prevState, role]
        : prevState.filter((_role) => _role !== role)
    );
  };

  const hanldleCheckAll = () => {
    if (allRoles.length === checkedRoles.length) {
      setCheckedRoles([]);
    } else {
      setCheckedRoles(allRoles);
    }
  };

  const showAllChecked = allRoles.length === checkedRoles.length;

  return (
    <>
      <div className="inm-roles-title">
        <h4>Roles Enabled*</h4>
        <Checkbox
          style={{ marginBottom: 8 }}
          checked={showAllChecked}
          onChange={hanldleCheckAll}
        >
          Mark all
        </Checkbox>
      </div>
      <div className="inm-roles-checboxes">
        {Object.keys(roleLabels).map((role) => (
          <Checkbox
            key={role}
            onChange={(e) => handleRoleCheck(e, role)}
            checked={!!checkedRoles.includes(role)}
          >
            {roleLabels[role]}
          </Checkbox>
        ))}
      </div>
    </>
  );
}
