import * as React from 'react';
const SvgMove = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#Move_svg__a)"
    >
      <path d="M10 .625v18.75M7.5 3.125l2.5-2.5 2.5 2.5M7.5 16.875l2.5 2.5 2.5-2.5M19.375 10H.625M16.875 7.5l2.5 2.5-2.5 2.5M3.125 7.5.625 10l2.5 2.5" />
    </g>
    <defs>
      <clipPath id="Move_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMove;
