import * as React from 'react';
const SvgPlayerArrowLeft = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.78 2 6.155 9.623a.533.533 0 0 0 0 .754L13.78 18"
    />
  </svg>
);
export default SvgPlayerArrowLeft;
