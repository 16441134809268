import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Divider, Tag } from 'antd';
import isEmpty from 'lodash/isEmpty';

import { useDispatch, useSelector } from 'react-redux';
import {
  actionSaveTag,
  actionRequestTags,
  getTagTranslations,
} from '../../store/mainActions';
import ContainerHeader from '../../components/ContainerHeader';
import { defaultLanguage, languageList } from '../../util/languageList';

const { Option } = Select;

const composeOptions = (list) => {
  const options = [];
  list.forEach((e) =>
    options.push(
      <Option value={e.value} key={e.key}>
        {e.key}
      </Option>
    )
  );
  return options;
};

const NewTag = (props) => {
  const languageOptions = composeOptions(languageList);
  const dispatch = useDispatch();

  const [activeLanguage, setActiveLanguage] = useState(null);
  const [tags, setTags] = useState({});

  useEffect(() => {
    dispatch(actionRequestTags(defaultLanguage));
  }, []);

  const tagList = useSelector(({ tags }) => tags.tags);
  const loading = useSelector(({ common }) => common.loading);
  const success = useSelector(({ common }) => common.success);

  const handleChangeInput = (value) => {
    if (value === '') {
      const { [activeLanguage]: tmp, ...rest } = tags;
      setTags(rest);
    } else {
      setTags({
        ...tags,
        [activeLanguage]: value,
      });
    }
  };

  const cleanInputs = () => {
    setActiveLanguage(null);
    setTags({});
  };

  useEffect(() => {
    if (success && !loading) {
      cleanInputs();
    }
  }, [success]);

  const handleSave = () => dispatch(actionSaveTag(tags));

  const handleEdit = async (tagId) => {
    cleanInputs();
    const tagTranslations = await getTagTranslations(tagId);
    const preparetags = {};
    tagTranslations.forEach((tag) => {
      preparetags[tag.locale] = tag.translation;
    });
    setTags(preparetags);
    setActiveLanguage(Object.keys(preparetags)[0]);
  };

  return (
    <>
      <ContainerHeader title="Tags" />
      <div className="inm-newTag-addTagContainer">
        <div className="inm-newTag-selectContainer">
          <Select
            className="inm-newTag-dropDown"
            onChange={(value) => setActiveLanguage(value)}
            value={activeLanguage}
            placeholder="Language"
          >
            {languageOptions}
          </Select>
        </div>
        <div className="inm-newTag-inputContainer">
          <Input
            placeholder="Tag name"
            value={tags[activeLanguage]}
            onChange={(e) => handleChangeInput(e.target.value)}
            disabled={!activeLanguage}
          />
        </div>
        <div className="inm-newTag-languagesContainer">
          {Object.keys(tags).map((lang) => (
            <div key={lang} className="inm-newTag-box inm-newTag-languageBox">
              {lang}
            </div>
          ))}
        </div>
      </div>
      <div className="inm-newTag-buttonContainer">
        <Button disabled={isEmpty(tags)} onClick={handleSave} loading={loading}>
          Save
        </Button>
      </div>
      <Divider />
      Tags available:
      <p style={{ padding: '8px 0' }}>
        {tagList.map((tag) => (
          <Tag
            style={{ cursor: 'pointer' }}
            key={tag.uuid}
            onClick={() => handleEdit(tag.uuid)}
          >
            {tag.translation}
          </Tag>
        ))}
      </p>
    </>
  );
};

export default NewTag;
