import * as React from 'react';
const SvgKeyboard = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="m5 16.873-1.875-1.876L5 13.124M3.125 14.998h3.75M15 16.873l1.875-1.876L15 13.124M16.875 14.998h-3.75M11.875 4.998 10 3.122 8.125 4.999M10 3.123v3.75"
      />
      <path
        strokeWidth={1.249995}
        d="M1.25 11.248h7.5v7.5h-7.5ZM11.25 11.248h7.5v7.5h-7.5ZM6.25 1.248h7.5v7.5h-7.5Z"
      />
    </g>
  </svg>
);
export default SvgKeyboard;
