import { all } from 'redux-saga/effects';
import authSagas from './auth/sagas';
import resourcesSagas from './resources/sagas';
import tagsSagas from './tags/sagas';
import tabsSagas from './tabs/sagas';
import coursesSagas from './courses/sagas';
import listsSagas from './lists/sagas';
import pagesSagas from './pages/sagas';
import sitemapsSagas from './sitemaps/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    resourcesSagas(),
    tagsSagas(),
    tabsSagas(),
    coursesSagas(),
    listsSagas(),
    pagesSagas(),
    sitemapsSagas(),
  ]);
}
