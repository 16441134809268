import * as React from 'react';
const SvgSamVideo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#sam_video_svg__a)"
    >
      <path d="M1.563 14.688a2.187 2.187 0 1 0 4.374 0 2.187 2.187 0 0 0-4.374 0" />
      <path d="M6.875 19.375a3.542 3.542 0 0 0-6.25 0M7.813 14.688a2.187 2.187 0 1 0 4.374 0 2.187 2.187 0 0 0-4.374 0" />
      <path d="M13.125 19.375a3.542 3.542 0 0 0-6.25 0M14.063 14.688a2.188 2.188 0 1 0 4.375 0 2.188 2.188 0 0 0-4.375 0" />
      <path d="M19.375 19.375a3.542 3.542 0 0 0-6.25 0M5.625 10h-3.75a1.25 1.25 0 0 1-1.25-1.25V1.875a1.25 1.25 0 0 1 1.25-1.25h3.75M12.5.625h5.625a1.25 1.25 0 0 1 1.25 1.25V8.75a1.25 1.25 0 0 1-1.25 1.25H12.5" />
      <path d="M8.125 2.5v6.25l5-3.125z" />
    </g>
    <defs>
      <clipPath id="sam_video_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSamVideo;
