import * as React from 'react';
const SvgDownloadPdf = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M4 16v-6M4 10.002h.6a1.8 1.8 0 1 1 0 3.6H4M8.8 16.002a2.4 2.4 0 0 0 2.4-2.4v-1.2a2.4 2.4 0 0 0-2.4-2.4zM13.6 16.002v-4.8a1.2 1.2 0 0 1 1.2-1.2H16M13.6 13.6h1.8"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.648 4.849a1.2 1.2 0 0 1 .352.848v11.505A1.8 1.8 0 0 1 17.2 19H2.8A1.8 1.8 0 0 1 1 17.202v-14.4A1.8 1.8 0 0 1 2.8 1h11.502a1.2 1.2 0 0 1 .85.351z"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M14.2 1v3.6a1.2 1.2 0 0 0 1.2 1.2H19"
    />
  </svg>
);
export default SvgDownloadPdf;
