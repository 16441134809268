import * as React from 'react';
const SvgMonitorDownload = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M9.375 5.625a5 5 0 1 0 10 0 5 5 0 1 0-10 0M14.375 3.125v5M14.375 8.125 12.5 6.25M14.375 8.125 16.25 6.25M13.125 19.375h-6.25l.625-2.5h5zM5 19.375h10M.625 14.375h18.75"
      />
      <path
        strokeWidth={1.249995}
        d="M19.375 11.875v2.5a2.5 2.5 0 0 1-2.5 2.5H3.125a2.5 2.5 0 0 1-2.5-2.5v-8.75a2.5 2.5 0 0 1 2.5-2.5h3.75"
      />
    </g>
  </svg>
);
export default SvgMonitorDownload;
