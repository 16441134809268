import * as React from 'react';
const SvgCloseBig = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#333"
      fillRule="evenodd"
      d="M1.161 1.161a1.25 1.25 0 0 0 0 1.768L8.232 10l-7.07 7.071a1.25 1.25 0 1 0 1.767 1.768L10 11.768l7.071 7.07a1.25 1.25 0 1 0 1.768-1.767L11.768 10l7.07-7.071a1.25 1.25 0 0 0-1.767-1.768L10 8.232l-7.071-7.07a1.25 1.25 0 0 0-1.768 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloseBig;
