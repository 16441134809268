// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

// Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const SET_USER_INFO = 'SET_USER_INFO';

// Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

// Contact
export const GET_All_CONTACT_SUCCESS = 'GET_All_CONTACT_SUCCESS';
export const ON_ADD_CONTACT_SUCCESS = 'ON_ADD_CONTACT_SUCCESS';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';

// Validation
export const SET_VALIDATION = 'SET_VALIDATION';

// Resources
export const SAVE_RESOURCE = 'SAVE_RESOURCE';
export const GET_RESOURCE = 'GET_CURRENT_RESOURCE';
export const SET_CURRENT_RESOURCE = 'SET_CURRENT_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const DELETE_RESOURCES = 'DELETE_RESOURCES';
export const GET_RESOURCES = 'GET_RESOURCES';
export const SET_RESOURCES = 'SET_RESOURCES';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';

// Pagination and filters
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_FILTERS = 'SET_FILTERS';
export const CLEAN_PAGINATION_FILTER = 'CLEAN_PAGINATION_FILTER';

// Tags
export const SAVE_TAG = 'SAVE_TAG';
export const SET_TAGS = 'SET_TAGS';
export const REQUEST_TAGS = 'REQUEST_TAGS';

// Tabs
export const SAVE_TAB = 'SAVE_TAB';
export const SET_TABS = 'SET_TABS';
export const REQUEST_TABS = 'REQUEST_TABS';

// Courses
export const SAVE_COURSE = 'SAVE_COURSE';
export const SET_COURSES = 'SET_COURSES';
export const REQUEST_COURSES = 'REQUEST_COURSES';

// Lists
export const GET_LISTS = 'GET_LISTS';
export const SET_CURRENT_LIST = 'SET_CURRENT_LIST';
export const SET_LISTS = 'SET_LISTS';
export const GET_LIST = 'GET_LIST';

// Pages
export const SET_PAGES = 'SET_PAGES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const GET_TRANSLATED_PAGES = 'GET_TRANSLATED_PAGES';
export const GET_PAGE = 'GET_PAGE';

// Sitemaps
export const GET_REGIONS_REQUEST = 'GET_REGIONS_REQUEST';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR';

export const SAVE_AND_CONTINUE = 'SAVE_AND_CONTINUE';
export const SAVE_AND_CONTINUE_SUCCESS = 'SAVE_AND_CONTINUE_SUCCESS';
export const SAVE_AND_CONTINUE_ERROR = 'SAVE_AND_CONTINUE_ERROR';

export const EDIT_SITEMAP = 'EDIT_SITEMAP';

export const GET_SITEMAPS_REQUEST = 'GET_SITEMAPS_REQUEST';
export const GET_SITEMAPS_SUCCESS = 'GET_SITEMAPS_SUCCESS';
export const GET_SITEMAPS_ERROR = 'GET_SITEMAPS_ERROR';

export const TOGGLE_SITEMAP_REQUEST = 'TOGGLE_SITEMAP_REQUEST';
export const TOGGLE_SITEMAP_SUCCESS = 'TOGGLE_SITEMAP_SUCCESS';
export const TOGGLE_SITEMAP_ERROR = 'TOGGLE_SITEMAP_ERROR';

export const DELETE_SITEMAP_REQUEST = 'DELETE_SITEMAP_REQUEST';
export const DELETE_SITEMAP_SUCCESS = 'DELETE_SITEMAP_SUCCESS';
export const DELETE_SITEMAP_ERROR = 'DELETE_SITEMAP_ERROR';

export const GET_SITEMAP_REQUEST = 'GET_SITEMAP_REQUEST';
export const GET_SITEMAP_SUCCESS = 'GET_SITEMAP_SUCCESS';
export const GET_SITEMAP_ERROR = 'GET_SITEMAP_ERROR';

export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_ERROR = 'GET_LANGUAGES_ERROR';

export const SAVE_GROUP_ITEM = 'SAVE_GROUP_ITEM';
export const SAVE_GROUP_ITEM_SUCCESS = 'SAVE_GROUP_ITEM_SUCCESS';
export const SAVE_GROUP_ITEM_ERROR = 'SAVE_GROUP_ITEM_ERROR';

export const SAVE_LINK_ITEM_REQUEST = 'SAVE_LINK_ITEM_REQUEST';
export const SAVE_LINK_ITEM_SUCCESS = 'SAVE_LINK_ITEM_SUCCESS';
export const SAVE_LINK_ITEM_ERROR = 'SAVE_LINK_ITEM_ERROR';

export const ON_REORDER_LIST = 'ON_REORDER_LIST';
export const ON_REORDER_LIST_SUCCESS = 'ON_REORDER_LIST_SUCCESS';
export const ON_REORDER_LIST_ERROR = 'ON_REORDER_LIST_ERROR';

export const GET_ALL_MENU_ITEMS = 'GET_ALL_MENU_ITEMS';
export const GET_ALL_MENU_ITEMS_SUCCESS = 'GET_ALL_MENU_ITEMS_SUCCESS';
export const GET_ALL_MENU_ITEMS_ERROR = 'GET_ALL_MENU_ITEMS_ERROR';

export const GET_MENU_ITEM = 'GET_MENU_ITEM';
export const GET_MENU_ITEM_SUCCESS = 'GET_MENU_ITEM_SUCCESS';
export const GET_MENU_ITEM_ERROR = 'GET_MENU_ITEM_ERROR';

export const GET_ROOT_ITEM = 'GET_ROOT_ITEM';
export const GET_ROOT_ITEM_SUCCESS = 'GET_ROOT_ITEM_SUCCESS';
export const GET_ROOT_ITEM_ERROR = 'GET_ROOT_ITEM_ERROR';

export const DELETE_MENU_ITEM = 'DELETE_MENU_ITEM';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';
export const DELETE_MENU_ITEM_ERROR = 'DELETE_MENU_ITEM_ERROR';

export const CLEAR_SITEMAP = 'CLEAR_SITEMAP';

export const GET_ALL_COURSES = 'GET_ALL_COURSES';
export const GET_ALL_COURSES_SUCCESS = 'GET_ALL_COURSES_SUCCESS';
export const GET_ALL_COURSES_ERROR = 'GET_ALL_COURSES_ERROR';

export const CLONE_SITEMAP = 'CLONE_SITEMAP';
export const CLONE_SITEMAP_SUCCESS = 'CLONE_SITEMAP_SUCCESS';
export const CLONE_SITEMAP_ERROR = 'CLONE_SITEMAP_ERROR';

export const SET_REGION_FILTER = 'SET_REGION_FILTER';

export const SET_LANG_FILTER = 'SET_LANG_FILTER';
export const SET_ROLE_FILTER = 'SET_ROLE_FILTER';

export const UPDATE_ITEM_REQUEST = 'UPDATE_ITEM_REQUEST';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_ERROR = 'UPDATE_ITEM_ERROR';
