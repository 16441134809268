import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguages } from '../store/mainActions';

function useLanguages() {
  const dispatch = useDispatch();
  const languages = useSelector(({ sitemaps }) => sitemaps.languages);
  const sitemap = useSelector(({ sitemaps }) => sitemaps.sitemap);

  useEffect(() => {
    if (languages === undefined) {
      dispatch(getLanguages());
    }
  }, []);

  const langsBySitemapRegion = useMemo(() => {
    if (!sitemap || !languages) {
      return undefined;
    }

    const flatLanguages = [sitemap.region, ...sitemap.regions].flatMap(
      (region) => languages[region]
    );

    return [...new Set(flatLanguages)];
  }, [sitemap, languages]);

  const getLanguagesWithTranslations = useCallback(
    (translations) => {
      const allTranslations = langsBySitemapRegion?.map((language) => {
        const translation = translations?.find(
          (t) => t.languageId === language
        );

        if (translation) {
          return translation;
        }

        return {
          languageId: language,
          status: 'ACTIVE',
          value: '',
          label: 'name',
        };
      });

      return allTranslations;
    },
    [langsBySitemapRegion]
  );

  return {
    languages,
    getLanguagesWithTranslations,
    langsBySitemapRegion,
  };
}

export default useLanguages;
