import React, { useState, useEffect } from 'react';
import { Button, Input, message, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_HOST } from '../../../../store/resources/dataSource';

const GenericComposer = (props) => {
  const { handleChangeInput, fileName } = props;

  const [isGenericUploaded, setIsGenericUploaded] = useState(!!fileName);
  const [fileList, updateFileList] = useState([]);

  useEffect(() => {
    if (fileName) {
      const currentFile = [
        {
          uid: '1',
          name: fileName,
          status: 'done',
        },
      ];
      updateFileList(currentFile);
      setIsGenericUploaded(true);
    } else {
      updateFileList([]);
      setIsGenericUploaded(false);
    }
  }, [fileName]);

  const successFileUpload = (file) => {
    handleChangeInput('fileName', file.name, true);
    handleChangeInput('fileId', file.response.uuid, true);
  };

  const clearFileData = () => {
    handleChangeInput('fileName', '', true);
    handleChangeInput('fileId', '', true);
  };

  const handleOnChange = (info) => {
    // file.status is empty when beforeUpload return false
    updateFileList(info.fileList.filter((file) => !!file.status));
    setIsGenericUploaded(!!info.file.status);

    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'removed') {
      setIsGenericUploaded(false);
      clearFileData();
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      successFileUpload(info.file);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <>
      <div className="inm-newContent-section">
        <div className="inm-newContent-subSection inm-newContent-columns">
          Upload the generic file
          <div className="inm-newContent-browsePdf">
            <Upload
              fileList={fileList}
              name="file"
              action={`${API_HOST}/file/generic`}
              onChange={handleOnChange}
            >
              <Button disabled={isGenericUploaded}>
                <UploadOutlined />
                Click to Upload
              </Button>
            </Upload>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenericComposer;
