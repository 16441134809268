import jwt_decode from 'jwt-decode';

export const encode = (data) => btoa(JSON.stringify(data));

export const decode = (data) => JSON.parse(atob(data));

export const setUser = (user) => {
  const encodedUser = encode(user);
  localStorage.setItem('backoffice_u', encodedUser);
};

export const getUser = () => {
  const encodedUser = localStorage.getItem('backoffice_u');
  return encodedUser ? decode(encodedUser) : undefined;
};

export const clearUser = () => {
  localStorage.removeItem('backoffice_u');
};

export const defineUserDataFromTokens = (responseTokens) => {
  const decodedIdToken = jwt_decode(responseTokens.id_token);
  const decodedAccessToken = jwt_decode(responseTokens.access_token);
  const userData = {
    uid: decodedAccessToken.sub,
    name: decodedIdToken.name,
    firstName: decodedIdToken.given_name,
    lastName: decodedIdToken.family_name,
    email: decodedIdToken.email,
    token: responseTokens.access_token,
    language: decodedIdToken.locale,
    school: decodedAccessToken.school,
    roles: decodedAccessToken.roles,
    school_name: 'Not Defined',
    crmId: decodedIdToken.crm_id,
    permissions: {},
    refreshToken: responseTokens.refresh_token,
    loggedIn: true,
    region: decodedAccessToken.region,
  };
  return userData;
};
