import * as React from 'react';
const SvgUserPairsRegister = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#user-pairs-register_svg__a)"
    >
      <path d="M8.752 7.095a2.083 2.083 0 1 0 4.167 0 2.083 2.083 0 0 0-4.167 0M7.427 14.438a3.409 3.409 0 0 1 6.817 0M14.245 5.348a2.084 2.084 0 1 1-.003 3.492M14.623 11.114a3.412 3.412 0 0 1 4.166 3.324" />
      <path
        strokeWidth={0.7}
        d="M4 4.564A1.37 1.37 0 0 0 2.636 5.95l-.001 7.715L3.999 16l1.366-2.337.002-7.714A1.37 1.37 0 0 0 4 4.564M2.635 6.17h2.731M2.634 7.284h2.732"
      />
      <path
        strokeWidth={0.7}
        d="M2.634 13.664 4 13.114l1.365.547M4 7.283v5.831"
      />
    </g>
    <defs>
      <clipPath id="user-pairs-register_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUserPairsRegister;
