import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { SAVE_COURSE, REQUEST_COURSES } from 'constants/ActionTypes';
import {
  actionSetCourses,
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../mainActions';
import * as translationApi from '../translations/dataSource';
import * as courseApi from './dataSource';
import { defaultLanguage } from '../../util/languageList';

function* runRequestCourses({ locale }) {
  yield put(fetchStart());
  try {
    const res = yield courseApi.getCourses(locale);
    const courses = res.data.translated_courses;
    yield put(actionSetCourses(courses));
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

function* runSaveCourse({ courses }) {
  yield put(fetchStart());
  try {
    const translations = [];
    Object.keys(courses).forEach((locale) => {
      translations.push({ locale, translation: courses[locale] });
    });
    const translationResponse = yield translationApi.requestSingleTranslation({
      translations,
    });
    const translationId = translationResponse.data.uuid;
    yield courseApi.createCourse({ translation_id: translationId });
    yield runRequestCourses({ locale: defaultLanguage });
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

const watcher = () =>
  function* watch() {
    yield takeLatest(SAVE_COURSE, runSaveCourse);
    yield takeLatest(REQUEST_COURSES, runRequestCourses);
  };

export default function* coursesSagas() {
  yield all([fork(watcher())]);
}
