import * as React from 'react';
const SvgManagment = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M4.322 12.277a1.548 1.548 0 1 0 0-3.096 1.548 1.548 0 0 0 0 3.096M5.3 13.46A2.325 2.325 0 0 0 2 15.568M16.553 12.277a1.548 1.548 0 1 0 0-3.096 1.548 1.548 0 0 0 0 3.096M13.1 5.006v2.338l-2.672.816-2.673-.816V5.006"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M15.772 4.004 10.427 2 5.084 4.004l5.344 2.004zM5.084 4.004v2.671M7.906 7.39v1.1a2.483 2.483 0 1 0 4.965 0V7.413M6.048 17.297a4.38 4.38 0 0 1 8.759 0"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m8.188 13.53 2.24 2.493 2.24-2.492M15.575 13.46a2.325 2.325 0 0 1 3.3 2.107"
    />
  </svg>
);
export default SvgManagment;
