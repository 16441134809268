export const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#e6fffb' : 'transparent',
  ...draggableStyle,
});

export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const PARENT_TYPES = {
  group: '0',
  link: '1',
};

export const getSortedMenuItemsWithChanges = (menuItems) => {
  let order = 0;
  const changes = [];
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i];
    if (menuItem.menuItemOrder !== order) {
      changes.push({
        id: menuItem.id,
        menuItemOrder: order,
      });
    }
    menuItem.menuItemOrder = order;
    order++;
    if (menuItem.items?.length) {
      for (let y = 0; y < menuItem.items.length; y++) {
        const submenuItem = menuItem.items[y];
        if (submenuItem.menuItemOrder !== order) {
          changes.push({
            id: submenuItem.id,
            menuItemOrder: order,
          });
        }
        submenuItem.menuItemOrder = order;
        order++;
      }
    }
  }
  return { menuItems, changes };
};

export const flatMenuItems = (menuItems, rootId) =>
  menuItems
    .find((item) => item.id === rootId)
    .flatMap((item) => {
      const { items, ...res } = item;
      return [res, ...item.items];
    });
