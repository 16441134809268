import * as React from 'react';
const SvgCheck = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <path
      fill="none"
      stroke="#000"
      strokeLinejoin="round"
      strokeWidth={1.249995}
      d="m6.873 17.402-5.525-5.527a2.467 2.467 0 0 1 3.485-3.488l3.515 3.515 6.584-8.778a2.467 2.467 0 1 1 3.949 2.96l-8.29 11.054a2.468 2.468 0 0 1-3.718.264Z"
    />
  </svg>
);
export default SvgCheck;
