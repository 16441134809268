import * as React from 'react';
const SvgError = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <circle
      cx={10}
      cy={10}
      r={10}
      fill="#E8304F"
      transform="rotate(-180 10 10)"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14.167 14.167c.46-.46.46-1.207 0-1.667l-2.5-2.5 2.5-2.5A1.179 1.179 0 0 0 12.5 5.833l-2.5 2.5-2.5-2.5A1.179 1.179 0 0 0 5.833 7.5l2.5 2.5-2.5 2.5A1.179 1.179 0 0 0 7.5 14.167l2.5-2.5 2.5 2.5c.46.46 1.207.46 1.667 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgError;
