import * as React from 'react';
const SvgSearch = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M1.227 11.13a7.553 7.553 0 1 0 13.901-5.907A7.553 7.553 0 1 0 1.227 11.13M13.518 13.517l5.857 5.858"
      />
    </g>
  </svg>
);
export default SvgSearch;
