import React from 'react';

const ContainerHeader = ({ title, regionTags }) => {
  return (
    <div className="gx-page-heading">
      <h2 className="gx-page-title">{title}</h2>
      {regionTags && (
        <div style={{ marginTop: '8px' }}>
          {regionTags.map((tag) => (
            <span
              style={{
                backgroundColor: '#f5f5f5',
                padding: '2px 8px',
                marginRight: '4px',
                borderRadius: '4px',
                border: '1px solid #e8e8e8',
                fontSize: '14px',
              }}
            >
              {tag}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default ContainerHeader;
