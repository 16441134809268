import * as React from 'react';
const SvgCopy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#copy_svg__a)">
      <path
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M1.875 15.625a1.25 1.25 0 0 1-1.25-1.25v-12.5a1.25 1.25 0 0 1 1.25-1.25h12.5a1.25 1.25 0 0 1 1.25 1.25m-10 2.5h12.5s1.25 0 1.25 1.25v12.5s0 1.25-1.25 1.25h-12.5s-1.25 0-1.25-1.25v-12.5s0-1.25 1.25-1.25"
      />
    </g>
    <defs>
      <clipPath id="copy_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCopy;
