import * as React from 'react';
const SvgPasswordLock = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M10.625 10.625h-8.75a1.25 1.25 0 0 1-1.25-1.25v-7.5a1.25 1.25 0 0 1 1.25-1.25h15a1.25 1.25 0 0 1 1.25 1.25v5"
      />
      <path
        strokeWidth={1.249995}
        d="M4.375 5.313a.313.313 0 1 1-.312.312.31.31 0 0 1 .312-.312M9.375 5.313a.313.313 0 1 1-.312.312.31.31 0 0 1 .312-.312M14.375 5.313a.313.313 0 1 1-.312.312.31.31 0 0 1 .312-.312M11.875 13.125h7.5v6.25h-7.5ZM15.625 9.375a2.5 2.5 0 0 0-2.5 2.5v1.25h5v-1.25a2.5 2.5 0 0 0-2.5-2.5"
      />
      <path
        strokeWidth={1.249995}
        d="M15.625 15.962a.313.313 0 1 1-.312.312.313.313 0 0 1 .312-.312"
      />
    </g>
  </svg>
);
export default SvgPasswordLock;
