import { SET_PAGES, SET_CURRENT_PAGE } from '../../constants/ActionTypes';

const INIT_STATE = {
  pages: [],
  currentPage: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.page,
      };
    }
    case SET_PAGES: {
      return {
        ...state,
        pages: action.pages.pages,
        count: action.pages.count,
      };
    }
    default:
      return state;
  }
};
