import React, { useState } from 'react';
import { Button, Checkbox, Select } from 'antd';
import { Link } from 'react-router-dom';
import VideoComposer from './VideoComposer';
import PdfComposer from './PdfComposer';
import WebGLComposer from './WebGLComposer';
import SessionComposer from './SessionComposer';
import { capitalizeFirst, objectIsEmpty } from '../../../util/helpers';
import GeniallyComposer from './GeniallyComposer';
import ManipulativeComposer from './ManipulativeComposer';
import PageComposer from './PageComposer';
import ListComposer from './ListComposer';
import GeogebraComposer from './GeogebraComposer';
import GenericComposer from './GenericComposer';

const SecondStep = (props) => {
  const {
    type,
    publishResource,
    handlePrevStep,
    title,
    handleChangeInput,
    language,
    languageOptions,
    courses,
    coursesList,
    videoUrl,
    addFragment,
    fragments,
    removeFragment,
    editFragment,
    validateData,
    activeLanguages,
    duration,
    fileName,
    appletScene,
    appletPack,
    contentTypes,
    status,
    guide,
    printables,
    suggestedLists,
    sessionBlocks,
    listBlock,
    pageBlock,
    settings,
    isPreview,
    tagsList,
    translations,
    openCancelModal,
    uuid,
    pageId,
    script,
    sessionWithoutPlayer,
  } = props;
  const [guideStep, setGuideStep] = useState(false);
  const [printableStep, setPrintableStep] = useState(false);
  const [suggestedListStep, setSuggestedListStep] = useState(false);

  const guideOrPrintableStep = guideStep || printableStep || suggestedListStep;

  const isSession = type === 'session';

  const getElementByType = () => {
    switch (type) {
      case 'vimeo':
        return (
          <VideoComposer
            handleChangeInput={handleChangeInput}
            videoUrl={videoUrl}
            duration={duration}
            error={!validateData()}
            title={title}
            isEdit={uuid}
          />
        );
      case 'pdf':
        return (
          <PdfComposer
            editFragment={editFragment}
            fragments={fragments}
            addFragment={addFragment}
            fileName={fileName}
            handleChangeInput={handleChangeInput}
            duration={duration}
            translations={translations}
          />
        );
      case 'genially':
        return (
          <GeniallyComposer
            editFragment={editFragment}
            fragments={fragments}
            addFragment={addFragment}
            fileName={fileName}
            handleChangeInput={handleChangeInput}
            duration={duration}
          />
        );
      case 'applet':
        return (
          <WebGLComposer
            handleChangeInput={handleChangeInput}
            appletScene={appletScene}
            appletPack={appletPack}
            duration={duration}
          />
        );
      case 'manipulativeEnvironment':
        return (
          <ManipulativeComposer
            handleChangeInput={handleChangeInput}
            settings={settings}
          />
        );
      case 'geogebra':
        return (
          <GeogebraComposer
            script={script}
            handleChangeInput={handleChangeInput}
            fileName={fileName}
            settings={settings}
          />
        );
      case 'generic':
        return (
          <GenericComposer
            fileName={fileName}
            handleChangeInput={handleChangeInput}
          />
        );
      case 'session':
        return (
          <SessionComposer
            type={type}
            contentTypes={contentTypes}
            handleChangeInput={handleChangeInput}
            sessionBlocks={sessionBlocks}
            activeLanguages={activeLanguages}
            tagsList={tagsList}
            sessionWithoutPlayer={sessionWithoutPlayer}
          />
        );
      case 'page':
        return (
          <PageComposer
            type={type}
            contentTypes={contentTypes}
            handleChangeInput={handleChangeInput}
            pageBlock={pageBlock}
            activeLanguages={activeLanguages}
            tagsList={tagsList}
          />
        );
      case 'resourceList':
        return (
          <ListComposer
            type={type}
            contentTypes={contentTypes}
            handleChangeInput={handleChangeInput}
            listBlock={listBlock}
            isPreview={isPreview}
            activeLanguages={activeLanguages}
            tagsList={tagsList}
            pageId={pageId}
          />
        );
      default:
        return <div>Error</div>;
    }
  };

  const getGuideOrPrintableStep = () => (
    <SessionComposer
      type={type}
      contentTypes={contentTypes}
      guideStep={guideStep}
      printableStep={printableStep}
      handleChangeInput={handleChangeInput}
      guide={guideStep && guide}
      printables={printableStep && printables}
    />
  );

  const getSuggestedListsStep = () => (
    <SessionComposer
      type={type}
      contentTypes={contentTypes}
      handleChangeInput={handleChangeInput}
      suggestedListStep={suggestedListStep}
      suggestedLists={suggestedListStep && suggestedLists}
    />
  );

  const goToGuideStep = () => {
    setGuideStep(true);
    setPrintableStep(false);
    setSuggestedListStep(false);
  };

  const goToPrintableStep = () => {
    setGuideStep(false);
    setPrintableStep(true);
    setSuggestedListStep(false);
  };

  const goToSuggestedListsStep = () => {
    setGuideStep(false);
    setPrintableStep(false);
    setSuggestedListStep(true);
  };

  const goToSecondStep = () => {
    setGuideStep(false);
    setPrintableStep(false);
  };

  const isSessionEmpty = () => {
    if (!isSession) return false;

    const itemsAreDefined = () => !!guide && !!printables && !!suggestedLists;
    const itemsHaveLength = () =>
      !(
        objectIsEmpty(guide) ||
        objectIsEmpty(printables) ||
        objectIsEmpty(suggestedLists)
      );

    if (sessionWithoutPlayer && itemsAreDefined() && itemsHaveLength()) {
      return !(
        !!guide[1].length ||
        !!printables[1].length ||
        suggestedLists[1].length
      );
    }
    return false;
  };

  const publishButton = (
    <Button
      className="inm-newContent-saveButton"
      onClick={publishResource}
      disabled={!validateData() || isSessionEmpty()}
    >
      Save and Publish
    </Button>
  );

  const goToPrintableButton = (
    <Button
      className="inm-newContent-saveButton"
      onClick={goToPrintableStep}
      disabled={!validateData()}
    >
      Save and Continue
    </Button>
  );

  const goToGuideButton = (
    <Button
      className="inm-newContent-saveButton"
      onClick={goToGuideStep}
      disabled={!validateData()}
    >
      Save and Continue
    </Button>
  );

  const goToSuggestedListButton = (
    <Button
      className="inm-newContent-saveButton"
      onClick={goToSuggestedListsStep}
      disabled={!validateData()}
    >
      Save and Continue
    </Button>
  );

  const getNextStepButton = () => {
    if (!isSession) return publishButton;

    if (!guideStep && !printableStep && !suggestedListStep) {
      return goToGuideButton;
    }
    if (guideStep) return goToPrintableButton;
    if (printableStep) return goToSuggestedListButton;
    if (suggestedListStep) return publishButton;

    return publishButton;
  };

  const checkPrevStep = () => {
    if (!isSession) handlePrevStep();
    else {
      if (guideStep) goToSecondStep();
      if (printableStep) goToGuideStep();
      if (suggestedListStep) return goToPrintableStep();
      if (!guideStep && !printableStep && !suggestedListStep) handlePrevStep();
    }
  };

  const showLanguageSelector =
    type !== 'session' &&
    type !== 'resourceList' &&
    type !== 'page' &&
    type !== 'manipulativeEnvironment' &&
    type !== 'geogebra';
  const isPage = type === 'page';

  const getTitleStep = () => {
    if (guideStep) return 'Add guide to session';
    if (printableStep) return 'Add printables to session';
    if (suggestedListStep) return 'Add suggested lists to session';
    return `Compose the ${capitalizeFirst(type)}`;
  };

  return (
    <>
      <div className="inm-newContent-section inm-newContent-borderBottom inm-newContent-columns">
        <div className="inm-newContent-subSection inm-newContent-secondStepHeader inm-newContent-firstRow">
          <div className="inm-newContent-header">{getTitleStep()}</div>
          {showLanguageSelector && (
            <div className="inm-newContent-languageHeader">
              Language
              <Select
                className="inm-newContent-dropDown"
                onChange={(value) => handleChangeInput('language', value)}
                value={language}
              >
                {languageOptions}
              </Select>
            </div>
          )}
        </div>
        <div className="inm-newContent-subSection inm-newContent-secondStepHeader">
          <div
            className="inm-newContent-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="inm-newContent-boxesContainerRight">
            {activeLanguages.map((lang) => (
              <div
                key={lang}
                className="inm-newContent-box inm-newContent-languageBox"
              >
                {lang}
              </div>
            ))}
          </div>
        </div>
        <div className="inm-newContent-subSection inm-newContent-secondStepHeader">
          <div className="inm-newContent-boxesContainerLeft">
            {courses.map((course) => (
              <div
                key={course}
                className="inm-newContent-box inm-newContent-tagBox"
              >
                {isPage && coursesList.find((tag) => tag.uuid === course)?.name}
                {!isPage &&
                  tagsList.find((tag) => tag.uuid === course)?.translation}
              </div>
            ))}
            {isSession && !guideOrPrintableStep && (
              <span className="inm-newContent-checkboxContainer">
                <Checkbox
                  className="inm-newContent-checkbox"
                  checked={sessionWithoutPlayer}
                  onChange={(e) =>
                    handleChangeInput('sessionWithoutPlayer', e.target.checked)
                  }
                />
                Session without player
              </span>
            )}
          </div>
          <div className="inm-newContent-boxesContainerRight">
            <div
              className={`inm-newContent-box inm-newContent-draftBox inm-newContent-${[
                status,
              ]}`}
            >
              {status}
            </div>
          </div>
        </div>
      </div>
      <div className="inm-newContent-composer">
        {!guideStep &&
          !printableStep &&
          !suggestedListStep &&
          getElementByType(type)}
        {(guideStep || printableStep) && getGuideOrPrintableStep()}
        {suggestedListStep && getSuggestedListsStep()}
      </div>
      <div className="inm-newContent-buttonsContainer inm-newContent-bothSides">
        <div>
          <Button
            className="inm-newContent-cancelButton"
            onClick={checkPrevStep}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            className="inm-newContent-cancelButton"
            onClick={openCancelModal}
          >
            Cancel
          </Button>
          {getNextStepButton()}
        </div>
      </div>
    </>
  );
};

export default SecondStep;
