import * as React from 'react';
const SvgShipmentUpload = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M10 .625.625 4.375 10 8.125l9.375-3.75z"
      />
      <path
        strokeWidth={1.249995}
        d="M.625 4.375v11.25L10 19.375V8.125zM19.375 4.375v11.25L10 19.375V8.125zM15.156 6.063 5.78 2.313M17.188 13.75l-1.563.625"
      />
    </g>
  </svg>
);
export default SvgShipmentUpload;
