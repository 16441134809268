import * as React from 'react';
const SvgPlus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M0 8.5h20v3H0z" />
    <path fill="#fff" d="M11.5 0v20h-3V0z" />
  </svg>
);
export default SvgPlus;
