import * as React from 'react';
const SvgCameraVideo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.222}
      d="M10.778 4H3.444A2.444 2.444 0 0 0 1 6.444v6.112A2.444 2.444 0 0 0 3.444 15h7.334a2.444 2.444 0 0 0 2.444-2.444V6.444A2.444 2.444 0 0 0 10.778 4M15.667 11.944l2.782 1.391a.61.61 0 0 0 .884-.546V6.21a.61.61 0 0 0-.884-.546l-2.782 1.39"
    />
  </svg>
);
export default SvgCameraVideo;
