import * as React from 'react';
const SvgUnlink = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625 14.375a5.628 5.628 0 0 1 7.833-5.173M2.813 4.063a3.438 3.438 0 1 0 6.874 0 3.438 3.438 0 1 0-6.874 0M9.375 14.374a5 5 0 1 0 10 0 5 5 0 1 0-10 0M11.875 14.374h5"
      />
    </g>
  </svg>
);
export default SvgUnlink;
