import * as React from 'react';
const SvgModelizar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#modelizar_svg__a)"
    >
      <path d="m3.217 19.367-1.767-2.1a3.75 3.75 0 0 1-.833-2.342v-4.308a1.25 1.25 0 0 1 1.25-1.25v0a1.25 1.25 0 0 1 1.25 1.25v3.241" />
      <path d="M6.875 19.367V16.75a3.7 3.7 0 0 0-.633-2.083l-.95-1.425a1.167 1.167 0 0 0-1.667-.367v0a1.17 1.17 0 0 0-.392 1.492l1.125 1.875M16.775 19.367l1.775-2.1a3.75 3.75 0 0 0 .833-2.342v-4.308a1.25 1.25 0 0 0-1.25-1.25v0a1.25 1.25 0 0 0-1.25 1.25v3.216" />
      <path d="M13.125 19.367V16.75a3.7 3.7 0 0 1 .633-2.083l.95-1.425a1.167 1.167 0 0 1 1.667-.367v0a1.17 1.17 0 0 1 .392 1.492l-1.125 1.875M10 3.583c2.338 0 4.234-.664 4.234-1.483S12.338.617 10 .617 5.767 1.28 5.767 2.1 7.662 3.583 10 3.583" />
      <path d="M5.767 2.1c-.001.5.095.995.283 1.458a1.45 1.45 0 0 1-.167 1.375A4.66 4.66 0 0 0 5 7.667a4.917 4.917 0 0 0 5 4.833 4.92 4.92 0 0 0 5-4.833c0-.982-.308-1.938-.883-2.734a1.45 1.45 0 0 1-.167-1.375 3.9 3.9 0 0 0 .283-1.458" />
    </g>
    <defs>
      <clipPath id="modelizar_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgModelizar;
