import React, { useEffect, useState } from 'react';
import { Modal, Table, Tag } from 'antd';
import {
  FilterOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  capitalizeFirst,
  getTimeAgo,
  handleCopyClipboard,
} from '../../util/helpers';
import {
  actionSetPagination,
  actionGetList,
  actionGetPage,
  actionGetTranslatedPages,
  actionSetCurrentPage,
} from '../../store/mainActions';

import ContainerHeader from '../../components/ContainerHeader';

import PagePreview from '../../components/PagePreview';

const columns = (handleOpenPreview, handleEditPage) => [
  {
    title: 'Title',
    dataIndex: 'name',
    width: '40%',
    // sorter: (a, b) => b.title.localeCompare(a.title),
    render: (title) =>
      title && <div dangerouslySetInnerHTML={{ __html: title }} />,
  },
  {
    title: 'Identifier',
    dataIndex: 'uuid',
    width: '30%',
    render: (id) => (
      <span
        onClick={() => handleCopyClipboard(id)}
        style={{ cursor: 'pointer' }}
      >
        {id}
      </span>
    ),
  },
  {
    title: 'Course',
    dataIndex: 'course',
    width: '20%',
  },
  {
    title: 'Tab',
    dataIndex: 'tab',
    width: '20%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '20%',
    render: (actions, all) => (
      <div style={{ display: 'flex' }}>
        <div style={{ textAlign: 'center', padding: '0 5px' }}>
          <EyeOutlined onClick={() => handleOpenPreview(all)} />
        </div>
        <div style={{ textAlign: 'center', padding: '0 5px' }}>
          <EditOutlined onClick={() => handleEditPage(all)} />
        </div>
      </div>
    ),
  },
];

const Pages = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pages = useSelector(({ pages }) => pages.pages);
  const pagesCount = useSelector(({ pages }) => pages.count);
  const loading = useSelector(({ common }) => common.loading);
  const success = useSelector(({ common }) => common.success);
  const pagination = useSelector(
    ({ paginationFilters }) => paginationFilters.pages.pagination
  );
  const filters = useSelector(
    ({ paginationFilters }) => paginationFilters.pages.filters
  );

  const [previewVisible, setPreviewVisible] = useState(false);
  const [waitEdit, setWaitEdit] = useState(false);

  useEffect(() => {
    dispatch(actionGetTranslatedPages());
  }, [filters, pagination]);

  useEffect(() => {
    if (waitEdit && !loading && success) {
      setWaitEdit(false);
      history.push('/newContent');
    } else if (waitEdit && !loading && !success) {
      setWaitEdit(false);
    }
  }, [loading, success]);

  const handleDeletePage = (e, id) => {
    // TODO: DELETE CONTENT
    e.stopPropagation();
    // dispatch(actionDeletePage(id));
  };

  const handleEditPage = (page) => {
    setWaitEdit(true);
    dispatch(actionGetPage(page));
  };

  const handlePagination = (newPage, newPageSize) => {
    const _pagination = {
      offset: (newPage - 1) * newPageSize,
      length: newPageSize,
    };
    dispatch(actionSetPagination('pages', _pagination));
  };

  const paginationSettings =
    pagesCount > 50
      ? {
          total: pagesCount,
          pageSize: pagination.length,
          current: pagination.offset / pagination.length + 1,
          onChange: (newPage, newPageSize) =>
            handlePagination(newPage, newPageSize),
          position: ['bottomCenter'],
          showTotal: () => `Total ${pagesCount} items`,
          size: 'small',
          showSizeChanger: false,
        }
      : false;

  const handleOpenPreview = (page) => {
    setPreviewVisible(true);
    dispatch(actionGetPage(page));
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
    dispatch(actionSetCurrentPage({}));
  };

  return (
    <>
      <ContainerHeader title="Pages" />
      {/* <div className={styles.filterContainer}>
        <Input
          placeholder="Filter"
          suffix={<FilterOutlined />}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div> */}
      <Table
        className="gx-table-no-bordered"
        columns={columns(handleOpenPreview, handleEditPage)}
        dataSource={pages}
        pagination={paginationSettings}
        bordered={false}
        rowKey={(record) => record.uuid}
        size="medium"
        loading={loading}
      />
      <Modal
        visible={previewVisible}
        closable
        onCancel={handleClosePreview}
        footer={null}
        width="90%"
        style={{ height: '80%' }}
        bodyStyle={{ height: '100%' }}
        wrapClassName="inm-preview-wrapModal"
      >
        <PagePreview />
      </Modal>
    </>
  );
};

export default Pages;
