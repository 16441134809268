import * as React from 'react';
const SvgIndagar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#indagar_svg__a)"
    >
      <path d="M3.125 5.625h8.75M3.125 9.375H6.25M3.125 13.125H6.25M6.25 16.875H1.875a1.25 1.25 0 0 1-1.25-1.25V1.875a1.25 1.25 0 0 1 1.25-1.25h8.858c.33 0 .649.132.883.366l2.393 2.393c.234.235.366.552.366.884V6.25" />
      <path d="M8.762 13.125a4.375 4.375 0 1 0 8.75 0 4.375 4.375 0 0 0-8.75 0M19.375 19.375l-3.144-3.157" />
    </g>
    <defs>
      <clipPath id="indagar_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIndagar;
