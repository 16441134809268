import * as React from 'react';
const SvgInfo2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <circle cx={8} cy={8} r={8} fill="#D1F4F0" />
    <path
      fill="#18C6B5"
      fillRule="evenodd"
      d="M8 4a1 1 0 1 0 0 2 1 1 0 0 0 0-2m0 3a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V8a1 1 0 0 0-1-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInfo2;
