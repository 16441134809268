import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import SearchDragCRUD from '../../../../components/SearchDragCRUD';
import {
  setValidation,
  actionGetResources,
  actionSetResources,
  actionSetFilters,
  actionSetPagination,
} from '../../../../store/mainActions';

import * as resourcesApi from '../../../../store/resources/dataSource';
import { defaultLanguage } from '../../../../util/languageList';

const ListComposer = (props) => {
  const {
    contentTypes,
    type,
    handleChangeInput,
    listBlock,
    isPreview,
    activeLanguages,
    tagsList,
    pageId,
  } = props;

  const allResults = useSelector(({ resources }) => resources.resources);
  // const loading = useSelector(({ common }) => common.loading);
  const filters = useSelector(
    ({ paginationFilters }) => paginationFilters.resources.filters
  );

  const [listResources, setListResources] = useState({ 1: [] });
  const [searchName, setSearchName] = useState('');
  const [searchID, setSearchID] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchResult, setSearchResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const handleValidation = useCallback(
    (isValid) => dispatch(setValidation(isValid)),
    []
  );

  const addResourceToList = (resource, block) => {
    setListResources({
      ...listResources,
      [block]: [...listResources[block], resource],
    });
  };

  const removeResourceFromList = (index, block) => {
    setListResources({
      ...listResources,
      [block]: listResources[block].filter((_, i) => index !== i),
    });
  };

  const getListResourcesData = () => {
    let totalResources = 0;
    let totalDuration = 0;
    if (Object.keys(listResources)) {
      for (let i = 1; i <= Object.keys(listResources).length; i++) {
        listResources[i].forEach((resource) => {
          totalResources++;
          totalDuration += resource.duration;
        });
      }
    }
    return { totalResources, totalDuration };
  };

  const listResourcesData = getListResourcesData();

  useEffect(() => {
    if (listResourcesData.totalResources >= 1) handleValidation(true);
    else handleValidation(false);
  }, [listResourcesData]);

  useEffect(() => {
    setListResources({ 1: listBlock });
    setSearchName('');
    setSearchID('');
    setSearchType('');
    setSearchResult({});
    setError(false);
    dispatch(actionSetFilters('resources', {}));
    dispatch(actionSetResources({ resources: [], count: 0 }));
  }, []);

  useEffect(() => {
    if (searchType || searchName || searchID) dispatch(actionGetResources());
    else dispatch(actionSetResources({ resources: [], count: 0 }));
  }, [filters]);

  useEffect(() => {
    dispatch(actionSetPagination('resources', { offset: 0, length: 50 }));
    return () => {
      dispatch(actionSetFilters('resources', {}));
      dispatch(actionSetPagination('resources', { offset: 0, length: 50 }));
    };
  }, []);

  useEffect(() => {
    handleChangeInput('listBlock', listResources[1]);
  }, [listResources]);

  const getListResourcesList = () => {
    const list = [];
    Object.keys(listResources).forEach((block) =>
      list.push(...listResources[block])
    );
    return list;
  };

  const handleSearchResource = async () => {
    setLoading(true);
    setError(false);
    if (searchID) {
      let res;
      try {
        res = await resourcesApi.getTranslatedResourceByType(
          searchType,
          searchID,
          defaultLanguage
        );
        res = res.data;
      } catch (e) {
        console.error(e);
        setError(true);
      }

      setSearchResult(res);
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      source.droppableId === 'search'
    ) {
      return;
    }

    const sourceBlockIndex = source.droppableId;
    const destinationBlockIndex = destination.droppableId;
    const destinationBlock = cloneDeep(listResources[destinationBlockIndex]);

    if (sourceBlockIndex === 'search') {
      const resource =
        searchResult || allResults.find((e) => e.uuid === draggableId);
      resource && addResourceToList(resource, destinationBlockIndex);
      return;
    }
    if (destinationBlockIndex === 'search') {
      const resource =
        searchResult ||
        listResources[sourceBlockIndex].find((e) => e.uuid === draggableId);
      resource && removeResourceFromList(resource, sourceBlockIndex);
      return;
    }
    const sourceBlock = cloneDeep(listResources[sourceBlockIndex]);
    const resource = cloneDeep(listResources[sourceBlockIndex][source.index]);

    if (sourceBlockIndex === destinationBlockIndex) {
      sourceBlock.splice(source.index, 1);
      sourceBlock.splice(destination.index, 0, resource);
      setListResources({
        ...listResources,
        [sourceBlockIndex]: sourceBlock,
      });
    } else {
      sourceBlock.splice(source.index, 1);
      destinationBlock.splice(destination.index, 0, resource);
      setListResources({
        ...listResources,
        [sourceBlockIndex]: sourceBlock,
        [destinationBlockIndex]: destinationBlock,
      });
    }
  };

  const getContentTypes = () =>
    contentTypes.filter(
      (content) => content.value !== 'page' && content.value !== 'resourceList'
    );

  const filterById = (result) => {
    if (searchID) {
      return result.uuid.includes(searchID.trim());
    }
    return true;
  };

  const filterByLanguages = (result) => {
    const resultLanguages = Object.keys(result.translations).map(
      (language) => language
    );
    return !activeLanguages?.some(
      (language) => !resultLanguages.includes(language)
    );
  };

  return (
    <SearchDragCRUD
      type={type}
      onDragEnd={onDragEnd}
      searchID={searchID}
      setSearchID={setSearchID}
      searchType={searchType}
      getContentTypes={getContentTypes}
      setSearchType={setSearchType}
      error={error}
      handleSearchElement={handleSearchResource}
      loading={loading}
      searchResult={searchResult}
      allResults={allResults}
      getAllElementsList={getListResourcesList}
      tagsList={tagsList}
      addElement={addResourceToList}
      elementsData={listResourcesData}
      isPreview={isPreview}
      handleChangeInput={handleChangeInput}
      addedElements={listResources}
      removeElement={removeResourceFromList}
      pageId={pageId}
    />
  );
};

export default ListComposer;
