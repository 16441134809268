import React, { useEffect } from 'react';
import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Logo from 'assets/images/logo.svg';
import {
  hideMessage,
  showAuthLoader,
  userGoogleSignIn,
} from '../store/mainActions';

const SignIn = () => {
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, user } = useSelector(
    ({ auth }) => auth
  );
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (user !== undefined) {
      history.push('/');
    }
  });

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature'/> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={Logo} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                dispatch(showAuthLoader());
                dispatch(userGoogleSignIn(credentialResponse));
              }}
              onError={() => {
                console.error('Login Failed');
              }}
              useOneTap
            />
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress />
            </div>
          ) : null}
          {showMessage ? message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
