import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Select } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  actionSaveResource,
  actionSetCurrentResource,
  actionRequestCourses,
  actionRequestTabs,
  actionRequestTags,
  actionSetCurrentList,
  actionSetCurrentPage,
} from '../../store/mainActions';
import { defaultLanguage, languageList } from '../../util/languageList';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';

import ContainerHeader from '../../components/ContainerHeader';
import history from '../../store/history';
import { filterTags, getCoursesFromTags } from '../../util/helpers';

const { Option } = Select;

const contentTypes = [
  {
    title: 'Resources',
    value: 'RESOURCES',
    selectable: false,
    children: [
      {
        title: 'Video',
        value: 'vimeo',
      },
      {
        title: 'Genially',
        value: 'genially',
      },
      {
        title: 'Pdf',
        value: 'pdf',
      },
      {
        title: 'Generic printable',
        value: 'generic',
      },
      {
        title: 'Applet',
        value: 'applet',
      },
      {
        title: 'Manipulative',
        value: 'manipulativeEnvironment',
      },
      {
        title: 'Geogebra',
        value: 'geogebra',
      },
      {
        title: 'Digital guide',
        value: 'digitalGuide',
      },
    ],
  },
  {
    title: 'Session',
    value: 'session',
  },
  {
    title: 'List',
    value: 'resourceList',
  },
  {
    title: 'Page',
    value: 'page',
  },
];

const composeOptions = (list) => {
  const options = [];
  list.forEach((e) =>
    options.push(
      <Option value={e.value} key={e.key}>
        {e.key}
      </Option>
    )
  );
  return options;
};

const composeTagOptions = (tags) =>
  tags
    .filter((tag) => filterTags(tag.uuid))
    .map((e) => (
      <Option value={e.uuid} key={e.uuid} label={e.translation}>
        {e.translation}
      </Option>
    ));

const composeCoursesOptions = (courses) =>
  courses.map((e) => (
    <Option value={e.uuid} key={e.uuid} label={e.name}>
      {e.name}
    </Option>
  ));

const createDefaultTranslationsState = () => {
  const translationsState = {};
  languageList.forEach(({ value }) => {
    translationsState[value] = { ...defaultTranslationState };
  });
  return translationsState;
};

const defaultTranslationState = {
  title: '',
  description: '',
  fragments: [],
  resourceId: '',
  videoUrl: '',
  duration: 0,
  fileName: '',
  fileId: '',
  thumbnailId: undefined,
  thumbnailUrl: undefined,
  enumeration: null,
  icon: null,
  status: 'available',
  list_type: null,
};

const defaultState = {
  appletPack: '',
  appletScene: '',
  currentStep: 1,
  type: '',
  isGuide: false,
  isPrintable: false,
  isDownloadable: false,
  isProjectable: false,
  isPublic: false,
  notAutomaticallyRelatable: false,
  courses: [],
  dynamics: [],
  contentBlock: [],
  others: [],
  language: defaultLanguage,
  uuid: null,
  status: 'DRAFT',
  settings: {
    enable_right_click: false,
    enable_label_drags: false,
    show_reset_icon: false,
    enable_shift_drag_zoom: false,
    show_menu_bar: false,
    show_tool_bar: false,
    show_algebra_input: false,
    allow_style_bar: false,
    allow_upscale: false,
  },
  isPreview: false,
  printableIds: '',
  tabs: [],
  pageId: null,
  script: 'classic',
  sessionBlocks: {
    1: [],
    2: [],
    3: [],
  },
  listBlock: [],
  pageBlock: [],
  guide: {
    1: [],
  },
  printables: {
    1: [],
  },
  suggestedLists: {
    1: [],
  },
  translations: createDefaultTranslationsState(),
  visibilityType: 'BLANK',
  sessionCode: '',
  isCompletable: false,
  sessionWithoutPlayer: false,
};

class NewContent extends Component {
  constructor(props) {
    super(props);
    const { currentResource, currentList, currentPage } = props;

    const currentContent = !isEmpty(currentResource)
      ? currentResource
      : !isEmpty(currentList)
      ? currentList
      : !isEmpty(currentPage)
      ? currentPage
      : {};

    const {
      type,
      uuid,
      dynamics,
      courses,
      status,
      printableIds,
      translations,
      isPrintable,
      isDownloadable,
      isProjectable,
      isPublic,
      isGuide,
      sessionBlocks,
      guide,
      printables,
      tags,
      listBlock,
      isPreview,
      pageBlock,
      tabs,
      pack,
      scene,
      settings,
      pageId,
      script,
      suggestedLists,
      notAutomaticallyRelatable,
      visibility_type,
      sessionCode,
      isCompletable,
      sessionWithoutPlayer,
      list_type,
    } = currentContent;

    const translationsState = {};
    languageList.forEach(({ value }) => {
      translationsState[value] = {
        ...defaultTranslationState,
        ...translations?.[value],
      };
    });

    this.state = {
      appletPack: pack || defaultState.appletPack,
      appletScene: scene || defaultState.appletScene,
      currentStep: defaultState.currentStep,
      type: type || defaultState.type,
      isGuide: isGuide || defaultState.isGuide,
      isPrintable: isPrintable || defaultState.isPrintable,
      isDownloadable: isDownloadable || defaultState.isDownloadable,
      isProjectable: isProjectable || defaultState.isProjectable,
      isPublic: isPublic || defaultState.isPublic,
      notAutomaticallyRelatable:
        notAutomaticallyRelatable || defaultState.notAutomaticallyRelatable,
      courses: courses || defaultState.courses,
      dynamics: dynamics || defaultState.dynamics,
      contentBlock: defaultState.contentBlock,
      others: defaultState.others,
      language: defaultState.language,
      sessionBlocks: sessionBlocks || defaultState.sessionBlocks,
      pageId: pageId || defaultState.pageId,
      translations: translationsState,
      uuid: uuid || defaultState.uuid,
      status: status || defaultState.status,
      list_type: list_type || defaultState.list_type,
      guide: guide || defaultState.guide,
      printables: printables || defaultState.printables,
      suggestedLists: suggestedLists || defaultState.suggestedLists,
      listBlock: listBlock || defaultState.listBlock,
      pageBlock: pageBlock || defaultState.pageBlock,
      settings: settings || defaultState.settings,
      isPreview: isPreview || defaultState.isPreview,
      printableIds:
        printableIds !== '' ? printableIds : defaultState.printableIds,
      tabs: tabs || defaultState.tabs,
      tags: tags
        ?.filter((t) => !t.translations)
        ?.map((t) => t.uuid)
        ?.filter(filterTags),
      script: script || defaultState.script,
      visibilityType: visibility_type || defaultState.visibilityType,
      sessionCode: sessionCode || defaultState.sessionCode,
      isCompletable: isCompletable || defaultState.isCompletable,
      sessionWithoutPlayer:
        sessionWithoutPlayer || defaultState.sessionWithoutPlayer,
    };
  }

  componentDidMount() {
    function confirmExit() {
      return 'show warning';
    }
    const { getTags, getCourses, getTabs } = this.props;
    getTags(defaultLanguage);
    getCourses(defaultLanguage);
    getTabs(defaultLanguage);
    window.onbeforeunload = confirmExit;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { type } = this.state;
    const { currentResource, currentList, currentPage } = this.props;
    const currentContent = currentResource || currentList || currentPage;
    if (
      prevProps.currentResource !== currentResource ||
      prevProps.currentList !== currentList ||
      prevProps.currentPage !== currentPage
    ) {
      if (isEmpty(currentContent)) this.setState({ ...defaultState });
    }
    if (prevState.type !== type) {
      this.setState({ ...defaultState, type });
    }
  }

  componentWillUnmount() {
    const { setCurrentResource, setCurrentList, setCurrentPage } = this.props;
    setCurrentResource({});
    setCurrentList({});
    setCurrentPage({});
    window.onbeforeunload = null;
  }

  handleChangeInput = (field, value, hasTranslation, subfield) => {
    if (hasTranslation) {
      const { language } = this.state;

      this.setState((prevState) => ({
        translations: {
          ...prevState.translations,
          [language]: {
            ...prevState.translations[language],
            [field]: value,
          },
        },
      }));
    } else if (subfield) {
      this.setState((prevState) => ({
        [field]: {
          ...prevState[field],
          [subfield]: value,
        },
      }));
    } else {
      this.setState({ [field]: value });
    }
  };

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleUploadedImage = ({ url, uuid }) => {
    const { language } = this.state;
    this.setState((prevState) => ({
      translations: {
        ...prevState.translations,
        [language]: {
          ...prevState.translations[language],
          thumbnailUrl: url,
          thumbnailId: uuid,
        },
      },
    }));
  };

  handleNextStep = () => {
    const { currentStep, translations } = this.state;
    if (currentStep === 1) {
      Object.keys(translations).forEach((lang) => {
        const { title, description } = translations[lang];
        const titleParsed =
          title && title.replace('<p>', '').replace('</p>', '').trim();
        if (!title || !titleParsed) {
          this.setState((prevState) => ({
            translations: {
              ...prevState.translations,
              [lang]: {
                ...prevState.translations[lang],
                title: '',
                description: '',
              },
            },
          }));
        }
        const descParsed =
          description &&
          description.replace('<p>', '').replace('</p>', '').trim();
        if (!descParsed) {
          this.setState((prevState) => ({
            translations: {
              ...prevState.translations,
              [lang]: {
                ...prevState.translations[lang],
                description: '',
              },
            },
          }));
        }
      });
    }
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };

  handlePrevStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }));
  };

  addFragment = (fragment) => {
    const { language } = this.state;
    this.setState((prevState) => ({
      translations: {
        ...prevState.translations,
        [language]: {
          ...prevState.translations[language],
          fragments: [...prevState.translations[language].fragments, fragment],
        },
      },
    }));
  };

  removeFragment = (order) => {
    const { language, translations } = this.state;
    const { fragments } = translations[language];
    let newFragments = [...fragments];
    newFragments = newFragments.filter((fragment) => fragment.order !== order);
    newFragments.forEach((fragment) => {
      if (fragment.order > order) {
        fragment.order--;
      }
    });

    this.setState((prevState) => ({
      translations: {
        ...prevState.translations,
        [language]: {
          ...prevState.translations[language],
          fragments: newFragments,
        },
      },
    }));
  };

  editFragment = (key, order, value) => {
    const { language, translations } = this.state;
    const { fragments } = translations[language];
    const newFragments = [...fragments];
    newFragments.forEach((fragment) => {
      if (fragment.order === order) {
        fragment[key] = value;
      }
    });
    this.setState((prevState) => ({
      translations: {
        ...prevState.translations,
        [language]: {
          ...prevState.translations[language],
          fragments: newFragments,
        },
      },
    }));
  };

  validateThumbnail = () => {
    const { type, translations } = this.state;

    if (type === 'resourceList' || type === 'page') return true;

    return translations[defaultLanguage].thumbnailId;
  };

  validateData = () => {
    const { currentStep, type, translations, courses, tabs } = this.state;
    const { isValid } = this.props;
    if (currentStep === 1) {
      const atLeastOneLanguage = Object.keys(translations).some(
        (lang) => translations[lang].title
      );

      const isThumbnailValid = this.validateThumbnail();

      const isSessionCodeValid = this.validateSessionCode();

      const courseAndTab =
        type === 'page' ? !isEmpty(courses) && !isEmpty(tabs) : true;
      return (
        type &&
        isThumbnailValid &&
        atLeastOneLanguage &&
        courseAndTab &&
        isSessionCodeValid
      );
    }
    if (currentStep === 2) {
      switch (type) {
        case 'vimeo':
          return this.validateVideoData();
        case 'pdf':
        case 'generic':
          return this.validatePdfData();
        case 'genially':
          return this.validatePdfData();
        case 'applet':
          return this.validateAppletFields();
        case 'manipulativeEnvironment':
          return true; // TODO VALIDATE MAnipulative
        case 'geogebra':
          return true;
        case 'session':
          return isValid;
        case 'page':
          return isValid;
        case 'resourceList':
          return isValid;
        default:
          return false;
      }
    }
    return false;
  };

  validateVideoData = () => {
    const { translations, uuid } = this.state;
    const activeLanguages = this.getActiveLanguages();
    if (uuid) {
      return !activeLanguages.some((lang) => !translations[lang].videoUrl);
    }
    const someFragmentError = activeLanguages.some((lang) =>
      translations[lang].fragments.some(
        (fragment) =>
          this.overlaps(translations[lang].fragments, fragment) ||
          !this.validStartEndTime(
            fragment.from,
            fragment.to,
            translations[lang].duration
          ) ||
          this.sameStartEndTime(translations[lang].fragments, fragment)
      )
    );
    const someMissingVideo = activeLanguages.some(
      (lang) => translations[lang].duration === 0
    );
    return !someFragmentError && !someMissingVideo;
  };

  validateAppletFields = () => {
    const { appletPack, appletScene } = this.state;
    return !!appletPack && !!appletScene;
  };

  validatePdfData = () => {
    const { translations } = this.state;
    const activeLanguages = this.getActiveLanguages();
    const someMissingFile = activeLanguages.some(
      (lang) =>
        translations[lang].fileId === '' ||
        translations[lang].fileId === undefined
    );
    return !someMissingFile;
  };

  validateFragment = (fragment) => fragment.thumbnail_id && fragment.caption;

  validateSessionCode = () => {
    const { sessionCode } = this.state;
    return sessionCode === '' || sessionCode.length <= 10;
  };

  sameStartEndTime = (all, selected) =>
    all.some(
      (element) =>
        element.from === selected.from &&
        element.to === selected.to &&
        element.order !== selected.order
    );

  overlaps = (all, selected) =>
    all.some(
      (element) =>
        (element.from < selected.from && element.to > selected.from) ||
        (element.from < selected.to && element.to > selected.to)
    );

  validStartEndTime = (start, end, max) => start < end && end <= max;

  getActiveLanguages = () => {
    const { translations } = this.state;
    return Object.keys(translations).filter((lang) => translations[lang].title);
  };

  publishResource = () => {
    const { saveResource } = this.props;
    saveResource(this.state);
  };

  openCancelModal = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: 'Confirm',
      content: "Are you sure you want to cancel? All changes won't be saved.",
      cancelText: 'Continue creating or editing content',
      okText: 'Leave without saving',
      onOk: this.handleCancel,
      width: '50%',
      maskClosable: true,
    });
  };

  handleCancel = () => {
    history.goBack();
  };

  render() {
    const {
      currentStep,
      type,
      language,
      isGuide,
      isPrintable,
      isDownloadable,
      isProjectable,
      isPublic,
      courses,
      dynamics,
      contentBlock,
      others,
      translations,
      appletPack,
      appletScene,
      uuid,
      status,
      list_type,
      guide,
      printables,
      sessionBlocks,
      listBlock,
      pageBlock,
      settings,
      isPreview,
      tabs,
      tags,
      pageId,
      script,
      suggestedLists,
      notAutomaticallyRelatable,
      printableIds,
      visibilityType,
      sessionCode,
      isCompletable,
      sessionWithoutPlayer,
    } = this.state;

    const { tagsList, coursesList, tabsList } = this.props;

    const tagOptions = composeTagOptions(tagsList);
    const coursesOptions = composeCoursesOptions(coursesList);
    const fakeCoursesOptions = composeTagOptions(
      tagsList.filter((tag) => getCoursesFromTags(tag.translation))
    ); // TODO provisional
    const tabsOptions = composeCoursesOptions(tabsList);
    const activeLanguages = this.getActiveLanguages();
    const languageOptions = composeOptions(
      currentStep === 1
        ? languageList
        : languageList.filter((lang) => activeLanguages.includes(lang.value))
    );

    const thumbnailUrl = translations[language]?.thumbnailUrl;

    return (
      <>
        <ContainerHeader
          title={`${uuid ? 'Edit' : 'Add new content'} ${
            type
              ? `: ${
                  contentTypes.find(
                    (obj) =>
                      obj.value === type ||
                      obj.children?.find((ch) => ch.value === type)
                  )?.title
                }`
              : ''
          }`}
        />
        {currentStep === 1 && (
          <FirstStep
            list_type={list_type}
            printableIds={printableIds}
            type={type}
            contentTypes={contentTypes}
            handleChangeInput={this.handleChangeInput}
            isGuide={isGuide}
            isPrintable={isPrintable}
            isDownloadable={isDownloadable}
            isProjectable={isProjectable}
            isPublic={isPublic}
            language={language}
            languageOptions={languageOptions}
            translations={translations}
            handleUploadedImage={this.handleUploadedImage}
            thumbnailUrl={thumbnailUrl}
            dynamics={dynamics}
            tagOptions={tagOptions}
            coursesOptions={coursesOptions}
            fakeCoursesOptions={fakeCoursesOptions}
            courses={courses}
            contentBlock={contentBlock}
            others={others}
            handleNextStep={this.handleNextStep}
            validateData={this.validateData}
            uuid={uuid}
            tabsOptions={tabsOptions}
            tabs={tabs}
            tags={tags}
            visibilityType={visibilityType}
            openCancelModal={this.openCancelModal}
            notAutomaticallyRelatable={notAutomaticallyRelatable}
            sessionCode={sessionCode}
            validateSessionCode={this.validateSessionCode}
            isCompletable={isCompletable}
          />
        )}
        {currentStep === 2 && (
          <SecondStep
            type={type}
            publishResource={this.publishResource}
            handlePrevStep={this.handlePrevStep}
            title={translations[language].title}
            handleChangeInput={this.handleChangeInput}
            language={language}
            languageOptions={languageOptions}
            courses={courses}
            coursesList={coursesList}
            videoUrl={translations[language].videoUrl}
            addFragment={this.addFragment}
            removeFragment={this.removeFragment}
            fragments={translations[language].fragments}
            editFragment={this.editFragment}
            validateData={this.validateData}
            activeLanguages={this.getActiveLanguages()}
            duration={translations[language].duration}
            handleUploadPdf={this.handleUploadPdf}
            fileName={translations[language].fileName}
            appletScene={appletScene}
            appletPack={appletPack}
            contentTypes={contentTypes}
            status={status}
            list_type={list_type}
            guide={guide}
            printables={printables}
            suggestedLists={suggestedLists}
            sessionBlocks={sessionBlocks}
            listBlock={listBlock}
            pageBlock={pageBlock}
            settings={settings}
            isPreview={isPreview}
            tagsList={tagsList}
            translations={translations}
            openCancelModal={this.openCancelModal}
            uuid={uuid}
            pageId={pageId}
            script={script}
            sessionWithoutPlayer={sessionWithoutPlayer}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveResource: (data) => dispatch(actionSaveResource(data)),
  getTags: (locale) => dispatch(actionRequestTags(locale)),
  getCourses: (locale) => dispatch(actionRequestCourses(locale)),
  getTabs: (locale) => dispatch(actionRequestTabs(locale)),
  setCurrentResource: (resource) =>
    dispatch(actionSetCurrentResource(resource)),
  setCurrentList: (list) => dispatch(actionSetCurrentList(list)),
  setCurrentPage: (page) => dispatch(actionSetCurrentPage(page)),
});

const mapStateToProps = ({
  resources,
  common,
  tags,
  courses,
  tabs,
  lists,
  pages,
}) => ({
  currentResource: resources.currentResource,
  currentList: lists.currentList,
  currentPage: pages.currentPage,
  isValid: common.isValid,
  tagsList: tags.tags,
  coursesList: courses.courses,
  tabsList: tabs.tabs,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewContent);
