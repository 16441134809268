import React, { useState, useEffect } from 'react';
import { Layout, Popover, Button, Select, TreeSelect } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import CustomScrollbars from 'util/CustomScrollbars';
import SearchBox from 'components/SearchBox';
import UserInfo from 'components/UserInfo';
import AppNotification from 'components/AppNotification';
import MailNotification from 'components/MailNotification';
import Auxiliary from 'util/Auxiliary';
import { useDispatch, useSelector } from 'react-redux';

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from '../../constants/ThemeSetting';
import {
  switchLanguage,
  toggleCollapsedSideNav,
  actionSetCurrentResource,
  actionSetResources,
  actionSetFilters,
  actionSetPagination,
  actionSetLists,
  actionSetPages,
  getSitemapSuccess,
} from '../../store/mainActions';
import languageData from './languageData';

import * as resourcesApi from '../../store/resources/dataSource';
import * as listsApi from '../../store/lists/dataSource';
import * as pagesApi from '../../store/pages/dataSource';

import { getSingleTranslation } from '../../store/translations/dataSource';
import { defaultLanguage } from '../../util/languageList';

const { Header } = Layout;

const contentTypes = [
  {
    title: 'Resources',
    value: 'RESOURCES',
    selectable: false,
    children: [
      {
        title: 'Video',
        value: 'vimeo',
      },
      {
        title: 'Genially',
        value: 'genially',
      },
      {
        title: 'Pdf',
        value: 'pdf',
      },
      {
        title: 'Generic printable',
        value: 'generic',
      },
      {
        title: 'Applet',
        value: 'applet',
      },
      {
        title: 'Geogebra',
        value: 'geogebra',
      },
      {
        title: 'Manipulative',
        value: 'manipulativeEnvironment',
      },
    ],
  },
  {
    title: 'Session',
    value: 'session',
  },
];

const Topbar = () => {
  const { locale, width, navCollapsed, navStyle } = useSelector(
    ({ settings }) => settings
  );
  const [searchID, setSearchID] = useState('');
  const [searchType, setSearchType] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const pathName = history.location.pathname;

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={(e) => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearch = (evt) => {
    setSearchID(evt.target.value);
  };

  useEffect(() => {
    if (!searchID) {
      if (pathName === '/resources') {
        const filters = {
          type: searchType,
        };
        dispatch(actionSetPagination('resources', { offset: 0, length: 50 }));
        dispatch(actionSetFilters('resources', filters));
      }
      if (pathName === '/lists') {
        dispatch(actionSetPagination('lists', { offset: 0, length: 50 }));
      }
      if (pathName === '/pages') {
        dispatch(actionSetPagination('pages', { offset: 0, length: 50 }));
      }
    }
  }, [searchType, searchID, pathName]);

  const getSearchResource = async () => {
    let res;
    try {
      res = await resourcesApi.getTranslatedResourceByType(
        searchType,
        searchID,
        defaultLanguage
      );
      res = res.data;
    } catch (e) {
      console.error(e);
    }
    return res;
  };

  const getSearchList = async () => {
    let res;
    try {
      res = await listsApi.getList(searchID);
      res = res.data;
      // TODO: Use translated list endpoint when available
      let nameRes = await getSingleTranslation(res.name_id);
      nameRes = nameRes.data.translations;
      const name = nameRes.find((t) => t.locale === defaultLanguage)?.translation;
      res = { ...res, name };
    } catch (e) {
      console.error(e);
    }
    return res;
  };

  const getSearchPage = async () => {
    let res;
    try {
      res = await pagesApi.getTranslatedPage(searchID, defaultLanguage);
      res = res.data;
    } catch (e) {
      console.error(e);
    }
    return res;
  };

  useEffect(() => {
    if (pathName === '/resources') {
      if (searchID && searchType) {
        getSearchResource(searchID, searchType).then((res) => {
          res && dispatch(actionSetResources({ resources: [res], count: 1 }));
        });
      }
    }
    if (pathName === '/lists') {
      if (searchID) {
        getSearchList(searchID).then((res) => {
          res && dispatch(actionSetLists({ lists: [res], count: 1 }));
        });
      }
    }
    if (pathName === '/pages') {
      if (searchID) {
        getSearchPage(searchID).then((res) => {
          res && dispatch(actionSetPages({ pages: [res], count: 1 }));
        });
      }
    }
  }, [searchID, searchType]);

  useEffect(() => {
    setSearchID('');
    setSearchType('');
  }, [pathName]);

  const isSiteMapPath =
    pathName.includes('sitemaps') || pathName.includes('newSitemap');

  const handleAddNewContent = () => {
    if (!isSiteMapPath) {
      dispatch(actionSetCurrentResource({}));
    } else {
      dispatch(getSitemapSuccess(undefined));
    }
  };

  const newContentUrl = isSiteMapPath ? '/newSitemap' : '/newContent';
  const showLanguageSwitch = false;
  const showNotifications = false;
  const showSearchTypeSelector = pathName === '/resources';
  const showSearchBox =
    pathName === '/resources' || pathName === '/lists' || pathName === '/pages';

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                dispatch(toggleCollapsedSideNav(!navCollapsed));
              }}
            />
          </div>
        ) : null}
      <Link to="/resources" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require('assets/images/mini-logo.ico')} />
      </Link>
      {showSearchBox && (
        <SearchBox
          styleName="gx-d-lg-block gx-lt-icon-search-bar-lg"
          placeholder="Search by ID"
          onChange={updateSearch}
          value={searchID}
        />
      )}

      {showSearchTypeSelector && (
        <TreeSelect
          value={searchType === '' ? null : searchType}
          treeData={contentTypes}
          placeholder="Select type"
          onChange={(e) => setSearchType(e)}
          treeDefaultExpandAll
          className="inm-header-tree-selector"
        />
      )}
      <ul className="gx-header-notifications gx-ml-auto">
        {showNotifications && (
          <Auxiliary>
            <li className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<AppNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-d-block">
                  <i className="icon icon-notification" />
                </span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<MailNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-block">
                  <i className="icon icon-chat-new" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Popover>
            </li>
          </Auxiliary>
        )}
        {showLanguageSwitch && (
          <li className="gx-language">
            <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={languageMenu()}
              trigger="click"
            >
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <i className={`flag flag-24 flag-${locale.icon}`} />
                <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                <i className="icon icon-chevron-down gx-pl-2" />
              </span>
            </Popover>
          </li>
        )}
        <li className="inm-header-button-container">
          <Button type="primary">
            <Link to={newContentUrl} onClick={handleAddNewContent}>
              New Content
            </Link>
          </Button>
        </li>
        <Auxiliary>
          <li className="gx-user-nav">
            <UserInfo />
          </li>
        </Auxiliary>
      </ul>
    </Header>
  );
};

export default Topbar;
