import * as React from 'react';
const SvgSamSocioafectives = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M11.951 11.385a1.35 1.35 0 0 1-1.902 0L5.32 6.574a3.19 3.19 0 0 1-.631-3.747A3.41 3.41 0 0 1 8.76 1.165c.508.164.97.444 1.35.819L11 2.87l.889-.887a3.414 3.414 0 0 1 5.387.843v0a3.18 3.18 0 0 1-.596 3.747zM3.667 21l-1.778-2.113A4 4 0 0 1 1 16.392v-4.59a1.33 1.33 0 0 1 1.333-1.331v0a1.334 1.334 0 0 1 1.334 1.332v3.426"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.667 21v-2.663a3.96 3.96 0 0 0-.676-2.22l-1.013-1.554a1.235 1.235 0 0 0-1.778-.39v0a1.243 1.243 0 0 0-.418 1.589l1.2 1.998M18.333 21l1.778-2.113A4 4 0 0 0 21 16.392v-4.59a1.33 1.33 0 0 0-1.333-1.331 1.334 1.334 0 0 0-1.334 1.332v3.426"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M14.333 21v-2.663a3.96 3.96 0 0 1 .676-2.22l1.013-1.554a1.236 1.236 0 0 1 1.778-.39v0a1.243 1.243 0 0 1 .418 1.589l-1.2 1.998"
    />
  </svg>
);
export default SvgSamSocioafectives;
