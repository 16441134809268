import {
  GET_TRANSLATED_PAGES,
  SET_PAGES,
  SET_CURRENT_PAGE,
  GET_PAGE,
} from '../../constants/ActionTypes';

export const actionGetTranslatedPages = (locale) => ({
  type: GET_TRANSLATED_PAGES,
  locale,
});

export const actionSetPages = (pages) => ({
  type: SET_PAGES,
  pages,
});

export const actionSetCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  page,
});

export const actionGetPage = (page) => ({
  type: GET_PAGE,
  page,
});
