import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';

export default function DeleteConfirmation({ onConfirm }) {
  return (
    <Popconfirm
      title="Are you sure to delete this menu item?"
      onConfirm={onConfirm}
      okText="Yes"
      placement="left"
      cancelText="No"
    >
      <DeleteOutlined />
    </Popconfirm>
  );
}
