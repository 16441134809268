import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { SAVE_TAG, REQUEST_TAGS } from 'constants/ActionTypes';
import {
  actionSetTags,
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../mainActions';
import * as translationApi from '../translations/dataSource';
import * as tagApi from './dataSource';
import { defaultLanguage } from '../../util/languageList';

function* runRequestTags({ locale }) {
  yield put(fetchStart());
  try {
    const res = yield tagApi.getTags(locale);
    const tags = res.data.translated_tag_views;
    yield put(actionSetTags(tags));
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

function* runSaveTag({ tags }) {
  yield put(fetchStart());
  try {
    const translations = [];
    Object.keys(tags).forEach((locale) => {
      translations.push({ locale, translation: tags[locale] });
    });
    const translationResponse = yield translationApi.requestSingleTranslation({
      translations,
    });
    const translationId = translationResponse.data.uuid;
    yield tagApi.createTag({ translation_id: translationId });
    yield runRequestTags({ locale: defaultLanguage });
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

const watcher = () =>
  function* watch() {
    yield takeLatest(SAVE_TAG, runSaveTag);
    yield takeLatest(REQUEST_TAGS, runRequestTags);
  };

export default function* tagsSagas() {
  yield all([fork(watcher())]);
}
