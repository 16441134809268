import * as React from 'react';
const SvgArrowDownMedium = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#arrow-down-medium_svg__a)">
      <path
        stroke="#333"
        strokeWidth={1.5}
        d="M10 13.938a.69.69 0 0 1-.518-.233L3.358 6.742a.404.404 0 0 1 .607-.534l5.354 6.086.563-.494-.563.495q.13.146.307.227a.9.9 0 0 0 .748 0 .9.9 0 0 0 .307-.227v-.001l5.362-6.094.005-.007.006-.006a.4.4 0 0 1 .125-.1l-.342-.667.342.667a.4.4 0 0 1 .452.056l.495-.563-.495.563a.403.403 0 0 1 .03.578l-.006.006-.006.007-6.13 6.967a.7.7 0 0 1-.52.236Z"
      />
    </g>
    <defs>
      <clipPath id="arrow-down-medium_svg__a">
        <path fill="#fff" d="M2.5 2.5h15v15h-15z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowDownMedium;
