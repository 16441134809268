import * as React from 'react';
const SvgPlay2 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.249995}
      d="M1.948 2.713v14.574A1.25 1.25 0 0 0 3.79 18.39l13.603-7.287a1.25 1.25 0 0 0 0-2.204L3.789 1.611a1.25 1.25 0 0 0-1.84 1.102"
    />
  </svg>
);
export default SvgPlay2;
