import * as React from 'react';
const SvgPaginateFilter6 = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path strokeWidth={1.249995} d="M3.125.622h16.25v16.25H3.125Z" />
      <path
        strokeWidth={1.249995}
        d="M16.875 19.372h-15a1.25 1.25 0 0 1-1.25-1.25v-15M9.375 9.997a1.875 1.875 0 1 0 3.75 0 1.875 1.875 0 1 0-3.75 0"
      />
      <path strokeWidth={1.249995} d="M11.875 5.622a2.5 2.5 0 0 0-2.5 2.5V10" />
    </g>
  </svg>
);
export default SvgPaginateFilter6;
