import axios from 'axios';
import { axiosInstance } from '../../util/axiosInstance';

export const API_HOST = process.env.REACT_APP_API_RESOURCES;

export const getLists = async (queryParams) => {
  const url = `${API_HOST}/resourceLists${queryParams}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};

export const createList = async (data) => {
  const url = `${API_HOST}/resourceList`;
  const response = await axiosInstance({ url, method: 'POST', data });

  return response;
};

export const getList = async (id) => {
  const url = `${API_HOST}/resourceList/${id}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};
