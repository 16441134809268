import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Divider, Tag } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { defaultLanguage, languageList } from '../../util/languageList';
import {
  actionSaveCourse,
  actionRequestCourses,
  getCourseTranslations,
} from '../../store/mainActions';
import ContainerHeader from '../../components/ContainerHeader';

const { Option } = Select;

const composeOptions = (list) => {
  const options = [];
  list.forEach((e) =>
    options.push(
      <Option value={e.value} key={e.key}>
        {e.key}
      </Option>
    )
  );
  return options;
};

const NewCourse = (props) => {
  const languageOptions = composeOptions(languageList);
  const dispatch = useDispatch();

  const [activeLanguage, setActiveLanguage] = useState(null);
  const [courses, setCourses] = useState({});

  useEffect(() => {
    dispatch(actionRequestCourses(defaultLanguage));
  }, []);

  const courseList = useSelector(({ courses }) => courses.courses);
  const loading = useSelector(({ common }) => common.loading);
  const success = useSelector(({ common }) => common.success);

  const handleChangeInput = (value) => {
    if (value === '') {
      const { [activeLanguage]: tmp, ...rest } = courses;
      setCourses(rest);
    } else {
      setCourses({
        ...courses,
        [activeLanguage]: value,
      });
    }
  };

  const cleanInputs = () => {
    setActiveLanguage(null);
    setCourses({});
  };

  useEffect(() => {
    if (success && !loading) {
      cleanInputs();
    }
  }, [success]);

  const handleSave = () => dispatch(actionSaveCourse(courses));

  const handleEdit = async (courseId) => {
    cleanInputs();
    const courseTranslations = await getCourseTranslations(courseId);
    const preparecourses = {};
    courseTranslations.forEach((course) => {
      preparecourses[course.locale] = course.translation;
    });
    setCourses(preparecourses);
    setActiveLanguage(Object.keys(preparecourses)[0]);
  };

  return (
    <>
      <ContainerHeader title="Courses" />
      <div className="inm-newTag-addTagContainer">
        <div className="inm-newTag-selectContainer">
          <Select
            className="inm-newTag-dropDown"
            onChange={(value) => setActiveLanguage(value)}
            value={activeLanguage}
            placeholder="Language"
          >
            {languageOptions}
          </Select>
        </div>
        <div className="inm-newTag-inputContainer">
          <Input
            placeholder="Course name"
            value={courses[activeLanguage]}
            onChange={(e) => handleChangeInput(e.target.value)}
            disabled={!activeLanguage}
          />
        </div>
        <div className="inm-newTag-languagesContainer">
          {Object.keys(courses).map((lang) => (
            <div key={lang} className="inm-newTag-box inm-newTag-languageBox">
              {lang}
            </div>
          ))}
        </div>
      </div>
      <div className="inm-newTag-buttonContainer">
        <Button
          disabled={isEmpty(courses)}
          onClick={handleSave}
          loading={loading}
        >
          Save
        </Button>
      </div>
      <Divider />
      Courses available:
      <p style={{ padding: '8px 0' }}>
        {courseList.map((course) => (
          <Tag key={course} onClick={() => handleEdit(course.uuid)}>
            {course.name}
          </Tag>
        ))}
      </p>
    </>
  );
};

export default NewCourse;
