import { Button } from 'antd';
import React from 'react';

export default function BottomActions({
  onSave,
  onCancel,
  validateData,
  saving,
}) {
  return (
    <div className="inm-newContent-buttonsContainer inm-newContent-onlyRight">
      <Button className="inm-newContent-cancelButton" onClick={onCancel}>
        Cancel
      </Button>
      {onSave && (
        <Button
          className="inm-newContent-saveButton"
          onClick={onSave}
          disabled={!validateData?.()}
          loading={saving}
        >
          Save and Continue
        </Button>
      )}
    </div>
  );
}
