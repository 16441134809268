import * as ApiCourses from './dataSource';
import * as ApiTranslations from '../translations/dataSource';

import {
  REQUEST_COURSES,
  SAVE_COURSE,
  SET_COURSES,
} from '../../constants/ActionTypes';

export const actionSaveCourse = (courses) => ({
  type: SAVE_COURSE,
  courses,
});

export const actionSetCourses = (courses) => ({
  type: SET_COURSES,
  courses,
});

export const actionRequestCourses = (locale) => ({
  type: REQUEST_COURSES,
  locale,
});

export async function getCourseTranslations(uuid) {
  try {
    const responseId = await ApiCourses.getCourseById(uuid);
    const responseTranslations = await ApiTranslations.getSingleTranslation(
      responseId.data.translation_id
    );
    return responseTranslations.data.translations;
  } catch (e) {
    console.error('Translation ERROR', e.response);
    return e.response;
  }
}
