import React, { useState } from 'react';
import { Select } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import ContainerHeader from '../../components/ContainerHeader';
import useRegions from '../../hooks/useRegions';
import { saveAndContinue } from '../../store/mainActions';
import BottomActions from '../../components/BottomActions';
import useCourses from '../../hooks/useCourses';

const { Option } = Select;

const NewSitemap = () => {
  const dispatch = useDispatch();
  const { regions } = useRegions();
  const [region, setRegion] = useState();
  const [courseOrder, setCourseOrder] = useState();
  const [level, setLevel] = useState();
  const validateData = () => region && courseOrder;
  const history = useHistory();
  const handleSaveAndContinue = () => {
    dispatch(saveAndContinue({ region, courseOrder, level }));
  };
  const courses = useCourses();
  const levelsList = ['DEFAULT', 'BASIC', 'MANAGER'];

  const saving = useSelector(({ sitemaps }) => sitemaps.saving);
  const sitemap = useSelector(({ sitemaps }) => sitemaps.sitemap);

  const filterByRegion = (course) => course.region === region;

  const handleChangeRegion = (value) => {
    setRegion(value);
    setCourseOrder();
    setLevel();
  };

  const handleChangeCourse = (value) => {
    setCourseOrder(+value);
    setLevel('DEFAULT');
  };

  if (sitemap) {
    return <Redirect to={`newSitemap/${sitemap.id}`} />;
  }

  return (
    <>
      <ContainerHeader title="Create sitemap" />
      <div className="inm-craete-sitemap-container">
        <div className="inm-select-container">
          Select region
          <Select
            required
            className="inm-select"
            onChange={handleChangeRegion}
            value={region}
          >
            {regions?.map((_region) => (
              <Option key={_region} value={_region}>
                {_region}
              </Option>
            ))}
          </Select>
        </div>
        <div className="inm-select-container">
          Select course
          <Select
            disabled={!region}
            required
            className="inm-select"
            onChange={handleChangeCourse}
            value={courseOrder}
          >
            {courses?.filter(filterByRegion).map((course) => (
              <Option key={course.order} value={course.order}>
                {course.order}
              </Option>
            ))}
          </Select>
        </div>
        <div className="inm-select-container">
          Select level
          <Select
            disabled={!region || !courseOrder}
            required
            className="inm-select"
            onChange={(value) => setLevel(value)}
            value={level}
          >
            {levelsList?.map((level) => (
              <Option key={level} value={level}>
                {level}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="inm-footer-offset" />
      <BottomActions
        onSave={handleSaveAndContinue}
        onCancel={() => history.push('/sitemaps/')}
        validateData={validateData}
        saving={saving}
      />
    </>
  );
};

export default NewSitemap;
