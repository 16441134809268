import axios from 'axios';
import { axiosInstance } from '../../util/axiosInstance';

export const API_HOST = process.env.REACT_APP_API_RESOURCES;

export const getTranslatedPages = async (queryParams, locale) => {
  const url = `${API_HOST}/pages/locale/${locale}${queryParams}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};

export const getPages = async (queryParams, locale) => {
  const url = `${API_HOST}/pages${queryParams}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};

export const createPage = async (data) => {
  const url = `${API_HOST}/page`;
  const response = await axiosInstance({ url, method: 'POST', data });

  return response;
};

export const getPage = async (id) => {
  const url = `${API_HOST}/page/${id}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};

export const getTranslatedPage = async (id, locale) => {
  const url = `${API_HOST}/page/${id}/locale/${locale}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};
