import * as React from 'react';
const SvgZoomin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      clipPath="url(#zoomin_svg__a)"
    >
      <path d="M15.73 8.176a7.553 7.553 0 1 1-15.107.001 7.553 7.553 0 0 1 15.106-.001M4.375 8.124h7.5M8.125 11.874v-7.5M13.518 13.516l5.857 5.858" />
    </g>
    <defs>
      <clipPath id="zoomin_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgZoomin;
