import * as React from 'react';
const SvgRegisterHugeGroup = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#register-huge-group_svg__a)"
    >
      <path
        strokeWidth={0.8}
        d="M14.957 11.818a1.222 1.222 0 1 0 2.443 0 1.222 1.222 0 0 0-2.443 0M14.143 16a2.035 2.035 0 1 1 4.071 0M10.856 11.721a1.222 1.222 0 1 0 2.444 0 1.222 1.222 0 0 0-2.444 0M8.835 6.221a1.221 1.221 0 1 0 2.443 0 1.221 1.221 0 0 0-2.443 0M12.877 6.221a1.221 1.221 0 1 0 2.442 0 1.221 1.221 0 0 0-2.442 0M10.042 15.904a2.036 2.036 0 0 1 4.071 0M8.02 10.404a2.036 2.036 0 1 1 4.072 0"
      />
      <path
        strokeWidth={0.8}
        d="M12.063 10.404a2.035 2.035 0 1 1 4.07 0M6.814 11.818a1.221 1.221 0 1 0 2.443 0 1.221 1.221 0 0 0-2.443 0M6 16a2.035 2.035 0 1 1 4.071 0"
      />
      <path
        strokeWidth={0.7}
        d="M3.366 4.598a1.453 1.453 0 0 0-1.45 1.47v8.183l1.448 2.479 1.45-2.479V6.067a1.45 1.45 0 0 0-1.448-1.469M1.916 6.302h2.898M1.916 7.483h2.898"
      />
      <path
        strokeWidth={0.7}
        d="m1.915 14.251 1.45-.582 1.448.58M3.365 7.483v6.186"
      />
    </g>
    <defs>
      <clipPath id="register-huge-group_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRegisterHugeGroup;
