import * as React from 'react';
const SvgChat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M16.867 6.667h-6.233L9.5 7.8 8.367 6.666H2.133A1.133 1.133 0 0 1 1 5.533v-3.4A1.133 1.133 0 0 1 2.133 1h14.734a1.133 1.133 0 0 1 1.134 1.133v3.4a1.134 1.134 0 0 1-1.134 1.134M1 12.916a1.983 1.983 0 1 0 3.966 0 1.983 1.983 0 0 0-3.966 0M6.11 18.962A3.4 3.4 0 0 0 1 17.54M14.067 12.916a1.983 1.983 0 1 0 3.967 0 1.983 1.983 0 0 0-3.967 0M13.235 18.962a3.4 3.4 0 0 1 5.111-1.423M6.967 12.35a2.55 2.55 0 1 0 5.1 0 2.55 2.55 0 0 0-5.1 0M13.235 19.163a4.584 4.584 0 0 0-7.124 0"
    />
  </svg>
);
export default SvgChat;
