import { SET_COURSES } from '../../constants/ActionTypes';

const INIT_STATE = {
  courses: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_COURSES: {
      return {
        ...state,
        courses: action.courses,
      };
    }
    default:
      return state;
  }
};
