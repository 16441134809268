import * as React from 'react';
const SvgExpandPlayer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.733 14.267 2 18M18 6.267V2h-4.267M2 13.733V18h4.267M18 2l-3.733 3.733M14.267 14.267 18 18M2 6.267V2h4.267M18 13.733V18h-4.267M2 2l3.733 3.733M7.333 7.333h5.334v5.334H7.333z"
    />
  </svg>
);
export default SvgExpandPlayer;
