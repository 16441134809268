import {
  GET_REGIONS_REQUEST,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_ERROR,
  SAVE_AND_CONTINUE,
  SAVE_AND_CONTINUE_SUCCESS,
  SAVE_AND_CONTINUE_ERROR,
  GET_SITEMAPS_REQUEST,
  GET_SITEMAPS_SUCCESS,
  GET_SITEMAPS_ERROR,
  TOGGLE_SITEMAP_REQUEST,
  TOGGLE_SITEMAP_SUCCESS,
  DELETE_SITEMAP_REQUEST,
  DELETE_SITEMAP_ERROR,
  DELETE_CONTACT_SUCCESS,
  TOGGLE_SITEMAP_ERROR,
  DELETE_SITEMAP_SUCCESS,
  GET_SITEMAP_SUCCESS,
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_ERROR,
  SAVE_GROUP_ITEM,
  SAVE_GROUP_ITEM_SUCCESS,
  SAVE_GROUP_ITEM_ERROR,
  SAVE_LINK_ITEM_SUCCESS,
  SAVE_LINK_ITEM_REQUEST,
  SAVE_LINK_ITEM_ERROR,
  ON_REORDER_LIST,
  ON_REORDER_LIST_SUCCESS,
  ON_REORDER_LIST_ERROR,
  GET_ALL_MENU_ITEMS,
  GET_ALL_MENU_ITEMS_SUCCESS,
  GET_ALL_MENU_ITEMS_ERROR,
  DELETE_MENU_ITEM,
  DELETE_MENU_ITEM_ERROR,
  DELETE_MENU_ITEM_SUCCESS,
  GET_ROOT_ITEM_ERROR,
  GET_ROOT_ITEM,
  GET_ROOT_ITEM_SUCCESS,
  GET_MENU_ITEM_SUCCESS,
  GET_MENU_ITEM,
  GET_MENU_ITEM_ERROR,
  CLEAR_SITEMAP,
  GET_ALL_COURSES,
  GET_ALL_COURSES_SUCCESS,
  GET_ALL_COURSES_ERROR,
  CLONE_SITEMAP,
  CLONE_SITEMAP_SUCCESS,
  CLONE_SITEMAP_ERROR,
  GET_SITEMAP_REQUEST,
  GET_SITEMAP_ERROR,
  SET_REGION_FILTER,
  SET_LANG_FILTER,
  SET_ROLE_FILTER,
  EDIT_SITEMAP,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_ERROR,
  UPDATE_ITEM_SUCCESS,
} from '../../constants/ActionTypes';

export function getRegionsRequest() {
  return {
    type: GET_REGIONS_REQUEST,
  };
}
export function getRegionsSuccess(payload) {
  return {
    type: GET_REGIONS_SUCCESS,
    payload,
  };
}

export function editSitemap(payload) {
  return {
    type: EDIT_SITEMAP,
    payload,
  };
}

export function getRegionsError(payload) {
  return {
    type: GET_REGIONS_ERROR,
    payload,
  };
}

export function saveAndContinue(payload) {
  return {
    type: SAVE_AND_CONTINUE,
    payload,
  };
}

export function saveAndContinueSuccess(payload) {
  return {
    type: SAVE_AND_CONTINUE_SUCCESS,
    payload,
  };
}

export function saveAndContinueError(payload) {
  return {
    type: SAVE_AND_CONTINUE_ERROR,
    payload,
  };
}

export function getSitemaps(payload) {
  return {
    type: GET_SITEMAPS_REQUEST,
    payload,
  };
}
export function getSitemapsSucess(payload) {
  return {
    type: GET_SITEMAPS_SUCCESS,
    payload,
  };
}
export function getSitemapsError(payload) {
  return {
    type: GET_SITEMAPS_ERROR,
    payload,
  };
}

export function toggleSitemap(payload) {
  return {
    type: TOGGLE_SITEMAP_REQUEST,
    payload,
  };
}

export function toggleSitemapSuccess(payload) {
  return {
    type: TOGGLE_SITEMAP_SUCCESS,
    payload,
  };
}

export function toggleSitemapError(payload) {
  return {
    type: TOGGLE_SITEMAP_ERROR,
    payload,
  };
}

export function deleteSitemap(payload) {
  return {
    type: DELETE_SITEMAP_REQUEST,
    payload,
  };
}

export function deleteSitemapSuccess(payload) {
  return {
    type: DELETE_SITEMAP_SUCCESS,
    payload,
  };
}

export function deleteSitemapError(payload) {
  return {
    type: DELETE_SITEMAP_ERROR,
    payload,
  };
}

export function getSitemap(payload) {
  return {
    type: GET_SITEMAP_REQUEST,
    payload,
  };
}

export function getSitemapSuccess(payload) {
  return {
    type: GET_SITEMAP_SUCCESS,
    payload,
  };
}

export function getSitemapError(payload) {
  return {
    type: GET_SITEMAP_ERROR,
    payload,
  };
}

export function getLanguages(payload) {
  return {
    type: GET_LANGUAGES,
    payload,
  };
}

export function getLanguagesSuccess(payload) {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload,
  };
}

export function getLanguagesError(payload) {
  return {
    type: GET_LANGUAGES_ERROR,
    payload,
  };
}

export function saveGroupItem(payload) {
  return {
    type: SAVE_GROUP_ITEM,
    payload,
  };
}

export function saveGroupItemSuccess(payload) {
  return {
    type: SAVE_GROUP_ITEM_SUCCESS,
    payload,
  };
}

export function saveGroupItemError(payload) {
  return {
    type: SAVE_GROUP_ITEM_ERROR,
    payload,
  };
}

export function saveLinkItem(payload) {
  return {
    type: SAVE_LINK_ITEM_REQUEST,
    payload,
  };
}

export function saveLinkItemSuccess(payload) {
  return {
    type: SAVE_LINK_ITEM_SUCCESS,
    payload,
  };
}

export function saveLinkItemError(payload) {
  return {
    type: SAVE_LINK_ITEM_ERROR,
    payload,
  };
}

export function onReorderList(payload) {
  return {
    type: ON_REORDER_LIST,
    payload,
  };
}

export function onReorderListSuccess(payload) {
  return {
    type: ON_REORDER_LIST_SUCCESS,
    payload,
  };
}

export function onReorderListError(payload) {
  return {
    type: ON_REORDER_LIST_ERROR,
    payload,
  };
}

export function getAllMenuItems(payload) {
  return {
    type: GET_ALL_MENU_ITEMS,
    payload,
  };
}

export function getAllMenuItemsSuccess(payload) {
  return {
    type: GET_ALL_MENU_ITEMS_SUCCESS,
    payload,
  };
}

export function getAllMenuItemsError(payload) {
  return {
    type: GET_ALL_MENU_ITEMS_ERROR,
    payload,
  };
}

export function getRootItem(payload) {
  return {
    type: GET_ROOT_ITEM,
    payload,
  };
}

export function getRootItemSuccess(payload) {
  return {
    type: GET_ROOT_ITEM_SUCCESS,
    payload,
  };
}

export function getRootItemError(payload) {
  return {
    type: GET_ROOT_ITEM_ERROR,
    payload,
  };
}

export function deleteMenuItem(payload) {
  return {
    type: DELETE_MENU_ITEM,
    payload,
  };
}

export function deleteMenuItemSuccess(payload) {
  return {
    type: DELETE_MENU_ITEM_SUCCESS,
    payload,
  };
}

export function deleteMenuItemError(payload) {
  return {
    type: DELETE_MENU_ITEM_ERROR,
    payload,
  };
}

export function getMenuItem(payload) {
  return {
    type: GET_MENU_ITEM,
    payload,
  };
}

export function getMenuItemSuccess(payload) {
  return {
    type: GET_MENU_ITEM_SUCCESS,
    payload,
  };
}

export function getMenuItemError(payload) {
  return {
    type: GET_MENU_ITEM_ERROR,
    payload,
  };
}

export function clearSitemap() {
  return {
    type: CLEAR_SITEMAP,
  };
}

export function getAllCourses() {
  return {
    type: GET_ALL_COURSES,
  };
}

export function getAllCoursesSuccess(payload) {
  return {
    type: GET_ALL_COURSES_SUCCESS,
    payload,
  };
}

export function getAllCoursesError(payload) {
  return {
    type: GET_ALL_COURSES_ERROR,
    payload,
  };
}

export function cloneSitemap(payload) {
  return {
    type: CLONE_SITEMAP,
    payload,
  };
}

export function cloneSitemapSuccess(payload) {
  return {
    type: CLONE_SITEMAP_SUCCESS,
    payload,
  };
}

export function cloneSitemapError(payload) {
  return {
    type: CLONE_SITEMAP_ERROR,
    payload,
  };
}

export function setRegionFilter(payload) {
  return {
    type: SET_REGION_FILTER,
    payload,
  };
}

export function setLangFilter(payload) {
  return {
    type: SET_LANG_FILTER,
    payload,
  };
}

export function setRoleFilter(payload) {
  return {
    type: SET_ROLE_FILTER,
    payload,
  };
}

export function updateItemRequest(payload) {
  return {
    type: UPDATE_ITEM_REQUEST,
    payload,
  };
}

export function updateItemSuccess(payload) {
  return {
    type: UPDATE_ITEM_SUCCESS,
    payload,
  };
}

export function updateItemError(payload) {
  return {
    type: UPDATE_ITEM_ERROR,
    payload,
  };
}
