import { SET_LISTS, SET_CURRENT_LIST } from '../../constants/ActionTypes';

const INIT_STATE = {
  lists: [],
  currentList: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_LIST: {
      return {
        ...state,
        currentList: action.list,
      };
    }
    case SET_LISTS: {
      return {
        ...state,
        lists: action.lists.lists,
        count: action.lists.count,
      };
    }
    default:
      return state;
  }
};
