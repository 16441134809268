import * as React from 'react';
const SvgArrowUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#arrow_up_svg__a)">
      <path
        fill="#333"
        d="M10 5.313a1.44 1.44 0 0 1 1.08.487l6.132 6.97a1.154 1.154 0 0 1-1.733 1.524L10.118 8.2a.156.156 0 0 0-.236 0l-5.361 6.095a1.156 1.156 0 0 1-1.924-.239 1.15 1.15 0 0 1 .19-1.285l6.13-6.967A1.44 1.44 0 0 1 10 5.313"
      />
    </g>
    <defs>
      <clipPath id="arrow_up_svg__a">
        <path fill="#fff" d="M17.5 17.5h-15v-15h15z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgArrowUp;
