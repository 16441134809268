import axios from 'axios';
import { token } from '@innovamat/core-utilites';
import { getUser } from './userStorage';
import * as userStorage from './userStorage';
import { store } from '../store';
import { userSignInSuccess } from '../store/mainActions';

export const authAxiosInstance = axios.create();
export const axiosInstance = axios.create();

authAxiosInstance.interceptors.request.use(
  async (config) => {
    const user = getUser();
    config.headers = {
      Authorization: `Bearer ${user.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

authAxiosInstance.interceptors.response.use(
  (response) => response,
  (err) => {
    const originalRequest = err.config;

    if (err.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return authAxiosInstance(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        handleRefreshUserToken()
          .then((newTokensInfo) => {
            authAxiosInstance.defaults.headers.common.Authorization = `Bearer ${newTokensInfo.access_token}`;
            originalRequest.headers.Authorization = `Bearer ${newTokensInfo.access_token}`;
            const newUserInfo =
              userStorage.defineUserDataFromTokens(newTokensInfo);
            userStorage.setUser(newUserInfo);
            store.dispatch(userSignInSuccess(newUserInfo));
            processQueue(null, newTokensInfo.access_token);
            resolve(authAxiosInstance(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            // store.dispatch(showMessage({ message: 'Expired Token' }));
            userStorage.clearUser();
            window.location.href = '/';
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

const handleRefreshUserToken = async () => {
  const { refreshToken } = getUser();
  const newTokens = await token.refreshAccessToken(refreshToken);
  return newTokens;
};
