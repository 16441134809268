import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { getItemStyle, PARENT_TYPES } from './utils';
import DeleteConfirmation from '../../components/DeleteConfirmation';

const Subitems = ({
  group,
  groupIndex,
  onDelete,
  getStyleFromFilter,
  getTranslation,
  handleRightClick,
}) => {
  const { pathname } = useLocation();

  const getUrlToEdit = (item) => {
    const url = `${pathname}/newLink/${item.id}?parentId=${item.parentMenuItemId}&parentType=${PARENT_TYPES.link}`;
    return url;
  };

  return (
    <Droppable droppableId={`droppable${group.id}`} type={`${groupIndex}`}>
      {(provided) => (
        <div className="inm-group-subitems" ref={provided.innerRef}>
          {group.items.map((item, index) => (
            <Draggable
              key={`${groupIndex}${index}`}
              draggableId={`${groupIndex}${index}`}
              index={index}
            >
              {(provided, snapshot) => (
                <div
                  onContextMenu={handleRightClick(item)}
                  className="inm-group-item"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                  {...provided.dragHandleProps}
                >
                  <MenuOutlined />
                  <span className={getStyleFromFilter(item)}>
                    {getTranslation(item)}
                  </span>

                  <div className="inm-group-actions">
                    <span />
                    <Link to={getUrlToEdit(item)}>
                      <EditOutlined />
                    </Link>
                    <DeleteConfirmation onConfirm={() => onDelete(item)} />
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Subitems;
