import axios from 'axios';
// import { getCurrentUser } from "../utils";
// import { refreshUser } from "./apiMiddleware";

const APOLLO_SERVER = process.env.REACT_APP_APOLLO_SERVER;

const apolloFetch = async ({ graphQl }) => {
  const response = await axios({
    url: `${APOLLO_SERVER}`,
    method: 'POST',
    data: JSON.parse(graphQl),
  }).then(async (res) => {
    const errors = res.data?.errors;
    if (errors) {
      // if (
      //   errors.some(
      //     (error) =>
      //       error.extensions?.response?.body.type === "auth.expired_jwt"
      //   )
      // ) {
      //   // TODO Throw error and interceptate by axios interceptor
      //   const currentUser = getCurrentUser();
      //   await refreshUser(currentUser);
      //   return await axios({
      //     url: `${APOLLO_SERVER}`,
      //     method: "POST",
      //     data: JSON.parse(graphQl),
      //   });
      // }
    }
    return res;
  });
  return response.data;
};

export default apolloFetch;
