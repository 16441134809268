import React, { useEffect, useState } from 'react';
import { Modal, Table, Tag } from 'antd';
import {
  FilterOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  capitalizeFirst,
  getTimeAgo,
  handleCopyClipboard,
} from '../../util/helpers';
import {
  actionSetPagination,
  actionGetLists,
  actionGetList,
  actionSetCurrentList,
  actionGetResource,
} from '../../store/mainActions';

import ContainerHeader from '../../components/ContainerHeader';

import ListPreview from '../../components/ListPreview';

const columns = (handleOpenPreview, handleEditList) => [
  {
    title: 'Title',
    dataIndex: 'name',
    width: '40%',
    // sorter: (a, b) => b.title.localeCompare(a.title),
    render: (title) =>
      title && <div dangerouslySetInnerHTML={{ __html: title }} />,
  },
  {
    title: 'Identifier',
    dataIndex: 'uuid',
    width: '30%',
    render: (id) => (
      <span
        onClick={() => handleCopyClipboard(id)}
        style={{ cursor: 'pointer' }}
      >
        {id}
      </span>
    ),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '20%',
    render: (actions, all) => (
      <div style={{ display: 'flex' }}>
        <div style={{ textAlign: 'center', padding: '0 5px' }}>
          <EyeOutlined onClick={() => handleOpenPreview(all)} />
        </div>
        <div style={{ textAlign: 'center', padding: '0 5px' }}>
          <EditOutlined onClick={() => handleEditList(all)} />
        </div>
      </div>
    ),
  },
];

const Lists = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lists = useSelector(({ lists }) => lists.lists);
  const listsCount = useSelector(({ lists }) => lists.count);
  const loading = useSelector(({ common }) => common.loading);
  const success = useSelector(({ common }) => common.success);
  const pagination = useSelector(
    ({ paginationFilters }) => paginationFilters.lists.pagination
  );
  const filters = useSelector(
    ({ paginationFilters }) => paginationFilters.lists.filters
  );

  const [previewVisible, setPreviewVisible] = useState(false);
  const [waitEdit, setWaitEdit] = useState(false);

  useEffect(() => {
    dispatch(actionGetLists());
  }, [filters, pagination]);

  useEffect(() => {
    if (waitEdit && !loading && success) {
      setWaitEdit(false);
      history.push('/newContent');
    } else if (waitEdit && !loading && !success) {
      setWaitEdit(false);
    }
  }, [loading, success]);

  const handleDeleteContent = (e, id) => {
    // TODO: DELETE CONTENT
    e.stopPropagation();
    // dispatch(actionDeleteList(id));
  };

  const handleEditList = (list) => {
    setWaitEdit(true);
    dispatch(actionGetList(list));
  };

  const handlePagination = (newPage, newPageSize) => {
    const _pagination = {
      offset: (newPage - 1) * newPageSize,
      length: newPageSize,
    };
    dispatch(actionSetPagination('lists', _pagination));
  };

  const paginationSettings =
    listsCount > 50
      ? {
          total: listsCount,
          pageSize: pagination.length,
          current: pagination.offset / pagination.length + 1,
          onChange: (newPage, newPageSize) =>
            handlePagination(newPage, newPageSize),
          position: ['bottomCenter'],
          showTotal: () => `Total ${listsCount} items`,
          size: 'small',
          showSizeChanger: false,
        }
      : false;

  const handleOpenPreview = (list) => {
    setPreviewVisible(true);
    dispatch(actionGetList(list));
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
    dispatch(actionSetCurrentList({}));
  };

  return (
    <>
      <ContainerHeader title="Lists" />
      {/* <div className={styles.filterContainer}>
        <Input
          placeholder="Filter"
          suffix={<FilterOutlined />}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div> */}
      <Table
        className="gx-table-no-bordered"
        columns={columns(handleOpenPreview, handleEditList)}
        dataSource={lists}
        pagination={paginationSettings}
        bordered={false}
        rowKey={(record) => record.uuid}
        size="medium"
        loading={loading}
      />
      <Modal
        visible={previewVisible}
        closable
        onCancel={handleClosePreview}
        footer={null}
        width="90%"
        style={{ height: '80%' }}
        bodyStyle={{ height: '100%' }}
        wrapClassName="inm-preview-wrapModal"
      >
        <ListPreview />
      </Modal>
    </>
  );
};

export default Lists;
