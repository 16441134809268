export const rolesMapper = (_roles) => {
  const rolesToAdd = [
    'ROLE_ADVISOR',
    'ROLE_CONTENT_ADMIN',
    'ROLE_DISTRICT_ADMIN',
  ];
  if (!_roles.includes('ROLE_TEACHER'))
    return _roles.filter((role) => !rolesToAdd.includes(role));

  const roles = [..._roles, ...rolesToAdd];
  return [...new Set(roles)];
};
