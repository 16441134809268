import * as React from 'react';
const SvgGeometry = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M.625 10 5 1.875h10.625L19.375 10l-3.75 8.125H5z"
      />
      <path
        strokeWidth={1.249995}
        d="M13.125 5.625 5.625 10l7.5 3.75zM15.625 1.875l-2.5 3.75L19.375 10l-6.25 3.75 2.5 4.375"
      />
      <path
        strokeWidth={1.249995}
        d="M13.125 5.625 5 1.875 5.625 10h-5M5.625 10 5 18.125l8.125-4.375"
      />
    </g>
  </svg>
);
export default SvgGeometry;
