import * as React from 'react';
const SvgAddImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g stroke="#757575" strokeWidth={1.25} clipPath="url(#add-image_svg__a)">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13.938 18.438a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9M13.938 11.688v4.5M11.688 13.938h4.5"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.188 16.188h-4.5a1.125 1.125 0 0 1-1.126-1.125V2.688a1.125 1.125 0 0 1 1.126-1.126h7.971c.298 0 .584.119.795.33l2.154 2.154c.211.21.33.497.33.795v2.347"
      />
      <path d="M4.938 5.781a.281.281 0 0 1 0-.562M4.938 5.781a.281.281 0 0 0 0-.562" />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m10.21 8.425-.773-1.238a.562.562 0 0 0-.928-.01l-1.5 2.138-.924-.74a.56.56 0 0 0-.82.127l-1.452 2.985h3.375"
      />
    </g>
    <defs>
      <clipPath id="add-image_svg__a">
        <path fill="#fff" d="M1 1h18v18H1z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAddImage;
