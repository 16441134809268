import { push } from 'connected-react-router';

import { all, fork, put, select, takeLatest } from 'redux-saga/effects';

import {
  DELETE_RESOURCE,
  GET_RESOURCES,
  SAVE_RESOURCE,
} from 'constants/ActionTypes';

import { message } from 'antd';
import * as resourceApi from './dataSource';
import { requestTranslations } from '../translations/actions';
import { getTagTranslations } from '../tags/actions';
import {
  actionGetResources,
  actionSetCurrentResource,
  actionSetResources,
} from './actions';
import {
  createObject,
  fetchError,
  fetchStart,
  fetchSuccess,
} from '../mainActions';
import { getSingleTranslation } from '../translations/dataSource';
import {
  DOWNLOADABLE_TAG,
  getQueryParams,
  GUIDE_TAG,
  PRINTABLE_TAG,
  PROJECTABLE_TAG,
  throttleRequests,
} from '../../util/helpers';
import {
  DELETE_RESOURCES,
  GET_RESOURCE,
  UPDATE_RESOURCE,
} from '../../constants/ActionTypes';
import { defaultLanguage } from '../../util/languageList';

const getPagination = (state) => state.paginationFilters.resources.pagination;
const getFilters = (state) => state.paginationFilters.resources.filters;
const getLocale = (state) => state.settings.language || defaultLanguage;

function* runGetResources() {
  yield put(fetchStart());
  try {
    const pagination = yield select(getPagination);
    const filters = yield select(getFilters);
    const queryParams = getQueryParams(pagination, filters);
    const locale = yield select(getLocale);
    const res = yield resourceApi.getTranslatedResources(queryParams, locale);
    const { resources, count } = res.data;
    yield put(actionSetResources({ resources, count }));
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

function* updateSuggestedLists(uuid, suggestedLists) {
  const data = {
    suggested_resource_lists: suggestedLists.map((list, index) => ({
      resource_list_id: list.uuid,
      order: index,
    })),
  };
  yield resourceApi.updateSuggestedLists(uuid, data);
}

function* runSaveResource(payload) {
  const {
    data: { translations, type, uuid, suggestedLists },
    data,
  } = payload;
  yield put(fetchStart());
  try {
    const translationsIds = yield requestTranslations(translations, type);
    const resourceObject = yield createObject(data, translationsIds, type);
    const suggestedListsArray = suggestedLists[Object.keys(suggestedLists)[0]];

    let resourceId;
    if (uuid) {
      yield resourceApi.updateResource(resourceObject, type, uuid);
      resourceId = uuid;
    } else {
      const createResponse = yield resourceApi.createResource(
        resourceObject,
        type
      );
      resourceId = createResponse.data.uuid;
    }

    // Add SuggestedLists
    if (type === 'session') {
      yield updateSuggestedLists(resourceId, suggestedListsArray);
    }
    yield put(actionGetResources());
    yield put(fetchSuccess());
    if (type === 'resourceList') {
      yield put(push('/lists'));
    } else if (type === 'page') {
      yield put(push('/pages'));
    } else {
      yield put(push('/resources'));
    }
  } catch (e) {
    yield put(fetchError(e));
    console.error('Save Resource ERROR', e.response);
  }
}

function* runDeleteResource({ resourceId }) {
  yield put(fetchStart());
  try {
    yield resourceApi.deleteResource(resourceId);
    yield put(actionGetResources());
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

function* runDeleteResources({ resourcesIds }) {
  yield put(fetchStart());
  try {
    // TODO: Provisional, use single multiple delete when available
    const requestsToMake = resourcesIds.map((resourceId) => async () => {
      try {
        await resourceApi.deleteResource(resourceId);
      } catch (e) {
        message.error(`Failted to delete resource with id ${resourceId}`);
        console.error(e);
      }
    });
    yield throttleRequests(requestsToMake, 6);
    yield put(actionGetResources());
    yield put(fetchSuccess());
  } catch (e) {
    console.error(e);
    yield put(fetchError(e));
  }
}

function* getFile(language, type, fileId) {
  let file = {
    language,
  };
  try {
    const res = yield resourceApi.getFile(type, fileId);
    file = { ...file, ...res.data };
  } catch (e) {
    yield put(fetchError(e));
  }
  return file;
}

function* runGetResource({ resource }) {
  yield put(fetchStart());

  try {
    const resourceResponse = yield resourceApi.getResourceByType(
      resource.type,
      resource.uuid
    );

    const currentResource = { ...resourceResponse.data };
    const { files, type, thumbnails, name_id, description_id } =
      currentResource;

    const thumbnailsArray = Object.entries(thumbnails);
    const allThumbnails = yield thumbnails &&
      resourceApi.getThumbnails(thumbnailsArray);

    let _files = [];
    try {
      _files = yield all(
        Object.keys(files).map((language) =>
          getFile(language, type, files[language])
        )
      );
    } catch (e) {
      console.error(e);
    }
    currentResource.translations = { ...(currentResource.translations || {}) };

    yield all(
      _files.map(async (_file) => {
        currentResource.translations[_file.language] =
          currentResource.translations[_file.language] || {};
        currentResource.translations[_file.language] = {
          ...currentResource.translations[_file.language],
          fragments:
            _file.fragments &&
            (await Promise.all(
              _file.fragments.map(async (fragment, index) => {
                let thumbnail_url;
                try {
                  const thumbnailResponse =
                    fragment.thumbnail_id &&
                    (await resourceApi.getThumbnail(fragment.thumbnail_id));
                  thumbnail_url = thumbnailResponse?.data.href;
                } catch (e) {
                  console.error(e);
                }
                return {
                  ...fragment,
                  thumbnail_url,
                  order: fragment.order || index + 1,
                };
              })
            )),
          fileId: _file.uuid,
          fileUrl: _file.href,
          fileName: _file.original_file_name,
          videoUrl: _file.href,
        };
      })
    );

    const getThumbnail = (locale) => {
      const thumbnail = allThumbnails.find(
        (thumbnail) => thumbnail.language === locale
      );
      return {
        thumbnailId: thumbnail?.uuid,
        thumbnailUrl: thumbnail?.href,
      };
    };

    try {
      const nameTranslations = yield getSingleTranslation(name_id);
      nameTranslations.data.translations.forEach((translation) => {
        currentResource.translations[translation.locale] = {
          ...currentResource.translations[translation.locale],
          title: translation.translation,
          ...getThumbnail(translation.locale),
        };
      });
    } catch (e) {
      console.error(e);
    }

    try {
      const descriptionTranslations = yield getSingleTranslation(
        description_id
      );
      descriptionTranslations.data.translations.forEach((translation) => {
        currentResource.translations[translation.locale] = {
          ...currentResource.translations[translation.locale],
          description: translation.translation,
        };
      });
    } catch (e) {
      console.error(e);
    }

    const tagTranslations = yield all(
      currentResource.tags.map((tag) => getTagTranslations(tag.uuid))
    );
    currentResource.tags.translations = tagTranslations;

    currentResource.isPublic = !!currentResource.is_public;

    if (type === 'session') {
      const { code, is_completable } = currentResource;
      currentResource.sessionCode = code;
      currentResource.isCompletable = is_completable;

      const sessionBlocks = {
        1: [],
        2: [],
        3: [],
      };
      currentResource.sessionWithoutPlayer = !currentResource.has_player;
      try {
        yield all(
          Object.keys(sessionBlocks).map(async (block) =>
            Promise.all(
              currentResource.resource_blocks?.[
                block - 1
              ]?.ordered_resources?.map(async (r) => {
                const requestsToMake = Object.keys(
                  currentResource.translations
                ).map((language) => async () => {
                  try {
                    const res = await resourceApi
                      .getTranslatedResource(r.resource_id, language)
                      .then((res) => res.data);
                    currentResource.translations[language].thumbnailId =
                      allThumbnails.find(
                        (thumbnail) => thumbnail.language === language
                      ).uuid;
                    currentResource.translations[language].thumbnailUrl =
                      allThumbnails.find(
                        (thumbnail) => thumbnail.language === language
                      ).href;
                    currentResource.translations[language].resources =
                      currentResource.translations[language].resources || {};
                    currentResource.translations[language].resources[block] =
                      currentResource.translations[language].resources[block] ||
                      [];
                    currentResource.translations[language].resources[
                      block
                    ].splice(r.order, 0, { ...res, order: r.order });
                    currentResource.translations[language].resources[
                      block
                    ].sort((a, b) => a.order - b.order);
                    return res;
                  } catch (e) {
                    console.error(e);
                    return null;
                  }
                });
                const req = await throttleRequests(requestsToMake, 1);
                return req;
              })
            )
          )
        );
        sessionBlocks[1] =
          currentResource.translations[defaultLanguage]?.resources?.[1] || [];
        sessionBlocks[2] =
          currentResource.translations[defaultLanguage]?.resources?.[2] || [];
        sessionBlocks[3] =
          currentResource.translations[defaultLanguage]?.resources?.[3] || [];
      } catch (e) {
        console.error('Error getting session resources');
        console.error(e);
      }
      currentResource.sessionBlocks = sessionBlocks;

      try {
        const guide = currentResource.resource_blocks[3];
        const digitalGuide = currentResource.digital_guide;
        const guideId = guide.ordered_resources?.[0]?.resource_id;
        currentResource.guide = {};

        if (digitalGuide) {
          const res = yield resourceApi.getPrepareGuide(digitalGuide.id);
          const content = {
            uuid: res.id,
            name: res.title['es-ES'].body,
            type: 'digitalGuide',
          };
          currentResource.guide[1] = [content];
        } else if (guideId) {
          yield all(
            Object.keys(currentResource.translations).map(
              async (language) =>
                (currentResource.translations[language].guide =
                  await resourceApi
                    .getTranslatedResource(guideId, language)
                    .then((res) => res.data))
            )
          );
          currentResource.guide[1] = currentResource.translations[
            defaultLanguage
          ].guide
            ? [currentResource.translations[defaultLanguage].guide]
            : [];
        } else {
          currentResource.guide[1] = [];
        }
      } catch (e) {
        console.error('Error getting guide');
        console.error(e);
      }

      try {
        const printablesPosition = currentResource.resource_blocks.findIndex(
          (block) => block.name === 'printables'
        );
        if (
          currentResource.resource_blocks?.[printablesPosition]
            ?.ordered_resources
        ) {
          yield all(
            Object.keys(currentResource.translations).map(async (language) => {
              const orderedResources =
                currentResource.resource_blocks?.[printablesPosition]
                  ?.ordered_resources;

              const allPrintables = await Promise.all(
                orderedResources.map(async (printable) =>
                  resourceApi
                    .getTranslatedResource(printable.resource_id, language)
                    .then((res) => res.data)
                )
              );

              currentResource.translations[language].printables =
                orderedResources.map((printable) =>
                  allPrintables.find((p) => p.uuid === printable.resource_id)
                );
            })
          );
        }

        currentResource.printables = {};
        currentResource.printables[1] =
          currentResource.translations[defaultLanguage].printables || [];
      } catch (e) {
        console.error('Error getting printables');
        console.error(e);
      }

      try {
        yield all(
          Object.keys(currentResource.translations).map(async (language) =>
            Promise.all(
              currentResource.suggested_resource_lists.map(async (list) => {
                try {
                  const res = await resourceApi
                    .getTranslatedResourceList(list, language)
                    .then((res) => res.data);
                  currentResource.translations[language].suggestedLists =
                    currentResource.translations[language].suggestedLists || [];
                  currentResource.translations[language].suggestedLists.push(
                    res
                  );
                } catch (e) {
                  console.error(e);
                  return null;
                }
              })
            )
          )
        );

        currentResource.suggestedLists = {};
        currentResource.suggestedLists[1] =
          currentResource.translations[defaultLanguage].suggestedLists || [];
      } catch (e) {
        console.error(e);
      }
    }

    if (type === 'pdf' || type === 'vimeo') {
      currentResource.printableIds = currentResource.printables.join(', ');
    }

    currentResource.isPrintable = !!currentResource.tags.find(
      (tag) => tag.uuid === PRINTABLE_TAG
    );
    currentResource.isGuide = !!currentResource.tags.find(
      (tag) => tag.uuid === GUIDE_TAG
    );
    currentResource.isDownloadable = !!currentResource.tags.find(
      (tag) => tag.uuid === DOWNLOADABLE_TAG
    );
    currentResource.isProjectable = !!currentResource.tags.find(
      (tag) => tag.uuid === PROJECTABLE_TAG
    );

    yield put(actionSetCurrentResource(currentResource));
    yield put(fetchSuccess());
  } catch (e) {
    console.error(e);
    yield put(fetchError(e));
  }
}

const watcher = () =>
  function* watch() {
    yield takeLatest(SAVE_RESOURCE, runSaveResource);
    yield takeLatest(UPDATE_RESOURCE, runSaveResource);
    yield takeLatest(DELETE_RESOURCE, runDeleteResource);
    yield takeLatest(DELETE_RESOURCES, runDeleteResources);
    yield takeLatest(GET_RESOURCES, runGetResources);
    yield takeLatest(GET_RESOURCE, runGetResource);
  };

export default function* resourcesSaga() {
  yield all([fork(watcher())]);
}
