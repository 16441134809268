import * as React from 'react';
const SvgResetTests = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g clipPath="url(#Reset-tests_svg__a)">
      <path
        stroke="#757575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.25}
        d="M15.859 12.933h2.93V9.417m-.148 3.516a4.688 4.688 0 1 0-.875 4.098M7.07 16.448H2.382a1.17 1.17 0 0 1-1.172-1.172V2.385a1.17 1.17 0 0 1 1.172-1.172h8.304a1.17 1.17 0 0 1 .829.343l2.243 2.243a1.17 1.17 0 0 1 .343.829v2.445M3.555 4.73v3.516m7.031-3.516v3.516M6.484 4.73h1.172s.586 0 .586.586V7.66s0 .586-.586.586H6.484s-.586 0-.586-.586V5.316s0-.586.586-.586m-2.343 5.859h1.172s.586 0 .586.586v2.344s0 .586-.586.586H4.141s-.586 0-.586-.586v-2.344s0-.586.586-.586"
      />
    </g>
    <defs>
      <clipPath id="Reset-tests_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgResetTests;
