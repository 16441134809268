import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import SearchDragCRUD from '../../../../components/SearchDragCRUD';
import {
  setValidation,
  actionSetFilters,
  actionSetPagination,
  actionGetLists,
  actionSetLists,
} from '../../../../store/mainActions';

import * as listsApi from '../../../../store/lists/dataSource';
import { getSingleTranslation } from '../../../../store/translations/dataSource';
import { defaultLanguage } from '../../../../util/languageList';

const PageComposer = (props) => {
  const {
    contentTypes,
    type,
    handleChangeInput,
    pageBlock,
    isPreview,
    tagsList,
  } = props;

  const allResults = useSelector(({ lists }) => lists.lists);
  // const loading = useSelector(({ common }) => common.loading);
  const filters = useSelector(
    ({ paginationFilters }) => paginationFilters.lists.filters
  );

  const [pageLists, setPageLists] = useState({ 1: [] });
  const [searchName, setSearchName] = useState('');
  const [searchID, setSearchID] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchResult, setSearchResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const handleValidation = useCallback(
    (isValid) => dispatch(setValidation(isValid)),
    []
  );

  const addListToPage = (resource, block) => {
    setPageLists({
      ...pageLists,
      [block]: [...pageLists[block], resource],
    });
  };

  const removeListFromPage = (index, block) => {
    setPageLists({
      ...pageLists,
      [block]: pageLists[block].filter((_, i) => i !== index),
    });
  };

  const getPageListsData = () => {
    let totalResources = 0;
    let totalDuration = 0;
    if (Object.keys(pageLists)) {
      for (let i = 1; i <= Object.keys(pageLists).length; i++) {
        pageLists[i].forEach((list) => {
          totalResources++;
          totalDuration += list.duration;
        });
      }
    }
    return { totalResources, totalDuration };
  };

  const pageListsData = getPageListsData();

  useEffect(() => {
    if (pageListsData.totalResources >= 1) handleValidation(true);
    else handleValidation(false);
  }, [pageListsData]);

  useEffect(() => {
    setPageLists({ 1: pageBlock });
    setSearchName('');
    setSearchID('');
    setSearchType('');
    setSearchResult({});
    setError(false);
    dispatch(actionSetFilters('lists', {}));
    dispatch(actionSetLists({ lists: [], count: 0 }));
  }, []);

  useEffect(() => {
    if (searchType || searchName || searchID) dispatch(actionGetLists());
    else dispatch(actionSetLists({ lists: [], count: 0 }));
  }, [filters]);

  useEffect(() => {
    dispatch(actionSetPagination('lists', { offset: '', length: '' }));
    return () => {
      dispatch(actionSetFilters('lists', {}));
      dispatch(actionSetPagination('lists', { offset: 0, length: 50 }));
    };
  }, []);

  useEffect(() => {
    handleChangeInput('pageBlock', pageLists[1]);
  }, [pageLists]);

  const getPageLists = () => {
    const list = [];
    Object.keys(pageLists).forEach((block) => list.push(...pageLists[block]));
    return list;
  };

  const handleSearchList = async () => {
    setLoading(true);
    setError(false);
    if (searchID) {
      let res;
      try {
        res = await listsApi.getList(searchID); // TODO get translated list
        res = res.data;
        const translatedName = await getSingleTranslation(res.name_id);
        res = {
          ...res,
          name: translatedName.data.translations?.find((t) => t.locale === defaultLanguage)
            ?.translation,
        };
      } catch (e) {
        console.error(e);
        setError(true);
      }

      setSearchResult(res);
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      source.droppableId === 'search'
    ) {
      return;
    }

    const sourceBlockIndex = source.droppableId;
    const destinationBlockIndex = destination.droppableId;
    const destinationBlock = cloneDeep(pageLists[destinationBlockIndex]);

    if (sourceBlockIndex === 'search') {
      const list =
        searchResult || allResults.find((e) => e.uuid === draggableId);
      list && addListToPage(list, destinationBlockIndex);
      return;
    }
    if (destinationBlockIndex === 'search') {
      const list =
        searchResult ||
        pageLists[sourceBlockIndex].find((e) => e.uuid === draggableId);
      list && removeListFromPage(list, sourceBlockIndex);
      return;
    }
    const sourceBlock = cloneDeep(pageLists[sourceBlockIndex]);
    const resource = cloneDeep(pageLists[sourceBlockIndex][source.index]);

    if (sourceBlockIndex === destinationBlockIndex) {
      sourceBlock.splice(source.index, 1);
      sourceBlock.splice(destination.index, 0, resource);
      setPageLists({
        ...pageLists,
        [sourceBlockIndex]: sourceBlock,
      });
    } else {
      sourceBlock.splice(source.index, 1);
      destinationBlock.splice(destination.index, 0, resource);
      setPageLists({
        ...pageLists,
        [sourceBlockIndex]: sourceBlock,
        [destinationBlockIndex]: destinationBlock,
      });
    }
  };

  const getContentTypes = () =>
    contentTypes.filter((content) => content.value === 'resourceList');

  return (
    <SearchDragCRUD
      type={type}
      onDragEnd={onDragEnd}
      searchID={searchID}
      setSearchID={setSearchID}
      searchType={searchType}
      getContentTypes={getContentTypes}
      setSearchType={setSearchType}
      error={error}
      handleSearchElement={handleSearchList}
      loading={loading}
      searchResult={searchResult}
      allResults={allResults}
      getAllElementsList={getPageLists}
      tagsList={tagsList}
      addElement={addListToPage}
      elementsData={pageListsData}
      isPreview={isPreview}
      handleChangeInput={handleChangeInput}
      addedElements={pageLists}
      removeElement={removeListFromPage}
    />
  );
};

export default PageComposer;
