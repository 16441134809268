import { all, fork, put, select, takeLatest } from 'redux-saga/effects';
import { GET_PAGE, GET_TRANSLATED_PAGES } from '../../constants/ActionTypes';
import {
  actionSetCurrentPage,
  actionSetPages,
  fetchError,
  fetchStart,
  fetchSuccess,
  getCourseTranslations,
  getTabTranslations,
} from '../mainActions';
import { getQueryParams } from '../../util/helpers';
import * as pagesApi from './dataSource';
import * as listsApi from '../lists/dataSource';
import { getSingleTranslation } from '../translations/dataSource';
import { defaultLanguage } from '../../util/languageList';

const getPagination = (state) => state.paginationFilters.pages.pagination;
const getFilters = (state) => state.paginationFilters.pages.filters;
const getLocale = (state) => state.settings.language || defaultLanguage; // TODO: settings.language

function* runGetTranslatedPages({ locale }) {
  yield put(fetchStart());
  try {
    const pagination = yield select(getPagination);
    const filters = yield select(getFilters);
    const _locale = yield locale || select(getLocale);
    const queryParams = getQueryParams(pagination, filters);

    // const response = yield pagesApi.getTranslatedPages(queryParams, _locale);
    const response = yield pagesApi.getPages(queryParams, _locale);
    const { pages, count } = response.data;
    yield all(
      pages.map(async (page) => {
        try {
          const course = await getCourseTranslations(page.course_id);
          page.course = course[0].translation;
        } catch (e) {
          console.error(e);
        }
        try {
          const tab = await getTabTranslations(page.tab_id);
          page.tab = tab[0].translation;
        } catch (e) {
          console.error(e);
        }
        try {
          const translation = await getSingleTranslation(page.name_id);
          page.name = translation.data.translations[0].translation;
          return translation.data.translations[0].translation;
        } catch (e) {
          console.error(e);
        }
      })
    );

    yield put(actionSetPages({ pages, count }));
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

function* runGetPage({ page }) {
  yield put(fetchStart());

  try {
    const pageResponse = yield pagesApi.getPage(page.uuid);
    const currentPage = pageResponse.data;
    const { name_id, description_id, tab_id, course_id } = currentPage;

    currentPage.type = 'page';
    currentPage.translations = currentPage.translations || {};

    try {
      const nameTranslations = yield getSingleTranslation(name_id);
      nameTranslations.data.translations.forEach((translation) => {
        currentPage.translations[translation.locale] = {
          ...currentPage.translations[translation.locale],
          title: translation.translation,
        };
      });
    } catch (e) {
      console.error(e);
    }

    try {
      const descriptionTranslations = yield getSingleTranslation(
        description_id
      );
      descriptionTranslations.data.translations.forEach((translation) => {
        currentPage.translations[translation.locale] = {
          ...currentPage.translations[translation.locale],
          description: translation.translation,
        };
      });
    } catch (e) {
      console.error(e);
    }

    try {
      yield all(
        currentPage.ordered_lists.map(async (_list) => {
          const listsResponse = await listsApi.getList(_list.resource_list_id);
          const list = listsResponse.data;
          list.translations = {};
          const listNameTranslations = await getSingleTranslation(list.name_id);
          listNameTranslations.data.translations.forEach((language) => {
            currentPage.translations[language.locale] =
              currentPage.translations[language.locale] || {};
            currentPage.translations[language.locale].lists =
              currentPage.translations[language.locale].lists || [];
            currentPage.translations[language.locale].lists.splice(
              _list.order,
              0,
              {
                name: language.translation,
                uuid: list.uuid,
                order: _list.order,
              }
            );
            currentPage.translations[language.locale].lists.sort(
              (a, b) => a.order - b.order
            );
          });
          return list;
        })
      );
    } catch (e) {
      console.error(e);
    }

    currentPage.pageBlock =
      currentPage.translations[defaultLanguage]?.lists.sort((a, b) => a.order - b.order) ||
      [];

    currentPage.tabs = [tab_id];
    currentPage.courses = [course_id];

    yield put(actionSetCurrentPage(currentPage));
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

const watcher = () =>
  function* watch() {
    yield takeLatest(GET_TRANSLATED_PAGES, runGetTranslatedPages);
    yield takeLatest(GET_PAGE, runGetPage);
  };

export default function* pagesSagas() {
  yield all([fork(watcher())]);
}
