import * as React from 'react';
const SvgPlayClass = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M19.375 7.5v10.625a1.25 1.25 0 0 1-1.25 1.25H1.875a1.25 1.25 0 0 1-1.25-1.25V7.5M19.375 5H.625V1.875a1.25 1.25 0 0 1 1.25-1.25h16.25a1.25 1.25 0 0 1 1.25 1.25zM6.25 5 7.5.625M12.5 5 13.75.625"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.907 15.55a.713.713 0 0 1-1.032-.638v-4.824a.713.713 0 0 1 1.032-.638l4.824 2.413a.713.713 0 0 1 0 1.274z"
    />
  </svg>
);
export default SvgPlayClass;
