import { all, fork, put, select, takeLatest } from 'redux-saga/effects';
import { GET_LIST, GET_LISTS } from '../../constants/ActionTypes';
import {
  actionSetCurrentList,
  actionSetCurrentResource,
  actionSetLists,
  fetchError,
  fetchStart,
  fetchSuccess,
  getTagTranslations,
} from '../mainActions';
import { getQueryParams, throttleRequests } from '../../util/helpers';
import * as listsApi from './dataSource';
import * as resourcesApi from '../resources/dataSource';
import { getSingleTranslation } from '../translations/dataSource';
import { defaultLanguage } from '../../util/languageList';

const getPagination = (state) => state.paginationFilters.lists.pagination;
const getFilters = (state) => state.paginationFilters.lists.filters;
const getLocale = (state) => state.settings.language || defaultLanguage; // TODO: settings.language

function* runGetLists() {
  yield put(fetchStart());
  try {
    const pagination = yield select(getPagination);
    const filters = yield select(getFilters);
    const locale = yield select(getLocale);
    const queryParams = getQueryParams(pagination, filters, locale);

    const response = yield listsApi.getLists(queryParams);
    const { resource_lists, count } = response.data;
    // const resourceList = yield all(resource_lists.map((list) => listsApi.getList(list.uuid).then((res) => res.data)));
    yield put(actionSetLists({ lists: resource_lists, count }));
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

function* runGetList({ list }) {
  yield put(fetchStart());

  try {
    const listResponse = yield listsApi.getList(list.uuid);
    const currentList = listResponse.data;
    const {
      name_id,
      is_preview,
      page_id,
      description_id,
      not_automatically_relatable,
      list_type,
    } = currentList;

    currentList.type = 'resourceList';
    currentList.translations = currentList.translations || {};

    try {
      const nameTranslations = yield getSingleTranslation(name_id);
      nameTranslations.data.translations.forEach((translation) => {
        currentList.translations[translation.locale] = {
          ...currentList.translations[translation.locale],
          title: translation.translation,
        };
      });
    } catch (e) {
      console.error(e);
    }

    try {
      const descTranslations = yield getSingleTranslation(description_id);
      descTranslations.data.translations.forEach((translation) => {
        currentList.translations[translation.locale] = {
          ...currentList.translations[translation.locale],
          description: translation.translation,
        };
      });
    } catch (e) {
      console.error(e);
    }

    const listBlock = [];
    try {
      const requestsToMake = currentList.ordered_resources.map(
        (resource) => async () => {
          const _requestsToMake = Object.keys(currentList.translations).map(
            (language) => async () => {
              let resourceResponse = {};
              try {
                resourceResponse = await resourcesApi.getTranslatedResource(
                  resource.resource_id,
                  language
                );
                resourceResponse = resourceResponse.data;
              } catch (e) {
                console.error(e);
              }
              return {
                locale: language,
                order: resource.order,
                ...resourceResponse,
              };
            }
          );
          const req = await throttleRequests(_requestsToMake, 2);
          return req;
        }
      );

      const resources = yield throttleRequests(requestsToMake, 2);

      resources.forEach((resource) => {
        resource.forEach((r) => {
          currentList.translations[r.locale] =
            currentList.translations[r.locale] || {};
          currentList.translations[r.locale].resources =
            currentList.translations[r.locale].resources || [];
          currentList.translations[r.locale].resources.splice(
            resource.order,
            0,
            r
          );
          currentList.translations[r.locale].resources.sort(
            (a, b) => a.order - b.order
          );
        });
        const resourceListBlock =
          resource.find((r) => r.locale === defaultLanguage) || resource[0];
        listBlock.push(resourceListBlock);
      });
    } catch (e) {
      console.error(e);
    }

    currentList.listBlock = listBlock.sort((a, b) => a.order - b.order);
    currentList.isPreview = is_preview;
    currentList.pageId = page_id;
    currentList.notAutomaticallyRelatable = not_automatically_relatable;
    currentList.list_type = list_type === 'default' ? null : list_type;

    yield put(actionSetCurrentList(currentList));
    yield put(fetchSuccess());
  } catch (e) {
    yield put(fetchError(e));
  }
}

const watcher = () =>
  function* watch() {
    yield takeLatest(GET_LISTS, runGetLists);
    yield takeLatest(GET_LIST, runGetList);
  };

export default function* listsSagas() {
  yield all([fork(watcher())]);
}
