import * as React from 'react';
const SvgMultipleUsers = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M6.77 3.438a2.813 2.813 0 1 0 5.626 0 2.813 2.813 0 1 0-5.625 0M12.96 8.75a5.023 5.023 0 0 0-6.754 0"
      />
      <path
        strokeWidth={1.249995}
        d="M12.396 3.53a5.6 5.6 0 0 1-1.563.22 5.6 5.6 0 0 1-3.806-1.484M12.604 13.438a2.813 2.813 0 1 0 5.625 0 2.813 2.813 0 1 0-5.625 0M19.375 19.375a5.022 5.022 0 0 0-7.917 0"
      />
      <path
        strokeWidth={1.249995}
        d="M18.23 13.53a5.63 5.63 0 0 1-5.37-1.264M1.77 13.438a2.813 2.813 0 1 0 5.626 0 2.813 2.813 0 1 0-5.625 0M8.54 19.375a5.022 5.022 0 0 0-7.916 0"
      />
      <path
        strokeWidth={1.249995}
        d="M7.396 13.53a5.63 5.63 0 0 1-5.37-1.264"
      />
    </g>
  </svg>
);
export default SvgMultipleUsers;
