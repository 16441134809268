import * as React from 'react';
const SvgLegalScaleUnequal = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M10 3.608v15.767M.625 14.262l2.983-6.363 2.983 6.363M3.608 17.244a2.91 2.91 0 0 0 2.983-2.982H.625a2.91 2.91 0 0 0 2.983 2.982M19.375 9.148l-2.983-6.421-2.983 6.42"
      />
      <path
        strokeWidth={1.249995}
        d="M16.392 12.13a2.91 2.91 0 0 1-2.983-2.983h5.966a2.91 2.91 0 0 1-2.983 2.984M1.858 8.607 17.293 2.31M6.165 19.375h7.67-5.966"
      />
    </g>
  </svg>
);
export default SvgLegalScaleUnequal;
