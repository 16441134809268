import * as React from 'react';
const SvgVideo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.249995}
      d="M19.375 6.2a2.867 2.867 0 0 0-2.867-2.867H3.492A2.867 2.867 0 0 0 .625 6.2v7.6a2.867 2.867 0 0 0 2.867 2.867h13.016a2.867 2.867 0 0 0 2.867-2.867ZM7.917 13.283V6.067l5.641 3.608Z"
    />
  </svg>
);
export default SvgVideo;
