import React from 'react';
import { Input } from 'antd';

const ManipulativeComposer = (props) => {
  const { settings, handleChangeInput } = props;

  return (
    <div className="inm-newContent-section">
      <div className="inm-newContent-subSection inm-newContent-columns">
        Settings
        <Input
          onChange={(e) => handleChangeInput('settings', e.target.value, false)}
          value=""
          className="inm-newContent-input"
          disabled
        />
      </div>
    </div>
  );
};

export default ManipulativeComposer;
