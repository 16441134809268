import React, { useState, useEffect } from 'react';
import { Button, Checkbox, message, Select, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_HOST } from '../../../../store/resources/dataSource';

const { Option } = Select;

const GeogebraComposer = (props) => {
  const { script, handleChangeInput, fileName, settings } = props;

  const [isGeogebraUploaded, setIsGeogebraUploaded] = useState(!!fileName);
  const [fileList, updateFileList] = useState([]);

  useEffect(() => {
    if (fileName) {
      const currentFile = [
        {
          uid: '1',
          name: fileName,
          status: 'done',
        },
      ];
      updateFileList(currentFile);
      setIsGeogebraUploaded(true);
    } else {
      updateFileList([]);
      setIsGeogebraUploaded(false);
    }
  }, [fileName]);

  const successFileUpload = (file) => {
    handleChangeInput('fileName', file.name, true);
    handleChangeInput('fileId', file.response.uuid, true);
  };

  const clearFileData = () => {
    handleChangeInput('fileName', '', true);
    handleChangeInput('fileId', '', true);
  };

  const handleOnChange = (info) => {
    // file.status is empty when beforeUpload return false
    updateFileList(info.fileList.filter((file) => !!file.status));
    setIsGeogebraUploaded(!!info.file.status);

    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'removed') {
      setIsGeogebraUploaded(false);
      clearFileData();
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      successFileUpload(info.file);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <>
      <div className="inm-newContent-section inm-newContent-borderBottom">
        <div className="inm-newContent-subSection inm-newContent-columns">
          Upload the geogebra file if necessary
          <div className="inm-newContent-browsePdf">
            <Upload
              fileList={fileList}
              name="file"
              action={`${API_HOST}/file/geogebra`}
              onChange={handleOnChange}
            >
              <Button disabled={isGeogebraUploaded}>
                <UploadOutlined />
                Click to Upload
              </Button>
            </Upload>
          </div>
        </div>
        <div className="inm-newContent-subSection inm-newContent-alignRight">
          Type
          <Select
            className="inm-newContent-dropDown"
            onChange={(value) => handleChangeInput('script', value)}
            value={script}
          >
            <Option value="classic" key="classic">
              Classic
            </Option>
            <Option value="graphing" key="graphing">
              Graphing
            </Option>
            <Option value="geometry" key="geometry">
              Geometry
            </Option>
            <Option value="3d" key="3d">
              3D
            </Option>
            <Option value="suite" key="suite">
              Suite
            </Option>
            <Option value="evaluator" key="evaluator">
              Evaluator
            </Option>
          </Select>
        </div>
      </div>
      <div className="inm-newContent-section">
        <div className="inm-newContent-subSectionColumn">
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.enable_label_drags}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'enable_label_drags'
                )
              }
            />
            Permitir el arrastre de etiquetas
          </div>
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.show_reset_icon}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'show_reset_icon'
                )
              }
            />
            Icono de reiniciar construcción
          </div>
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.enable_right_click}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'enable_right_click'
                )
              }
            />
            Permitir “click” derecho, “zoom” y edición por teclado
          </div>
        </div>
        <div className="inm-newContent-subSectionColumn">
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.enable_shift_drag_zoom}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'enable_shift_drag_zoom'
                )
              }
            />
            Permitir usar zoom y arrastrar con Shift
          </div>
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.show_menu_bar}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'show_menu_bar'
                )
              }
            />
            Mostrar la Barra de Menús
          </div>
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.show_tool_bar}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'show_tool_bar'
                )
              }
            />
            Mostrar la Barra de Herramientas
          </div>
        </div>
        <div className="inm-newContent-subSectionColumn">
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.allow_upscale}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'allow_upscale'
                )
              }
            />
            Permitir cambiar la proporción de las dimensiones
          </div>
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.show_algebra_input}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'show_algebra_input'
                )
              }
            />
            Mostrar la Barra de Entrada
          </div>
          <div className="inm-newContent-checkboxContainer">
            <Checkbox
              className="inm-newContent-checkbox"
              checked={settings.allow_style_bar}
              onChange={(e) =>
                handleChangeInput(
                  'settings',
                  e.target.checked,
                  null,
                  'allow_style_bar'
                )
              }
            />
            Mostrar la Barra de estilo
          </div>
        </div>
      </div>
    </>
  );
};

export default GeogebraComposer;
