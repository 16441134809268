import * as React from 'react';
const SvgGeogebra = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22pt"
    height="20pt"
    viewBox="0 0 22 20"
    {...props}
  >
    <path
      d="M7.281 3.207C11.918.945 17.34 2.113 19.246 6.172c1.902 4.055-.605 9.086-5.246 11.344-4.637 2.257-10.059 1.09-11.965-2.965-1.902-4.055.606-9.086 5.246-11.344m10.899 3.48c-1.524-3.25-6.114-4.484-10.39-2.398-4.278 2.082-6.216 6.492-4.688 9.742 1.523 3.25 6.113 4.485 10.39 2.403s6.215-6.497 4.688-9.747m0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
    />
    <path
      d="M12.21 2.852c0 1.136-.901 2.054-2.015 2.054-1.113 0-2.015-.918-2.015-2.054C8.18 1.719 9.082.8 10.195.8c1.114 0 2.016.918 2.016 2.05m0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
    />
    <path
      d="M10.195 1.14c-.93 0-1.68.766-1.68 1.712 0 .945.75 1.71 1.68 1.71.926 0 1.68-.765 1.68-1.71 0-.946-.754-1.711-1.68-1.711M7.844 2.853c0-1.32 1.05-2.395 2.351-2.395 1.297 0 2.352 1.074 2.352 2.395 0 1.324-1.055 2.398-2.352 2.398-1.3 0-2.351-1.074-2.351-2.398M4.816 8.328c0 1.133-.902 2.055-2.015 2.055-1.114 0-2.016-.922-2.016-2.055s.903-2.055 2.016-2.055 2.015.922 2.015 2.055m0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
    />
    <path
      d="M2.8 6.617c-.925 0-1.679.766-1.679 1.711s.754 1.711 1.68 1.711c.93 0 1.68-.766 1.68-1.71 0-.946-.75-1.712-1.68-1.712M.45 8.328c0-1.324 1.054-2.394 2.35-2.394 1.302 0 2.352 1.07 2.352 2.394s-1.05 2.395-2.351 2.395c-1.297 0-2.352-1.07-2.352-2.395M7.508 17.223c0 1.136-.906 2.054-2.02 2.054s-2.015-.918-2.015-2.054c0-1.133.902-2.051 2.015-2.051 1.114 0 2.02.918 2.02 2.05m0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
    />
    <path
      d="M5.488 15.512c-.926 0-1.68.77-1.68 1.71 0 .946.755 1.712 1.68 1.712.93 0 1.684-.766 1.684-1.711 0-.942-.754-1.711-1.684-1.711m-2.351 1.71c0-1.32 1.054-2.394 2.351-2.394 1.301 0 2.356 1.074 2.356 2.395 0 1.324-1.055 2.398-2.356 2.398-1.297 0-2.351-1.074-2.351-2.398M17.586 15.855c0 1.133-.902 2.055-2.016 2.055s-2.015-.922-2.015-2.055c0-1.132.902-2.054 2.015-2.054 1.114 0 2.016.922 2.016 2.054m0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
    />
    <path
      d="M15.57 14.145c-.925 0-1.68.765-1.68 1.71 0 .946.755 1.711 1.68 1.711.93 0 1.68-.765 1.68-1.71 0-.946-.75-1.711-1.68-1.711m-2.351 1.71c0-1.324 1.054-2.394 2.351-2.394 1.301 0 2.352 1.07 2.352 2.394s-1.05 2.395-2.352 2.395c-1.297 0-2.351-1.07-2.351-2.395M20.945 6.96c0 1.134-.902 2.052-2.015 2.052-1.114 0-2.016-.918-2.016-2.051 0-1.137.902-2.055 2.016-2.055s2.015.918 2.015 2.055m0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
    />
    <path
      d="M18.93 5.25c-.926 0-1.68.766-1.68 1.71 0 .942.754 1.712 1.68 1.712.93 0 1.68-.77 1.68-1.711 0-.945-.75-1.711-1.68-1.711m-2.352 1.71c0-1.323 1.055-2.397 2.352-2.397 1.3 0 2.355 1.074 2.355 2.398 0 1.32-1.055 2.394-2.355 2.394-1.297 0-2.352-1.074-2.352-2.394m0 0"
      style={{
        stroke: 'none',
        fillRule: 'nonzero',
        fill: '#000',
        fillOpacity: 1,
      }}
    />
  </svg>
);
export default SvgGeogebra;
