import React, { useState, useEffect } from 'react';
import { Modal, Form, Select } from 'antd';
import useRegions from '../../hooks/useRegions';

const EditRegionsModal = ({ visible, sitemap, onClose, onOk }) => {
  const { regions } = useRegions();
  const currentRegions = sitemap?.regions || [];
  const [selectedRegions, setSelectedRegions] = useState(currentRegions);

  const handleAddRegion = (value) => {
    setSelectedRegions((prev) => [...prev, value]);
  };

  const regionsOptions = regions
    ?.filter((region) => region !== sitemap.region)
    .filter((region) => !selectedRegions.includes(region))
    .map((region) => ({
      value: region,
      label: region,
    }));

  const handleCancel = () => {
    setSelectedRegions(currentRegions);
    onClose();
  };
  const handleSend = () => {
    onOk(selectedRegions);
    onClose();
  };

  return (
    <Modal
      title={
        <>
          <h3>{`${sitemap.region}-${sitemap.courseOrder}-${sitemap.level}`}</h3>
          <h4 style={{ margin: '0' }}>Edit Sitemap Regions</h4>
        </>
      }
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSend}
    >
      <Form layout="vertical">
        <Form.Item label="Regions">
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            onSelect={handleAddRegion}
            onDeselect={(value) => {
              setSelectedRegions(
                selectedRegions.filter((region) => region !== value)
              );
            }}
            value={selectedRegions}
            options={regionsOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRegionsModal;
