import * as React from 'react';
const SvgDeleteTrash = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="M1.25 3.75h17.5M11.875 1.25h-3.75a1.25 1.25 0 0 0-1.25 1.25v1.25h6.25V2.5a1.25 1.25 0 0 0-1.25-1.25M8.125 14.375v-6.25M11.875 14.375v-6.25M15.72 17.603a1.25 1.25 0 0 1-1.245 1.147h-8.95a1.25 1.25 0 0 1-1.245-1.147L3.125 3.75h13.75Z"
      />
    </g>
  </svg>
);
export default SvgDeleteTrash;
