import {
  SAVE_RESOURCE,
  SET_CURRENT_RESOURCE,
  DELETE_RESOURCE,
  GET_RESOURCES,
  SET_RESOURCES,
  GET_RESOURCE,
  UPDATE_RESOURCE,
  DELETE_RESOURCES,
} from 'constants/ActionTypes';
import * as Api from './dataSource';
import { showVisibilityType } from '../../util/showVisibilityType';
import {
  DOWNLOADABLE_TAG,
  filterTags,
  GUIDE_TAG,
  PRINTABLE_TAG,
  PROJECTABLE_TAG,
} from '../../util/helpers';

export const actionSaveResource = (data) => ({
  type: SAVE_RESOURCE,
  data,
});

export const actionUpdateResource = (data) => ({
  type: UPDATE_RESOURCE,
  data,
});

const copyToAllLanguages = (translations, id) => {
  const languages = Object.keys(translations);
  const result = {};
  languages
    .filter((language) => !!translations[language].title)
    .forEach((language) => {
      result[language] = id;
    });
  return result;
};

const getTags = (data) => {
  const {
    isGuide,
    isPrintable,
    isDownloadable,
    isProjectable,
    courses,
    dynamics,
    contentBlock,
    others,
    tags,
  } = data;
  const _tags = tags?.filter(filterTags) || [];

  courses.forEach((course) => _tags.push(course));
  dynamics.forEach((dynamic) => _tags.push(dynamic));
  contentBlock.forEach((content) => _tags.push(content));
  others.forEach((other) => _tags.push(other));

  if (isGuide) _tags.push(GUIDE_TAG);
  if (isPrintable) _tags.push(PRINTABLE_TAG);
  if (isDownloadable) _tags.push(DOWNLOADABLE_TAG);
  if (isProjectable) _tags.push(PROJECTABLE_TAG);
  return _tags;
};

const getFilesIds = (translations, type) => {
  const languages = Object.keys(translations);
  const result = {};

  try {
    languages
      .filter((language) => !!translations[language].fileId)
      .forEach(async (language) => {
        const uuid = translations[language].fileId;
        result[language] = uuid;
        if (type === 'geogebra' || type === 'generic') return;
        const fragments = translations[language].fragments.map((fragment) => {
          const updatedFragment = fragment;
          updatedFragment.from = fragment.order - 1;
          updatedFragment.to = fragment.order;
          return updatedFragment;
        });
        // Update Fragments
        await Api.updateFile(uuid, { fragments }, type);
      });
  } catch (e) {
    console.error(e);
  }
  return result;
};

const getVimeoFilesIds = async (translations) => {
  const languages = Object.keys(translations);
  let result = {};

  try {
    const languagesFiltered = languages.filter(
      (language) => !!translations[language].videoUrl
    );
    result = await Promise.all(
      languagesFiltered.map(async (language) => {
        const url = translations[language].videoUrl;
        const file_name = translations[language].title.replace(
          /[^a-zA-Z0-9]/g,
          ''
        );
        const { fragments } = translations[language];
        let uuid;
        try {
          const response = await Api.createFile(
            { fragments, url, file_name },
            'vimeo'
          );
          uuid = response.data.uuid;
        } catch (e) {
          console.error(e);
        }
        return { language, uuid };
      })
    );
  } catch (e) {
    console.error(e);
  }
  return result;
};

const getThumbnailsFromTranslations = (translations) => {
  const translationsObj = {};
  Object.entries(translations).forEach(([key, value]) => {
    if (value.thumbnailId) {
      translationsObj[key] = value.thumbnailId;
    }
  });
  return translationsObj;
};

const getTranslationsObject = (translations) => {
  const translationsObj = {};
  Object.entries(translations).forEach(([key, value]) => {
    if (value.thumbnailId) {
      translationsObj[key] = {
        status: value.status || 'available',
        icon: value.icon,
        enumeration: value.enumeration,
      };
    }
  });
  return translationsObj;
};

export const createObject = async (data, translationsIds, type) => {
  const jsonData = {};

  jsonData.tags = getTags(data);
  jsonData.name_id = translationsIds.translations.title;
  jsonData.description_id = translationsIds.translations.description;

  jsonData.thumbnails = getThumbnailsFromTranslations(data.translations);
  jsonData.translations = getTranslationsObject(data.translations);

  if (type === 'applet') {
    jsonData.pack = data.appletPack;
    jsonData.scene = data.appletScene;
  }

  if (type === 'manipulativeEnvironment') {
    jsonData.settings = ''; // TODO Danger if we use it in the future
  }

  if (type === 'pdf' || type === 'genially' || type === 'generic') {
    jsonData.files = getFilesIds(data.translations, type);
  }

  if (type === 'pdf' || type === 'vimeo') {
    jsonData.printables = data.printableIds.length
      ? data.printableIds.replaceAll(' ', '').split(',').filter(Boolean)
      : [];
  }

  if (type === 'vimeo') {
    jsonData.files = {};
    const fileIds = await getVimeoFilesIds(data.translations);
    fileIds.forEach(
      (fileId) => (jsonData.files[fileId.language] = fileId.uuid)
    );
  }

  if (type === 'geogebra') {
    const file = getFilesIds(data.translations, type);
    const fileId = Object.keys(file).map((language) => file[language])[0];
    jsonData.files = copyToAllLanguages(data.translations, fileId);
    jsonData.script = data.script;
    jsonData.settings = data.settings;
  }

  if (type === 'session') {
    const resourceBlocks = [];
    Object.keys(data.sessionBlocks).forEach((block) => {
      const _block = { name: block, ordered_resources: [] };
      data.sessionBlocks[block].forEach((resource, index) => {
        const _resource = { resource_id: resource.uuid, order: index };
        _block.ordered_resources.push(_resource);
      });
      resourceBlocks.push(_block);
    });

    const guide = data.guide[1][0];
    if (guide?.uuid) {
      if (guide.type === 'digitalGuide') {
        jsonData.digital_guide = {
          type: 'prepr',
          id: guide.uuid,
        };
      } else {
        const guideId = guide.uuid;
        resourceBlocks.push({
          name: 'guide',
          ordered_resources: [{ resource_id: guideId, order: 0 }],
        });
      }
    } else {
      resourceBlocks.push({ name: 'guide', ordered_resources: [] });
    }

    const printableIds = [];
    data.printables[1].forEach((printable, index) =>
      printableIds.push({ resource_id: printable.uuid, order: index })
    );
    resourceBlocks.push({
      name: 'printables',
      ordered_resources: printableIds,
    });

    jsonData.resource_blocks = resourceBlocks;
    jsonData.code = data.sessionCode;
    jsonData.is_completable = data.isCompletable;
    jsonData.has_player = !data.sessionWithoutPlayer;
  }
  if (type !== 'resourceList' && type !== 'page') {
    jsonData.is_public = data.isPublic;
  }

  if (type === 'resourceList') {
    const orderedResources = [];
    data.listBlock.forEach((list, index) => {
      const _list = { resource_id: list.uuid, order: index };
      orderedResources.push(_list);
    });
    jsonData.ordered_resources = orderedResources;
    jsonData.is_preview = data.isPreview;
    jsonData.page_id = data.pageId;
    jsonData.not_automatically_relatable = data.notAutomaticallyRelatable;
    jsonData.list_type = data.list_type || 'default';
  }

  if (type === 'page') {
    const orderedLists = [];

    data.pageBlock.forEach((list, index) => {
      const _list = { uuid: list.uuid, order: index };
      orderedLists.push(_list);
    });

    jsonData.ordered_lists = orderedLists;
    jsonData.course_id = data.courses[0];
    jsonData.tab_id = data.tabs[0];
  }

  if (showVisibilityType(type)) {
    jsonData.visibility_type = data.visibilityType;
  }

  return jsonData;
};

export const actionGetResource = (resource) => ({
  type: GET_RESOURCE,
  resource,
});

export const actionSetCurrentResource = (resource) => ({
  type: SET_CURRENT_RESOURCE,
  resource,
});

export const actionDeleteResource = (resourceId) => ({
  type: DELETE_RESOURCE,
  resourceId,
});

export const actionDeleteResources = (resourcesIds) => ({
  type: DELETE_RESOURCES,
  resourcesIds,
});

export const actionGetResources = () => ({
  type: GET_RESOURCES,
});

export const actionSetResources = (resources) => ({
  type: SET_RESOURCES,
  resources,
});
