import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PARENT_TYPES } from '../routes/NewSitemapMenu/utils';
import {
  getAllMenuItems,
  getMenuItem,
  getRootItem,
  getSitemap,
  setLangFilter,
  setRoleFilter,
} from '../store/mainActions';
import useQuery from './useQuery';

export default function useSitemaps() {
  const dispatch = useDispatch();
  const { id, menuId } = useParams();
  const query = useQuery();
  const parentId = query.get('parentId');
  const parentType = query.get('parentType');

  const sitemap = useSelector(({ sitemaps }) => sitemaps?.sitemap);
  const langFilter = useSelector(({ sitemaps }) => sitemaps?.langFilter);
  const roleFilter = useSelector(({ sitemaps }) => sitemaps?.roleFilter);

  const menuItems = useSelector(({ sitemaps }) => sitemaps?.menuItems);
  const rootItem = useSelector(({ sitemaps }) => sitemaps?.rootItem);
  const menuItem = useSelector(({ sitemaps }) => sitemaps?.menuItem);
  const saving = useSelector(({ sitemaps }) => sitemaps?.saving);

  useEffect(() => {
    if (menuId) {
      dispatch(getMenuItem(menuId));
    }
  }, [menuId]);

  useEffect(() => {
    if (parentId) {
      dispatch(getRootItem(parentId));
    }
  }, [parentId]);

  useEffect(() => {
    if (sitemap) {
      const { region, courseOrder, level } = sitemap;
      dispatch(
        getAllMenuItems({ region, courseOrder, role: 'ROLE_TEACHER', level })
      );
    }
  }, [sitemap]);

  useEffect(() => {
    if (id) {
      dispatch(getSitemap(id));
    }
  }, [id]);

  const filterGroups = (element) => {
    const isParentRootElement = rootItem?.parentMenuItemId === null;
    if (isParentRootElement) return true;
    return element.menuItemType === 'group';
  };

  const getParentGroup = () => {
    if (parentType === PARENT_TYPES.group) {
      return menuItems?.filter(filterGroups).map((item) => ({
        id: item.id,
        name: getTranslation(item),
      }));
    }
    if (!rootItem?.parentMenuItemId) return [];
    return menuItems
      ?.find((item) => item.id === rootItem.parentMenuItemId)
      ?.items.filter(filterGroups)
      .map((item) => ({
        id: item.id,
        name: getTranslation(item),
      }));
  };

  const getTranslation = (item) => {
    const lang = item?.translations.find(
      (translation) => translation.languageId === langFilter
    );
    return lang?.value || '';
  };

  const setFilterLang = (lang) => {
    dispatch(setLangFilter(lang));
  };

  const setFilterRole = (role) => {
    dispatch(setRoleFilter(role));
  };

  return {
    sitemap,
    saving,
    parentId,
    parentType,
    groupsSelect: getParentGroup(),
    menuItems,
    rootItem,
    menuItem,
    langFilter,
    setFilterLang,
    getTranslation,
    roleFilter,
    setFilterRole,
  };
}
