export const languageList = [
  {
    key: 'Español',
    value: 'es',
  },
  {
    key: 'Català',
    value: 'ca',
  },
  {
    key: 'Euskera',
    value: 'eu',
  },
  {
    key: 'Valenciano',
    value: 'va',
  },
  {
    key: 'English',
    value: 'en',
  },
  {
    key: 'LATAM',
    value: 'es_419',
  },
  {
    key: 'Chileno',
    value: 'es_CL',
  },
  {
    key: 'Ecuatoriano',
    value: 'es_EC',
  },
  {
    key: 'French',
    value: 'fr_FR',
  },
  {
    key: 'Mexicano',
    value: 'es_MX',
  },
  {
    key: 'Italiano',
    value: 'it_IT',
  },
  {
    key: 'American English',
    value: 'en_US',
  },
  {
    key: 'Portegués de Brasil',
    value: 'pt_BR',
  },
  {
    key: 'Albanés',
    value: 'sq',
  },
  {
    key: 'Bielorruso',
    value: 'be',
  },
  {
    key: 'Colombiano',
    value: 'es_CO',
  },
];

export const defaultLanguage = 'es';
