import * as React from 'react';
const SvgPrinting = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.75 13.125H12.5a2.5 2.5 0 0 1-5 0H1.25a.625.625 0 0 0-.625.625v3.125a1.25 1.25 0 0 0 1.25 1.25h16.25a1.25 1.25 0 0 0 1.25-1.25V13.75a.624.624 0 0 0-.625-.625M18.125 10.625v-7.5a1.25 1.25 0 0 0-1.25-1.25H8.75a1.25 1.25 0 0 0-1.25 1.25v7.5M1.875 10.625v-5a1.25 1.25 0 0 1 1.25-1.25H5M12.5 5.625h3.125M10 8.75h5.625"
    />
  </svg>
);
export default SvgPrinting;
