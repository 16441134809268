import * as React from 'react';
const SvgClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <rect
      width={2.525}
      height={15.152}
      fill="#333"
      rx={1.263}
      transform="rotate(135 5.13 10.598)"
    />
    <rect
      width={2.525}
      height={15.152}
      fill="#333"
      rx={1.263}
      transform="rotate(-135 6.133 6.979)"
    />
  </svg>
);
export default SvgClose;
