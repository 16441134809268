import axios from 'axios';
import { axiosInstance } from '../../util/axiosInstance';

const API_HOST = process.env.REACT_APP_API_RESOURCES;

export const requestTranslations = async (data) => {
  const url = `${API_HOST}/translation`;
  const response = await axiosInstance({ url, method: 'PATCH', data });

  return response;
};

export const requestSingleTranslation = async (data) => {
  const url = `${API_HOST}/translation`;
  const response = await axiosInstance({ url, method: 'POST', data });

  return response;
};

export const getSingleTranslation = async (uuid) => {
  const url = `${API_HOST}/translation/${uuid}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};
