import * as React from 'react';
const SvgEdit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M18.492 1.508a3.034 3.034 0 0 0-4.309.03L2.1 13.62.625 19.375l5.754-1.476L18.462 5.816a3.03 3.03 0 0 0 .03-4.308M13.838 1.883l4.279 4.279M2.1 13.62l4.283 4.275"
    />
  </svg>
);
export default SvgEdit;
