import React, { useCallback, useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import {
  setValidation,
  actionSetFilters,
  actionSetPagination,
  actionGetResources,
  actionSetResources,
} from '../../../../store/mainActions';

import * as resourcesApi from '../../../../store/resources/dataSource';
import { getList } from '../../../../store/lists/dataSource';
import { getSingleTranslation } from '../../../../store/translations/dataSource';
import SearchDragCRUD from '../../../../components/SearchDragCRUD';
import { defaultLanguage } from '../../../../util/languageList';

const SessionComposer = (props) => {
  const {
    contentTypes,
    type,
    handleChangeInput,
    guideStep,
    printableStep,
    printables,
    guide,
    sessionBlocks,
    isPreview,
    activeLanguages,
    tagsList,
    suggestedListStep,
    suggestedLists,
    sessionWithoutPlayer,
  } = props;

  const guideOrPrintableStep = guideStep || printableStep || suggestedListStep;

  const allResults = useSelector(({ resources }) => resources.resources);
  // const loading = useSelector(({ common }) => common.loading);
  const filters = useSelector(
    ({ paginationFilters }) => paginationFilters.resources.filters
  );

  const [sessionResources, setSessionResources] = useState({});
  const [searchName, setSearchName] = useState('');
  const [searchID, setSearchID] = useState('');
  const [searchType, setSearchType] = useState('');
  const [searchResult, setSearchResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const handleValidation = useCallback(
    (isValid) => dispatch(setValidation(isValid)),
    []
  );

  const addResourceToSession = (resource, block) => {
    if (guideStep && sessionResourcesData.totalResources >= 1) return;
    setSessionResources({
      ...sessionResources,
      [block]: [...sessionResources[block], resource],
    });
  };

  const removeResourceFromSession = (index, block) => {
    setSessionResources({
      ...sessionResources,
      [block]: sessionResources[block].filter((_, i) => index !== i),
    });
  };

  const getSessionResourcesData = () => {
    let totalResources = 0;
    let totalDuration = 0;
    if (Object.keys(sessionResources)) {
      for (let i = 1; i <= Object.keys(sessionResources).length; i++) {
        sessionResources[i].forEach((resource) => {
          totalResources++;
          totalDuration += resource.duration;
        });
      }
    }
    return { totalResources, totalDuration };
  };

  const sessionResourcesData = getSessionResourcesData();

  useEffect(() => {
    if (guideOrPrintableStep) {
      handleValidation(true);
    } else {
      if (sessionResourcesData.totalResources >= 1 && !sessionWithoutPlayer) {
        handleValidation(true);
      }
      if (sessionResourcesData.totalResources >= 1 && sessionWithoutPlayer) {
        handleValidation(false);
      }
      if (sessionResourcesData.totalResources === 0 && sessionWithoutPlayer) {
        handleValidation(true);
      }
      if (sessionResourcesData.totalResources === 0 && !sessionWithoutPlayer) {
        handleValidation(false);
      }
    }
  }, [sessionResourcesData]);

  useEffect(() => {
    setSessionResources(sessionBlocks || guide || printables || suggestedLists);
    setSearchName('');
    setSearchID('');
    setSearchType('');
    setSearchResult({});
    setError(false);
    dispatch(actionSetFilters('resources', {}));
    dispatch(actionSetResources({ resources: [], count: 0 }));
  }, [guideStep, printableStep]);

  useEffect(() => {
    if (searchType || searchName || searchID) dispatch(actionGetResources());
    else dispatch(actionSetResources({ resources: [], count: 0 }));
  }, [filters]);

  useEffect(() => {
    dispatch(actionSetPagination('resources', { offset: 0, length: 50 }));
    return () => {
      dispatch(actionSetFilters('resources', {}));
      dispatch(actionSetPagination('resources', { offset: 0, length: 50 }));
    };
  }, []);

  useEffect(() => {
    if (!guideStep && !printableStep && !suggestedListStep) {
      handleChangeInput('sessionBlocks', sessionResources);
    }
    if (guideStep) handleChangeInput('guide', sessionResources);
    if (printableStep) handleChangeInput('printables', sessionResources);
    if (suggestedListStep) {
      handleChangeInput('suggestedLists', sessionResources);
    }
  }, [sessionResources]);

  const getSessionResourcesList = () => {
    const list = [];
    Object.keys(sessionResources).forEach((block) =>
      list.push(...sessionResources[block])
    );
    return list;
  };

  const handleSearchResource = async () => {
    setLoading(true);
    setError(false);
    if (searchID) {
      let result;
      try {
        if (searchType === 'resourceList') {
          const res = await getList(searchID);
          const singleTranslations = await getSingleTranslation(
            res.data.name_id
          );
          const translatedName = singleTranslations.data.translations.filter(
            (t) => t.locale === defaultLanguage
          );
          result = {
            uuid: res.data.uuid,
            name: translatedName[0].translation,
          };
        } else if (searchType === 'digitalGuide') {
          const res = await resourcesApi.getPrepareGuide(searchID);
          result = {
            uuid: res.id,
            name: res.title['es-ES'].body,
            type: 'digitalGuide',
          };
        } else {
          const res = await resourcesApi.getTranslatedResourceByType(
            searchType,
            searchID,
            defaultLanguage
          );
          result = res.data;
        }
      } catch (e) {
        console.error(e);
        setError(true);
      }

      setSearchResult(result);
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      source.droppableId === 'search'
    ) {
      return;
    }

    const sourceBlockIndex = source.droppableId;
    const destinationBlockIndex = destination.droppableId;
    const destinationBlock = cloneDeep(sessionResources[destinationBlockIndex]);

    if (sourceBlockIndex === 'search') {
      const resource =
        searchResult || allResults.find((e) => e.uuid === draggableId);
      resource && addResourceToSession(resource, destinationBlockIndex);
      return;
    }
    if (destinationBlockIndex === 'search') {
      const resource =
        searchResult ||
        sessionResources[sourceBlockIndex].find((e) => e.uuid === draggableId);
      resource && removeResourceFromSession(resource, sourceBlockIndex);
      return;
    }
    const sourceBlock = cloneDeep(sessionResources[sourceBlockIndex]);
    const resource = cloneDeep(
      sessionResources[sourceBlockIndex][source.index]
    );

    if (sourceBlockIndex === destinationBlockIndex) {
      sourceBlock.splice(source.index, 1);
      sourceBlock.splice(destination.index, 0, resource);
      setSessionResources({
        ...sessionResources,
        [sourceBlockIndex]: sourceBlock,
      });
    } else {
      sourceBlock.splice(source.index, 1);
      destinationBlock.splice(destination.index, 0, resource);
      setSessionResources({
        ...sessionResources,
        [sourceBlockIndex]: sourceBlock,
        [destinationBlockIndex]: destinationBlock,
      });
    }
  };

  const getContentTypes = () => {
    let allowedByType = contentTypes.filter(
      (content) =>
        content.value !== 'session' &&
        content.value !== 'page' &&
        content.value !== 'resourceList'
    );
    if (suggestedListStep) {
      allowedByType = contentTypes.filter(
        (content) => content.value === 'resourceList'
      );
    }
    return allowedByType;
  };

  const filterGuidePrintable = (result) => {
    const GUIDE_TAG = process.env.REACT_APP_GUIDE_TAG;
    const PRINTABLE_TAG = process.env.REACT_APP_PRINTABLE;
    if (guideStep) {
      return !!result.tags.find((tag) => tag.uuid === GUIDE_TAG);
    }
    if (printableStep) {
      return !!result.tags.find((tag) => tag.uuid === PRINTABLE_TAG);
    }
    return true;
  };

  const filterById = (result) => {
    if (searchID) {
      return result.uuid.includes(searchID.trim());
    }
    return true;
  };

  const filterByLanguages = (result) => {
    const resultLanguages = Object.keys(result.translations).map(
      (language) => language
    );
    return !activeLanguages?.some(
      (language) => !resultLanguages.includes(language)
    );
  };

  return (
    <SearchDragCRUD
      type={type}
      onDragEnd={onDragEnd}
      searchID={searchID}
      setSearchID={setSearchID}
      guideOrPrintableStep={guideOrPrintableStep}
      guideStep={guideStep}
      suggestedListStep={suggestedListStep}
      searchType={searchType}
      getContentTypes={getContentTypes}
      setSearchType={setSearchType}
      error={error}
      handleSearchElement={handleSearchResource}
      loading={loading}
      searchResult={searchResult}
      allResults={allResults}
      getAllElementsList={getSessionResourcesList}
      tagsList={tagsList}
      addElement={addResourceToSession}
      elementsData={sessionResourcesData}
      isPreview={isPreview}
      handleChangeInput={handleChangeInput}
      addedElements={sessionResources}
      removeElement={removeResourceFromSession}
    />
  );
};

export default SessionComposer;
