import * as React from 'react';
const SvgNumeration = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M.625 19.375h18.75"
    />
    <mask id="numeration_svg__a" fill="#fff">
      <rect width={8} height={5} x={1} y={15} rx={1} />
    </mask>
    <rect
      width={8}
      height={5}
      x={1}
      y={15}
      stroke="#757575"
      strokeWidth={2.5}
      mask="url(#numeration_svg__a)"
      rx={1}
    />
    <mask id="numeration_svg__b" fill="#fff">
      <rect width={8} height={5} x={1} y={11} rx={1} />
    </mask>
    <rect
      width={8}
      height={5}
      x={1}
      y={11}
      stroke="#757575"
      strokeWidth={2.5}
      mask="url(#numeration_svg__b)"
      rx={1}
    />
    <mask id="numeration_svg__c" fill="#fff">
      <rect width={8} height={5} x={1} y={7} rx={1} />
    </mask>
    <rect
      width={8}
      height={5}
      x={1}
      y={7}
      stroke="#757575"
      strokeWidth={2.5}
      mask="url(#numeration_svg__c)"
      rx={1}
    />
    <mask id="numeration_svg__d" fill="#fff">
      <rect width={8} height={5} x={11} y={15} rx={1} />
    </mask>
    <rect
      width={8}
      height={5}
      x={11}
      y={15}
      stroke="#757575"
      strokeWidth={2.5}
      mask="url(#numeration_svg__d)"
      rx={1}
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M15 15V1M5 7V1"
    />
  </svg>
);
export default SvgNumeration;
