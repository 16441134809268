import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';

import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNIN_GOOGLE_USER,
} from 'constants/ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
} from './actions';

import * as authApi from './dataSource';
import * as userStorage from '../../util/userStorage';

function* signInUserWithEmailPassword({ payload }) {
  try {
    const { data } = yield call(authApi.sendLogin, payload);
    const user = userStorage.defineUserDataFromTokens(data);
    userStorage.setUser(user);
    yield put(userSignInSuccess(user));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    userStorage.clearUser();
    yield put(userSignOutSuccess());
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signInUserGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

function* signInUserWithGoogle(credentialsResonse) {
  try {
    const { data } = yield call(
      authApi.sendLoginGoogle,
      credentialsResonse.payload.credential
    );
    const signUpUser = jwt_decode(data.id_token);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      const { email } = signUpUser;
      const isAuthorizedEmail = yield call(authApi.validateEmailAuth, email);
      signUpUser.token = data.access_token;
      if (email.includes('@innovamat.com') && isAuthorizedEmail) {
        userStorage.setUser(signUpUser);
        yield put(userSignInSuccess(signUpUser));
      } else {
        yield put(showAuthMessage('Access invalid.'));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(signInUserGoogle), fork(signOutUser)]);
}
