import * as React from 'react';
const SvgDashboard = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    fill="none"
    {...props}
  >
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="m7.667 17.889 3.555-3.556a.666.666 0 0 1 .942 0l1.725 1.725a.667.667 0 0 0 .942 0l3.556-3.556"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M19.667 1H2.333C1.597 1 1 1.597 1 2.333v17.334C1 20.403 1.597 21 2.333 21h17.334c.736 0 1.333-.597 1.333-1.333V2.333C21 1.597 20.403 1 19.667 1"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.667 11.889a4 4 0 1 0 0-8 4 4 0 0 0 0 8"
    />
    <path
      stroke="#757575"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M7.667 3.889v4l2.826 2.827M15 5.222h3.111M15 8.333h3.111"
    />
  </svg>
);
export default SvgDashboard;
