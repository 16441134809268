import axios from 'axios';
import { axiosInstance } from '../../util/axiosInstance';

export const API_HOST = process.env.REACT_APP_API_RESOURCES;

export const createCourse = async (data) => {
  const url = `${API_HOST}/course`;
  const response = await axiosInstance({ url, method: 'POST', data });

  return response;
};

export const getCourses = async (locale) => {
  const url = `${API_HOST}/courses/locale/${locale}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};

export const getCourseById = async (id) => {
  const url = `${API_HOST}/course/${id}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};
