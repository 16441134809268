import * as React from 'react';
const SvgMonitorTouch = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        strokeWidth={1.249995}
        d="m7.5 19.375.625-3.75M5.625 19.375h3.75M8.125 15.625h-5a2.5 2.5 0 0 1-2.5-2.5v-10a2.5 2.5 0 0 1 2.5-2.5h13.75a2.5 2.5 0 0 1 2.5 2.5v8.75"
      />
      <path
        strokeWidth={1.249995}
        d="m13.842 19.375-3.158-2.578a1.36 1.36 0 0 1-.471-1.396h0a1.364 1.364 0 0 1 1.934-.89l.978.489v-4.375a1.25 1.25 0 0 1 1.25-1.25h0a1.25 1.25 0 0 1 1.25 1.25v2.5l1.66.277a2.5 2.5 0 0 1 2.09 2.466v3.507M.625 13.125h7.5"
      />
    </g>
  </svg>
);
export default SvgMonitorTouch;
