import axios from 'axios';
import { axiosInstance } from '../../util/axiosInstance';

export const API_HOST = process.env.REACT_APP_API_RESOURCES;

export const createTab = async (data) => {
  const url = `${API_HOST}/tab`;
  const response = await axiosInstance({ url, method: 'POST', data });

  return response;
};

export const getTabs = async (locale) => {
  const url = `${API_HOST}/tabs/locale/${locale}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};

export const getTabById = async (id) => {
  const url = `${API_HOST}/tab/${id}`;
  const response = await axiosInstance({ url, method: 'GET' });

  return response;
};
