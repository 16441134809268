import { Checkbox, Input, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BottomActions from '../../components/BottomActions';
import ContainerHeader from '../../components/ContainerHeader';
import useSitemaps from '../../hooks/useSitemaps';
import { saveLinkItem } from '../../store/mainActions';
import TranslationByLanguage from '../../components/TranslationByLanguage';
import SelectRoles from '../../components/SelectRoles';
import IconSelector from '../../components/IconSelector';
import useLanguages from '../../hooks/useLanguages';

const { Option } = Select;

const programs = ['LABORATORY', 'APP', 'ADVENTURE'];

const getBaseUrl = (item) => {
  if (!item) return '';
  const path = item.route.split('/');
  return `/${path[1]}/`;
};

const getUrl = (urlName) => {
  if (!urlName) return '';
  const sufix = urlName.split('/')[2];
  return sufix;
};

export default function NewSitemapLink() {
  const {
    sitemap,
    rootItem,
    saving,
    parentId,
    groupsSelect,
    menuItem,
    menuItems,
    getTranslation,
  } = useSitemaps();
  const dispatch = useDispatch();
  const { region, courseOrder } = sitemap || {};
  const history = useHistory();
  const [linkType, setLinkType] = useState('PAGE_ID');
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [translations, setTranslations] = useState();
  const [icon, setIcon] = useState();
  const [urlName, setUrlName] = useState();
  const [inputs, setInputs] = useState({
    url: undefined,
    route: undefined,
    fullPageId: undefined,
    demoPageId: undefined,
    program: undefined,
    isOnlyForClassroom: false,
  });
  const [groupId, setGroupId] = useState(parentId);
  const { getLanguagesWithTranslations: getLanguagesByRegion } = useLanguages();

  const groupItem = menuItems?.find(
    (s) => s.id === (rootItem?.rootMenuItemId || rootItem?.id)
  );
  const url = getBaseUrl(groupItem);

  useEffect(() => {
    if (menuItem && url) {
      setInputs({
        url: menuItem.url,
        route: menuItem.route,
        fullPageId: menuItem.fullPageId,
        demoPageId: menuItem.demoPageId,
        program: menuItem.program,
        isOnlyForClassroom: menuItem.isOnlyForClassroom,
      });
      setUrlName(getUrl(menuItem.urlName));
      setIcon(menuItem.icon);
      setCheckedRoles(menuItem.roles);
      setGroupId(menuItem.parentMenuItemId);
      setTranslations(getLanguagesByRegion(menuItem.translations));
      setLinkType(menuItem.linkItemType);
    } else {
      setTranslations(getLanguagesByRegion([]));
    }
  }, [menuItem, url]);

  const validateData = () => {
    if (!checkedRoles.length) return false;
    if (translations?.some((lang) => lang.name === '')) return false;
    if (
      linkType === 'PAGE_ID' &&
      (inputs.fullPageId === undefined ||
        inputs.fullPageId === null ||
        inputs.fullPageId === '' ||
        urlName === undefined ||
        urlName === null ||
        urlName === '')
    ) {
      return false;
    }
    if (icon === undefined) return false;
    return true;
  };

  const handleSaveAndContinue = () => {
    const idToCompare = rootItem.parentMenuItemId || rootItem.id;
    // we sett the order to null beacuse we added the correct order in sagas
    const body = {
      icon,
      linkItemType: linkType,
      parentMenuItemId: groupId,
      sitemapId: sitemap.id,
      menuItemOrder: null,
      translations,
      roles: checkedRoles,
      rootMenuItemId: idToCompare,
      program: inputs.program,
      isOnlyForClassroom: inputs.isOnlyForClassroom,
    };

    if (linkType === 'EXTERNAL') {
      body.url = inputs.url;
    }
    if (linkType === 'ROUTE') {
      body.route = inputs.route;
    }
    if (linkType === 'PAGE_ID') {
      body.fullPageId = inputs.fullPageId;
      body.demoPageId = inputs.demoPageId;
      let _urlName;
      if (!urlName.includes(url)) {
        _urlName = `${url}${urlName}`;
      }

      body.urlName = _urlName;
    }

    dispatch(saveLinkItem(body));
  };

  const onLinkTypeChange = (e) => {
    const { value } = e.target;
    setLinkType(value);
  };

  const getInputs = () => {
    if (linkType === 'PAGE_ID') {
      return [
        {
          name: 'FULL*',
          value: 'fullPageId',
        },
        {
          name: 'DEMO',
          value: 'demoPageId',
        },
      ];
    }
    if (linkType === 'ROUTE') {
      return [
        {
          name: 'Route',
          value: 'route',
        },
      ];
    }
    return [
      {
        name: 'URL',
        value: 'url',
      },
    ];
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleProgramChange = (value) => {
    setInputs({ ...inputs, program: value });
  };

  if (!sitemap) {
    return <p>Loading...</p>;
  }
  const getTitle = () => {
    if (menuItem) {
      return `Edit sitemap: ${region} - ${courseOrder} - ${getTranslation(
        rootItem
      )} - ${getTranslation(menuItem)}`;
    }

    return `Create sitemap: ${region} - ${courseOrder} - ${getTranslation(
      rootItem
    )} - New Link`;
  };

  const isParentRootElement = rootItem?.parentMenuItemId === null;
  const isProgramDisabled = courseOrder < 6 || courseOrder > 11;

  return (
    <>
      <ContainerHeader title={getTitle()} />
      <div className="inm-craete-sitemap-group-container">
        <div className="inm-inputs-container">
          <h4>Link type</h4>
          <Radio.Group value={linkType} onChange={onLinkTypeChange}>
            <Radio value="PAGE_ID">Page ID</Radio>
            <Radio value="ROUTE">Route</Radio>
            <Radio value="EXTERNAL">External</Radio>
          </Radio.Group>
          {getInputs().map((input) => (
            <div className="inm-input-container" key={input.name}>
              <span className="inm-label-type">{input.name}</span>
              <Input
                name={input.value}
                value={inputs[input.value]}
                onChange={handleInputChange}
              />
            </div>
          ))}
          <Checkbox
            onChange={(e) => {
              setInputs({
                ...inputs,
                isOnlyForClassroom: e.target.checked,
              });
            }}
            name="isOnlyForClassroom"
            id="isOnlyForClassroom"
            checked={inputs.isOnlyForClassroom}
          >
            Only for class browsing
          </Checkbox>
        </div>
        <div className="inm-selects-container">
          <div className="inm-select-container">
            <span>
              {'Program'}
              {isProgramDisabled && ' (only enabled in primary)'}
            </span>
            <Select
              allowClear
              placeholder="Select a program"
              value={inputs.program}
              onChange={handleProgramChange}
              disabled={isProgramDisabled}
            >
              {programs?.map((_program) => (
                <Option key={_program} value={_program}>
                  {_program}
                </Option>
              ))}
            </Select>
          </div>
          <div className="inm-select-container">
            <span>Group</span>
            <Select
              disabled={isParentRootElement}
              value={groupId}
              style={{ width: 120 }}
              onChange={setGroupId}
            >
              {groupsSelect?.map((group) => (
                <Option key={group.id} value={group.id}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </div>

          <IconSelector
            noBgColor
            placeholder="Select an Icon"
            className="inm-icon-selector"
            label="Icon*"
            value={icon}
            onChange={(e) => setIcon(e.value)}
          />
        </div>
      </div>
      <div className="inm-craete-sitemap-group-container mt-52">
        <TranslationByLanguage
          showHiddenOption
          displayName="Link name*"
          translations={translations}
          setTranslations={setTranslations}
        />
        <div className="inm-roles-container">
          <SelectRoles
            allowedRoles={[]}
            checkedRoles={checkedRoles}
            setCheckedRoles={setCheckedRoles}
          />
          <div className="inm-basic-input-container mt-52">
            <span className="">Url name*</span>
            <div className="inm-url-name-container">
              {linkType === 'PAGE_ID' && <span>{url}</span>}

              <Input
                disabled={linkType !== 'PAGE_ID'}
                name="urlName"
                value={urlName}
                onChange={(e) => setUrlName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="inm-footer-offset" />
      <BottomActions
        onSave={handleSaveAndContinue}
        onCancel={() => history.push(`/newSitemap/${sitemap.id}`)}
        validateData={validateData}
        saving={saving}
      />
    </>
  );
}
