import * as ApiTags from './dataSource';
import * as ApiTranslations from '../translations/dataSource';

import { REQUEST_TAGS, SAVE_TAG, SET_TAGS } from '../../constants/ActionTypes';

export const actionSaveTag = (tags) => ({
  type: SAVE_TAG,
  tags,
});

export const actionSetTags = (tags) => ({
  type: SET_TAGS,
  tags,
});

export const actionRequestTags = (locale) => ({
  type: REQUEST_TAGS,
  locale,
});

export async function getTagTranslations(uuid) {
  try {
    const responseId = await ApiTags.getTagById(uuid);
    const responseTranslations = await ApiTranslations.getSingleTranslation(
      responseId.data.translation_id
    );
    return responseTranslations.data.translations;
  } catch (e) {
    console.error('Translation ERROR', e.response);
    return e.response;
  }
}
